.PlainTable {
	background: #ffffff;
	border: 0.8px solid rgba(196, 196, 196, 0.5);
	box-shadow: 0px 1px 2px rgba(101, 95, 95, 0.2);
	width: 100%;
	border-radius: 0.5rem;
	font-weight: 500;

	&__tableHead {
		padding: 13px 30px;
		padding-right: 1rem;
		border-bottom: 0.8px solid rgba(196, 196, 196, 0.5);
		font-size: 13.59px;
		line-height: 17px;
		color: #252627;
	}

	&__tableBody {
		padding: 25px 32px;
		padding-right: 1rem;
	}
}

@media screen and (max-width: 768px) {
	.PlainTable {
		box-shadow: 0px 1px 2px rgba(101, 95, 95, 0.2);

		&__tableHead,
		&__tableBody {
			padding: 14px 18px;
			padding-right: 1rem;
		}
	}
}
