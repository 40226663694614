@keyframes rotatePlane {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.CustomLoader {
	padding: 88px 0;
	height: calc(100vh - 61px);
	overflow: auto;
	.banner {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		padding: 66px;
		padding-bottom: 166px;
		background-image: url(../../resource/img/loader-bg.png);

		.globe {
			line-height: 0;
			svg {
				width: 96.75px;
				// height: 381px;
			}
		}

		.plane-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			animation: rotatePlane 1.5s linear 0s infinite;
		}

		.plane {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, 50%);
			padding: 0 10px;
			background-color: #d7ecf8;
			svg {
				width: 41.79px;
			}
		}

		.dotted-circle {
			padding: 36px;
			border: 2px dashed #243256;
			border-radius: 50%;
			position: relative;
		}
	}

	.info {
		margin-top: 58px;
		text-align: center;

		.locations {
			font-weight: 500;
			font-size: 18px;
			line-height: 23px;
			color: #252627;
			margin-bottom: 0.75rem;
		}

		.dates {
			font-weight: 450;
			font-size: 16px;
			line-height: 20px;
			color: #655f5f;
			margin-bottom: 0.25rem;
		}
		.other {
			font-weight: 450;
			font-size: 16px;
			line-height: 20px;
			color: #9c9393;
		}
	}
}

@media screen and (max-width: 800px) {
	.CustomLoader {
		padding: 88px 0;
		height: calc(100vh - 61px);
		overflow: auto;
		.banner {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			padding: 66px;
			padding-bottom: 0;
			background-image: none;

			.globe {
				line-height: 0;
				svg {
					width: 96.75px;
					// height: 381px;
				}
			}

			.plane-wrapper {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				animation: rotatePlane 1.5s linear 0s infinite;
			}

			.plane {
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translate(-50%, 50%);
				padding: 0 10px;
				background-color: transparent;
				svg {
					width: 41.79px;
				}
			}

			.dotted-circle {
				padding: 36px;
				border: 2px dashed #243256;
				border-radius: 50%;
				position: relative;
			}
		}

		.info {
			margin-top: 70px;
			text-align: center;

			.locations {
				font-weight: 500;
				font-size: 18px;
				line-height: 23px;
				color: #252627;
				margin-bottom: 0.75rem;
			}

			.dates {
				font-weight: 450;
				font-size: 16px;
				line-height: 20px;
				color: #655f5f;
				margin-bottom: 0.25rem;
			}
			.other {
				font-weight: 450;
				font-size: 16px;
				line-height: 20px;
				color: #9c9393;
			}
		}
	}
}
