.sign-innew,
.new-password {
	width: 95%;
	height: 95%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.heading {
		margin-bottom: 3.5%;
		h5 {
			font-family: "Circular Std";
			font-style: normal;
			font-weight: 500;
			font-size: 20.21px;
			line-height: 26px;
			/* identical to box height, or 129% */
			text-align: center;
			color: #252627;
		}
		p {
			font-family: "Circular Std";
			font-style: normal;
			font-weight: 450;
			font-size: 13.59px;
			line-height: 17px;
			text-align: center;
			color: #655f5f;
		}
	}
	.form-section {
		height: 65%;
		form {
			height: 100%;
			display: grid;
			gap: 1rem;

			.form-item {
				width: 100%;
				label {
					padding: 5px 0;
					font-weight: 450;
					font-size: 15px;
					line-height: 19px;
					color: #252627;
					padding: 5px 0;
					display: block;
					// font-weight: 100;
					// font-weight: 100;
				}
				input {
					background: #f9fafa;
					padding: 5px 0;
					border: 0.8px solid rgba(196, 196, 196, 0.5);
					border-radius: 4px;
					padding: 10px;
					padding-left: 20px;
					width: 100%;
					color: #252627;
					box-sizing: border-box;
				}
			}
			.button-wrapper {
				display: grid;
				width: 100%;
				padding-top: 5%;
				gap: 7px;
				button {
					width: 100%;
					padding-top: 9px;
					padding-bottom: 12px;
					height: 44px;
				}
				.rem-me {
					display: flex;
					align-items: center;
					gap: 10px;
					label {
						font-family: "Circular Std";
						font-style: normal;
						font-weight: 450;
						font-size: 13.59px;
						line-height: 17px;
						color: #655f5f;
					}
				}
				.forgot-link {
					text-decoration: none;
					color: #40798c;
					font-weight: 450;
					font-size: 13.59px;
					line-height: 17px;
					&:hover {
						cursor: pointer;
					}
				}
			}
		}
	}
	.bottom {
		text-align: center;
		p,
		a {
			padding: 5px 0;
			padding-top: 20px;
			color: #9c9393;
			font-size: 80%;
			font-family: "Circular Std";
			font-style: normal;
			font-size: 15px;
			line-height: 19px;
			text-decoration: none;
			padding: 5px 0;
		}
		a {
			color: #40789c;
			font-weight: 500;
		}
	}
	// .spinner-container {
	//   width: 100%;
	//   display: flex;
	//   justify-content: center;
	//   align-items: center;
	//   background: #243256;
	//   .spinner {
	//     transform: rotate(-90deg);
	//     width: 30px;
	//     height: 30px;
	//     .circle {
	//       stroke-linecap: round;
	//       stroke-dasharray: 270;
	//       stroke-width: 2;
	//       stroke: #D9D9D9;
	//       fill: transparent;
	//       animation: spin 1s ease-in-out 0s normal infinite;
	//     }
	//   }
	//   @keyframes spin {
	//     from {
	//       stroke-dashoffset: 270;
	//     }
	//     to {
	//       stroke-dashoffset: 40;
	//     }
	//   }
	// }
}

.forgot-password-wrapper {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10% 5%;
	padding-top: 22%;
	padding-bottom: 0;

	.forgot-password {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.heading {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			// height: 20%;

			h5 {
				font-family: "Circular Std";
				font-style: normal;
				font-weight: 500;
				font-size: 20.21px;
				line-height: 26px;
				/* identical to box height, or 129% */
				text-align: center;
				color: #252627;
				margin-bottom: 7.2%;
			}
			p {
				font-family: "Circular Std";
				font-style: normal;
				font-weight: 450;
				font-size: 15px;
				line-height: 19px;
				text-align: center;
				color: #655f5f;
				width: 70%;
			}
		}
		.form-section {
			// height: 30%;
			margin-bottom: 17px;
			form {
				input {
					background: #f9fafa;
					border: 0.8px solid rgba(196, 196, 196, 0.5);
					border-radius: 4px;
					padding: 12px 24px;
					width: 100%;
					color: #252627;
					box-sizing: border-box;
				}
				.button-wrapper {
					margin-top: 26px;
					margin-bottom: 17px;

					button {
						width: 100%;
						padding: 10px;
						height: 40px;
					}
					p {
						font-style: normal;
						font-weight: 450;
						font-size: 13.59px;
						line-height: 17px;
						text-align: center;
						padding: 20px 0;

						color: #9c9393;
					}
				}
			}
		}

		.check-spam {
			font-weight: 450;
			font-size: 13.59px;
			line-height: 17px;
			text-align: center;
			color: #9c9393;
		}
		.bottom {
			width: 100%;
			text-align: center;
			font-family: "Circular Std";
			font-style: normal;
			font-size: 15px;
			line-height: 19px;
			/* identical to box height */
			color: #9c9393;

			.blue-link {
				color: #40798c;
				cursor: pointer;
				font-weight: 500;
			}
		}
	}
}

.confirmation {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-top: 4rem;

	.form-section {
		display: flex;
		flex-direction: column;
		font-family: "Circular Std";
		width: 100%;
		// height: 70%;
		// padding: 2.5% 0;
		form {
			width: 100%;
			height: 80%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.form-row {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-column-gap: 40px;
				padding: 5px 0;
				width: 100%;
			}
			.form-item {
				width: 100%;
				padding: 5px 0;
				label {
					padding: 5px 0;
					color: #655f5f;
					display: block;
				}
				.parent-label {
					.custom-wrapper {
						display: flex;
						justify-content: flex-end;
						width: 70%;
						.custom {
							display: flex;
							visibility: hidden;
							font-style: normal;
							font-size: 70%;
							align-items: center;
							background: rgba(37, 38, 39, 0.7);
							justify-content: center;
							padding: 5px 10px;
							text-align: center;
							color: #fff;
							border-radius: 4px;
						}
					}
					.info-wrapper {
						display: flex;
						align-items: center;

						h5 {
							padding: 2% 0;
							font-family: "Circular Std";
							font-style: normal;
							font-weight: 450;
							font-size: 15px;
							line-height: 19px;
							color: #252627;
						}
						.infosvg {
							width: 16px;
							padding-left: 10px;
							align-self: center;
						}
					}
					&:hover {
						.custom-wrapper {
							.custom {
								visibility: visible;
							}
						}
					}
				}
				input,
				select {
					background: #f9fafa;
					border: 0.8px solid rgba(196, 196, 196, 0.5);
					border-radius: 4px;
					padding: 5px;
					padding-left: 10px;
					width: 100%;
					// height: 40%;
					color: #252627;
					box-sizing: border-box;
				}
				.select-wrapper {
					padding: 0;
					padding-right: 10px;
					border: 0.8px solid rgba(196, 196, 196, 0.5);
					background: #f9fafa;
					border-radius: 4px;
				}
				select {
					border: 0;
				}
				.prompt {
					color: red;
					font-size: 70%;
					padding: 5px 0;
				}
			}
			.last-form-row {
				p {
					padding: 5% 0;
				}
			}
			.button-wrapper {
				width: 100%;
				.previous-wrapper {
					display: flex;
					&:hover {
						cursor: pointer;
					}
					p {
						padding-left: 10px;
						color: #655f5f;
					}
				}
				display: flex;
				padding: 2.5% 0;
				button {
					width: 100%;
					padding: 0.5rem;
					height: 36px;
				}
				// padding-bottom: 5%;
				justify-content: flex-end;
				grid-gap: 5%;
				align-items: center;
				p {
					font-size: 80%;
				}
				img {
					width: 15px;
					color: pink;
				}
				.disabled {
					background: #9c9393;
					cursor: not-allowed;
				}
			}
			.rem-me {
				display: flex;
				// fontSize: "80%", color: "#9C9393", paddingTop: "10px"
				padding-top: 10px;
				width: 40%;
				min-width: 100px;
				justify-content: space-between;
				align-items: center;
				font-size: 80%;
				padding: 15px 0;
				padding-bottom: 10px;
			}
		}
	}

	.heading {
		display: none;
	}

	.bottom {
		width: 100%;
		text-align: center;
		font-family: "Circular Std";
		font-style: normal;
		font-size: 15px;
		line-height: 19px;
		/* identical to box height */
		color: #9c9393;

		.blue-link {
			color: #40798c;
			cursor: pointer;
			font-weight: 500;
		}
	}
}

.new-passworddd {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	// padding-top: 4rem;

	.form-section {
		display: flex;
		flex-direction: column;
		font-family: "Circular Std";
		width: 100%;
		// height: 70%;
		// padding: 2.5% 0;
		form {
			width: 100%;
			height: 80%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.form-row {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-column-gap: 40px;
				padding: 5px 0;
				width: 100%;
			}
			.form-item {
				width: 100%;
				padding: 5px 0;
				label {
					padding: 5px 0;
					color: #655f5f;
					display: block;
				}
				.parent-label {
					.custom-wrapper {
						display: flex;
						justify-content: flex-end;
						width: 70%;
						.custom {
							display: flex;
							visibility: hidden;
							font-style: normal;
							font-size: 70%;
							align-items: center;
							background: rgba(37, 38, 39, 0.7);
							justify-content: center;
							padding: 5px 10px;
							text-align: center;
							color: #fff;
							border-radius: 4px;
						}
					}
					.info-wrapper {
						display: flex;
						align-items: center;

						h5 {
							padding: 2% 0;
							font-family: "Circular Std";
							font-style: normal;
							font-weight: 450;
							font-size: 15px;
							line-height: 19px;
							color: #252627;
						}
						.infosvg {
							width: 16px;
							padding-left: 10px;
							align-self: center;
						}
					}
					&:hover {
						.custom-wrapper {
							.custom {
								visibility: visible;
							}
						}
					}
				}
				input,
				select {
					background: #f9fafa;
					border: 0.8px solid rgba(196, 196, 196, 0.5);
					border-radius: 4px;
					padding: 5px;
					padding-left: 10px;
					width: 100%;
					// height: 40%;
					color: #252627;
					box-sizing: border-box;
				}
				.select-wrapper {
					padding: 0;
					padding-right: 10px;
					border: 0.8px solid rgba(196, 196, 196, 0.5);
					background: #f9fafa;
					border-radius: 4px;
				}
				select {
					border: 0;
				}
				.prompt {
					color: red;
					font-size: 70%;
					padding: 5px 0;
				}
			}
			.last-form-row {
				p {
					padding: 5% 0;
				}
			}
			.button-wrapper {
				width: 100%;
				.previous-wrapper {
					display: flex;
					&:hover {
						cursor: pointer;
					}
					p {
						padding-left: 10px;
						color: #655f5f;
					}
				}
				display: flex;
				padding: 2.5% 0;
				button {
					width: 100%;
					padding: 0.5rem;
				}
				// padding-bottom: 5%;
				justify-content: flex-end;
				grid-gap: 5%;
				align-items: center;
				p {
					font-size: 80%;
				}
				img {
					width: 15px;
					color: pink;
				}
				.disabled {
					background: #9c9393;
					cursor: not-allowed;
				}
			}
			.rem-me {
				display: flex;
				// fontSize: "80%", color: "#9C9393", paddingTop: "10px"
				padding-top: 10px;
				width: 40%;
				min-width: 100px;
				justify-content: space-between;
				align-items: center;
				font-size: 80%;
				padding: 15px 0;
				padding-bottom: 10px;
			}
		}
	}

	.heading {
		// display: none;
	}

	.bottom {
		width: 100%;
		text-align: center;
		font-family: "Circular Std";
		font-style: normal;
		font-size: 15px;
		line-height: 19px;
		/* identical to box height */
		color: #9c9393;

		.blue-link {
			color: #40798c;
			cursor: pointer;
			font-weight: 500;
		}
	}
}

.confirmation,
.new-password {
	.form-section {
		padding-top: 10%;
		height: 100%;
		form {
			height: 50%;
		}
	}
}

@media screen and (max-width: 1024px) {
	.sign-innew,
	.new-password {
		width: 100%;
		height: 100%;
		display: block;

		.form-section {
			background: #ffffff;
			box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
			border-radius: 4px;
			padding: 24px 32px;
		}

		.heading {
			margin-bottom: 58px;
			h5 {
				font-weight: 500;
				font-size: 18.21px;
				line-height: 26px;
			}
		}

		.form-section {
			height: unset;
			margin-bottom: 46px;
			padding-top: 26px;
			padding-bottom: 42px;

			form {
				height: unset;
				display: grid;
				gap: 22px;
				// gap: 5.34vw;s

				.form-item {
					label {
						font-size: 12px;
						line-height: 15px;
						color: #252627;
						padding: 3px 0;
						display: block;
					}
					input {
						background: #f9fafa;
						padding: 5px 0;
						border: 0.8px solid rgba(196, 196, 196, 0.5);
						border-radius: 4px;
						padding: 7px;
						padding-left: 12px;
						width: 100%;
						color: #252627;
						font-size: 10.5px;
						line-height: 13px;
					}
				}
				.button-wrapper {
					display: grid;
					width: 100%;
					padding-top: 12px;
					gap: 7px;
					button {
						width: 100%;
					}
					.rem-me {
						display: flex;
						align-items: center;
						gap: 10px;
						label {
							font-family: "Circular Std";
							font-style: normal;
							font-weight: 450;
							font-size: 12px;
							line-height: 15px;
							color: #655f5f;
						}
					}
					button {
						font-weight: 500;
						font-size: 12.8409px;
						line-height: 16px;
						height: 32px;
					}
					.forgot-link {
						text-decoration: none;
						color: #40798c;
						font-weight: 500;
						font-size: 12px;
						line-height: 15px;
						text-align: center;
						cursor: pointer;
						padding: 1px 0;
					}
				}
			}
		}

		.bottom {
			text-align: center;
			p,
			a {
				font-size: 12px;
				line-height: 15px;
				padding: 1px 0;
			}
			a {
				color: #40789c;
			}
		}
	}

	.forgot-password-wrapper {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		padding-top: 0;
		padding-bottom: 0;
		width: 100%;

		.forgot-password {
			display: block;
			width: 100%;

			.heading {
				margin-bottom: 83px;
				// display: block;
				h5 {
					font-weight: 500;
					font-size: 18.21px;
					line-height: 26px;
					margin-bottom: 24px;
				}

				p {
					font-weight: 450;
					font-size: 12px;
					line-height: 15px;
					text-align: center;
					width: 80%;
				}
			}
			.form-section {
				background: #ffffff;
				box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
				border-radius: 4px;
				padding: 0 32px;
				padding-top: 62px;
				padding-bottom: 51px;
				height: unset;
				margin-bottom: 28px;

				form {
					input {
						background: #f9fafa;
						border: 0.8px solid rgba(196, 196, 196, 0.5);
						border-radius: 4px;
						padding: 7.5px;
						padding-bottom: 12px;
						width: 100%;
						color: #252627;
						box-sizing: border-box;
						font-size: 10.5px;
						line-height: 13px;
					}
					.button-wrapper {
						padding: 0;
						color: #9c9393;
						margin-bottom: 0;
						margin-top: 21px;

						button {
							width: 100%;
							font-size: 12.8409px;
							line-height: 16px;
							padding: 0.5rem;
							height: 32px;
						}

						p {
							font-style: normal;
							font-weight: 450;
							font-size: 13.59px;
							line-height: 17px;
							text-align: center;
							padding: 20px 0;

							color: #9c9393;
						}
					}
				}
			}

			.check-spam {
				margin-bottom: 63px;
				font-size: 12px;
				line-height: 15px;
				text-align: center;
			}

			.bottom {
				font-size: 12px;
				line-height: 15px;
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 63px;
			}
		}
	}

	.confirmation {
		display: block;
		width: 100%;
		padding-top: 0;
		.heading {
			text-align: center;
			display: block;
			margin: 0 auto;
			margin-bottom: 72px;
			font-family: "Circular Std";
			font-style: normal;
			font-weight: 450;
			font-size: 12px;
			line-height: 15px;
			text-align: center;
			color: #252627;

			span {
				color: #40798c;
			}
		}
		.form-section {
			background: #ffffff;
			box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
			border-radius: 4px;
			padding: 62px 30px 30px 32px;
			height: unset;
			margin-bottom: 28px;

			.email {
				display: none;
			}
			form {
				.button-wrapper {
					button {
						font-size: 12.8409px;
						line-height: 16px;
						height: 32px;
					}
				}
			}
		}
		.bottom {
			font-size: 12px;
			line-height: 15px;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 1rem;
		}
	}
	.new-password {
		.form-section {
			height: 60%;
		}
	}

	.dots {
		width: 20%;
	}
}
