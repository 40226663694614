main {
	background: #ffffff;
	// max-width: 800px;
	margin: 0 auto;
	color: #252627;
	// width: 100%;
	width: 800px;
	padding: 80px;
}

h3 {
	font-weight: 500;
	font-size: 18px;
	line-height: 20px;
	color: #252627;
}

.container_box {
	// padding: 0 62px;

	&__banner {
		background-image: linear-gradient(
				0deg,
				rgba(36, 50, 86, 0.3),
				rgba(36, 50, 86, 0.3)
			),
			url(../../resource/img/slider-bg2.png);
		min-height: 150px;
		background-size: cover;
		border-radius: 0;
		display: flex;
		flex-direction: column;
		// align-items: center;
		// justify-content: center;
		border-radius: 8px;

		padding: 40px 30px;

		p,
		h3 {
			color: #fff;
			text-align: left;
		}

		h3 {
			font-size: 24px;
			line-height: 28px;
			font-weight: 700;
			margin-bottom: 16px;
		}

		p {
			max-width: 70%;

			span {
				font-weight: 700;
			}
		}
	}
}

.logoTop {
	text-align: center;
	padding-bottom: 36px;
}
.logoTop img {
	/* width: 154px; */
}

.msgBlock {
	color: #252627;
	text-align: center;
	max-width: 600px;
	padding: 1rem 2rem;
	margin: auto;

	padding: 34px 14px 21px 18px;
	border-top: 0.5px solid #40798c;
	border-bottom: 0.5px solid #40798c;

	h3 {
		margin-bottom: 26px;
	}

	p {
		font-style: normal;
		font-weight: 450;
		font-size: 16px;
		line-height: 25px;
		text-align: center;
		color: #252627;

		a {
			color: #40798c;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.infoSection {
	padding-top: 34px;
	padding-bottom: 22.5px;

	h3 {
		text-align: center;
		margin-bottom: 15px;
	}

	&__box {
		padding: 1rem;
		padding-left: 34px;
		padding-right: 32px;
		background: #f2f5fe;
		border: 0.5px solid #d9d9d9;
		border-radius: 6px;
	}
}

.Table {
	width: 100%;
	font-weight: 450;
	font-size: 15px;
	line-height: 20px;
	color: #252627;

	tr {
		td {
			padding-top: 6px;
			padding-bottom: 10px;
			border-bottom: 0.5px dashed #d9d9d9;

			&:first-of-type {
				color: #40798c;
				font-size: 1rem;
			}

			&:last-of-type {
				text-align: right;
			}
		}
	}
}

.itineraries {
	h3 {
		text-align: center;
		margin-bottom: 10px;
	}
}

.singleItinerary {
	display: flex;
	flex-direction: column;
	align-items: center;

	.flightCount {
		background: #40798c;
		border-radius: 3.81px;
		padding: 1px 24px;
		color: #f9fafa;
		font-weight: 450;
		font-size: 12.97px;
		line-height: 20px;
	}
}

.singleItinerary__box {
	padding: 44px 56px;
	padding-top: 1rem;
	background: #f2f5fe;
	border: 0.5px solid #d9d9d9;
	border-radius: 6px;
	text-align: center;
	width: 100%;
	margin-top: 11px;
}
.singleItinerary__box .airline {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;
	font-weight: 450;
	font-size: 10px;
	line-height: 18px;
	color: #9c9393;
	margin-bottom: 20px;
}
.singleItinerary__box .airline img {
	width: 60px;
	height: 60px;
}

.singleItinerary__box__trip > div:first-of-type {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	font-weight: 450;
	font-size: 15px;
	line-height: 20px;
}
.singleItinerary__box__trip .baggage {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 6px;
	font-weight: 450;
	font-size: 15px;
	line-height: 20px;
	color: #40798c;
	margin-top: 37px;
	margin-bottom: 20px;
}
.singleItinerary__box__trip .layover {
	padding: 7px;
	background: #f9fafa;
	border: 0.5px solid #d9d9d9;
	border-radius: 6px;
	font-weight: 450;
	font-size: 14px;
	line-height: 20px;
	color: #655f5f;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-align: center;
	gap: 0.5rem;
}

.gridFlight {
	display: grid;
	grid-template-rows: 46px 1fr 20px;
	gap: 4.5px;
}
.gridFlight .cities {
	padding-bottom: 5.5px;
	border-bottom: 0.5px dashed #dccfcf;
}
.gridFlight .city {
	font-size: 16px;
	margin-top: 3px;
	text-transform: uppercase;
}
.gridFlight .planeWrap {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: center;
	padding-bottom: 5.5px;
}
.gridFlight .date {
	color: #222222;
	padding-bottom: 6px;
	border-bottom: 0.5px dashed #d9d9d9;
}
.gridFlight .grey {
	font-weight: 450;
	font-size: 14px;
	line-height: 20px;
	color: #9c9393;
}

.Footer {
	padding: 42px 1rem;
	padding-bottom: 40px;
	text-align: center;
	background: #243256;

	p {
		color: white;
		font-weight: 500;
		font-size: 15px;
		line-height: 23px;
		margin-bottom: 0.75rem;
	}

	.small {
		font-weight: 450;
		font-size: 13px;
		line-height: 20px;
		margin-bottom: 0;

		a {
			color: #ffffff;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.icons {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		margin-top: 20px;

		& > a {
			color: white;
			text-decoration: none;
			padding: 0.3rem;

			.outline {
				width: 28px;
				height: 28px;
				border: 1.23077px solid rgb(196, 196, 196);
				border-radius: 50%;
				display: grid;
				place-items: center;
			}
		}
	}
}

.BottomInfo {
	padding: 2.25rem 0;
	color: #252627;
	/* width: 95%; */

	h3 {
		font-weight: 500;
		font-size: 18px;
		line-height: 33px;
		margin-bottom: 4px;
	}

	p {
		font-weight: 450;
		font-size: 13px;
		line-height: 22px;

		a {
			color: #40798c;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
.Signoff {
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	color: #999999;
	margin: 27px 0;
}
