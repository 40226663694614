.OnHoldSummaryPage {
	.back {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 1.25rem;
		font-weight: 450;
		font-size: 13.429px;
		line-height: 17px;
		color: #40798c;
		cursor: pointer;
		margin-bottom: 18px;
	}

	h3 {
		margin-bottom: 0.5rem;
		span {
			color: #40798c;
		}
	}

	.msg {
		font-weight: 450;
		font-size: 16px;
		line-height: 25px;
		color: #655f5f;
		margin-bottom: 30px;
	}

	.priceBreakdown {
		margin-top: 0.5rem;

		.double-col {
			// display: grid;
			// grid-template-columns: 2fr 1fr;
			// color: green;
			> :nth-child(2) {
				font-weight: 400;
			}
		}
	}

	.left-tables {
		margin-top: 2.25rem;

		.miniHead {
			padding: 4px 0;
		}

		.PlainTable__tableBody {
			padding-left: 0;
			padding-right: 0;
		}

		& > :first-child {
			margin-bottom: 21px;
			.PlainTable__tableBody {
				padding-left: 2rem;
				padding-right: 1rem;
			}
		}

		& > * {
		}
	}

	.tableBody {
		font-weight: 450;

		.double-col {
			// display: grid;
			// grid-template-columns: 2fr 1fr;
			margin-bottom: 1rem;

			> p {
				font-weight: 400;
			}
		}
	}

	.printer {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 0.5rem;
		cursor: pointer;
		color: #40798c !important;
		margin-top: 0.8rem;
	}

	.flightDetails {
		color: #9c9393;
		font-weight: 450;
		font-size: 14.01px;
		line-height: 18px;

		img {
			width: 70px;
		}

		> div {
			.overview {
				display: grid;
				grid-template-columns: 80px 1fr;
				// flex-direction: row;
				align-items: center;
				gap: 63px;
				padding: 1rem 0;
				padding-left: 34px;
				padding-right: 1rem;
				padding-bottom: 0;

				.badge-img {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 22px;
				}

				.details {
					> div {
						display: flex;
						flex-direction: row;
						align-items: center;
						gap: 32px;

						> div > p {
							margin-bottom: 18px;
						}
					}
				}
			}
		}

		.black {
			color: #252627;
			font-size: 14px;
			line-height: 18px;
		}
	}

	.PaymentComponent {
		padding: 50px 0;
		padding-bottom: 0;

		&--mobile {
			display: none;
		}

		&__title {
			margin-bottom: 14px;
		}

		&__info {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 12px;
			font-weight: 450;
			font-size: 12px;
			line-height: 15px;
			color: #9c9393;

			a {
				color: #655f5f;
				text-decoration: underline;
				cursor: pointer;
				font-weight: 500;
			}
		}

		.btnWrap {
			display: flex;
			margin-top: 10px;
			& > button {
				font-weight: 500;
				font-size: 17.28px;
				line-height: 37px;
				padding: 7px 73px;
				height: 51px;
				width: 100%;
			}
		}
	}

	.PaymentBody {
		padding: 20px;
		background: #ffffff;
		border: 0.5px solid #c4c4c4;
		border-radius: 5px;
		margin-bottom: 28px;

		.radio-options {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			gap: 15px;
		}

		.radio-option {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			font-weight: 450;
			font-size: 13.59px;
			line-height: 17px;
			color: #9c9393;

			.title {
				margin-top: 0.5rem;
				color: #252627;
			}

			& > div {
				cursor: pointer;
			}
		}
	}

	.pay-now {
		margin-top: 20px;
		display: flex;
		justify-content: flex-end;

		button {
			font-weight: 500;
			font-size: 17.28px;
			line-height: 37px;
			padding: 7px 73px;
			background: #243256;
			border-radius: 3px;
			color: white;
			cursor: pointer;
			border: 0;
		}
	}
}

.OnHoldSummaryPage-mobile {
	display: none;
}

@media screen and (max-width: 800px) {
	.OnHoldSummaryPage {
		display: none;
	}
	.OnHoldSummaryPage-mobile {
		display: block;
		padding: 2.815rem 0;
		background: #ffffff;
		padding-bottom: 90px;

		& > :first-child {
			padding: 0 20px;
		}

		h3 {
			margin-bottom: 0.75rem;
			span {
				color: #40798c;
			}
		}

		.msg {
			font-weight: 450;
			font-size: 14px;
			line-height: 20px;
			color: #655f5f;
			padding-bottom: 30px;
			border-bottom: 1px solid rgba(196, 196, 196, 0.5);
		}

		.black {
			font-weight: 450;
			font-size: 13px;
			line-height: 16px;

			color: #252627;
		}

		.passengers {
			padding: 30px 20px;
			border-bottom: 1px solid #c4c4c4;

			h4 {
				margin-bottom: 1.25rem;
			}
		}

		.price-breakdown {
			padding: 20px 22px;
			padding-right: 2rem;
			background: #f9fafa;
		}

		.FlightInformation {
			color: #252627;
			border-bottom: 1px solid rgba(196, 196, 196, 0.5);
			padding: 31.5px 20px;
			.double-col {
				display: grid;
				grid-template-columns: 1fr 4px 1fr;
				gap: 48px;
				margin-bottom: 0.75rem;
				& > :first-child {
					font-weight: 400;
					color: #655f5f;
				}
				& > span {
					color: #9c9393;
				}
			}

			&__title {
				font-weight: 500;
				font-size: 16px;
				line-height: 20px;
				margin-bottom: 20px;
			}
		}

		.payment-body {
			margin-top: 18px;

			.pay-now {
				padding: 20px 0;

				button {
					width: 100%;
					font-weight: 500;
					font-weight: 500;
					font-size: 13px;
					line-height: 16px;
					padding: 13px 72px;
					background: #243256;
					border-radius: 3px;
					color: white;
					cursor: pointer;
					border: 0;
				}
			}
		}

		.PaymentComponent {
			display: none;
			.btnWrap {
				padding: 20px 0;

				button {
					width: 100%;
					font-weight: 500;
					font-weight: 500;
					font-size: 13px;
					line-height: 16px;
					padding: 13px 72px;
					background: #243256;
					border-radius: 3px;
					color: white;
					cursor: pointer;
					border: 0;
				}
			}
			&--mobile {
				display: grid;
				place-items: center;
				width: 100%;
				height: 72px;

				background: #243256;
				border-radius: 0px;
				border: 0px;
				color: #ffffff;
				cursor: pointer;

				position: fixed;
				bottom: 0;
				left: 0;

				font-weight: 500;
				font-size: 20px;
			}
		}

		.PaymentBody {
			padding: 1rem 0;
			.radio-option {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				font-weight: 450;
				font-size: 13.59px;
				line-height: 17px;
				color: #9c9393;
				max-width: 340px;
				margin-bottom: 22px;

				.title {
					margin-top: 0.5rem;
					margin-bottom: 0.25rem;
					color: #252627;
				}
			}
		}
	}
}
