.FlightInfoDrawer {
	position: relative;
	&__Overview {
		display: grid;
		grid-template-columns: 73fr 18fr;
		flex-direction: row;
		align-items: center;
		gap: 2rem;
		justify-content: space-between;
		padding: 20px 32px;
		background: #ffffff;
		border: 1px solid #f5f5f5;
		border-radius: 8px;
		box-shadow: 0px 2px 2px rgba(64, 121, 140, 0.25);
		cursor: pointer;

		&--selected {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}

		.overview_outbounds {
			display: grid;
			grid-template-rows: 1fr;
			gap: 40px;

			&__outbound {
				display: grid;
				grid-template-columns: 1.5fr 2.5fr 1fr 1fr;
				align-items: center;
			}
		}

		.airline {
			font-size: 13.59px;
			line-height: 17px;
			color: #9c9393;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			gap: 2px;
			img {
				height: 40px;
			}
		}

		.times {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: center;
			gap: 1rem;

			.stopover-indicator {
				width: 70px;
				height: 2px;
				background: #c4c4c4;
				border-radius: 4px;
				position: relative;
				margin-top: 0.4rem;

				> * {
					width: 100%;
					position: absolute;
					top: 1px;
					left: 0;
					transform: translateY(-50%);
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-around;
				}

				.stop-circle {
					width: 6px;
					height: 6px;
					border-radius: 50%;
					background-color: #c4c4c4;

					&:hover {
						.CustomTooltip {
							display: inline;
							left: 50%;
						}
					}
				}
			}
		}

		.icons {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			gap: 21px;
			svg {
				height: 17px;
				width: 17px;
			}
		}

		.double-block {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 10px;

			& > :first-child {
				font-size: 16px;
				line-height: 20px;
				color: #252627;
			}
			& > :last-child {
				font-size: 13.59px;
				line-height: 17px;
				color: #655f5f;
			}
		}

		.right {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 1.125rem;

			.double-block {
				gap: 3px;
			}
		}
	}

	&__MoreInfo {
		background: #f5f5f5;
		color: #655f5f;
		border: 0.8px solid rgba(196, 196, 196, 0.5);
		border-radius: 0px;
		min-height: 233px;
		max-height: 800px;
		font-size: 12px;
		line-height: 15px;
		overflow: hidden;
		transition: all 0.5s;

		&--shut {
			height: 0;
			min-height: 0;
			max-height: 0;
		}

		& > div {
			padding: 32px 0;
			padding-left: 52px;
			padding-right: 61px;
		}

		.Departure_nightDay {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 1rem;

			margin-bottom: 1rem;
		}

		.singleFlight {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: space-between;
			margin-bottom: 42px;

			.logo {
				margin: 0 44px;
				// margin-right: 36px;
				margin-top: 2.2rem;
				width: 60px;
			}

			&__imgData {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
			}

			&__left-grid {
				display: grid;
				grid-template-columns: 30px 52px 1fr;
				grid-gap: 19px;

				&-longer {
					grid-template-columns: 30px 1fr;
				}

				> :nth-child(3) {
					margin-left: 0.5rem;
				}
			}
			.big-black {
				font-size: 13.59px;
				line-height: 17px;

				color: #252627;
			}

			.onePlane {
				> :first-child {
					> * {
						margin-bottom: 19px;
					}
				}
			}

			.planeInfo {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 22px;
			}
			.extras {
				margin-left: 128px;
				> :first-child {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					gap: 1rem;
					margin-bottom: 18px;
				}
				svg {
					cursor: pointer;
					margin-right: 22px;
				}
			}
			.two-grid {
				display: grid;
				grid-template-columns: 16px 1fr;
				grid-gap: 24px;
				span {
					color: #9c9393;
				}
			}
			.layover-banner {
				background: rgba(112, 169, 161, 0.52);
				border-radius: 3px;
				font-size: 13.59px;
				line-height: 17px;
				color: #000000;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				gap: 1rem;
				padding: 6px 30px;
				padding-left: 60px;
				margin-top: 22px;

				div {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 1.5rem;
				}
			}
		}

		.bottom {
			margin-left: 97px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			background: none;
			padding: unset;
			.fare {
				font-weight: 450;
				font-size: 12px;
				line-height: 15px;
				color: #40798c;
				text-decoration: none;
				cursor: pointer;
			}

			&__left-grid {
				display: grid;
				grid-template-columns: 30px 1fr;
				grid-gap: 19px;
				text-align: left;

				> :nth-child(3) {
					margin-left: 0.5rem;
				}
			}
		}

		.bottomActions {
			display: grid;
			grid-template-columns: 130px 156px 1fr;
			gap: 2px;
			align-items: center;

			button {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 4px;

				color: #40798c;
				font-size: 12px;
				font-weight: 450;

				border: 0;
				background-color: transparent;
				cursor: pointer;
			}

			&__buttons {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 18px;
				padding-left: 40px;
			}
		}
	}

	&__MobileInfo {
		display: none;
	}
}

@media screen and (max-width: 930px) {
	.FlightInfoDrawer {
		position: static;
		&__Overview {
			gap: 15px;
			padding: 9px 15px;
			padding-bottom: 11px;
			grid-template-columns: 73fr 18fr;

			.overview_outbounds {
				display: grid;
				grid-template-rows: 1fr;
				gap: 8px;

				&__outbound {
					display: grid;
					grid-template-columns: 1.5fr 2.5fr;
				}
			}

			.airline > p {
				font-size: 12px;
				line-height: 15px;
			}

			.time-direct,
			.icons {
				display: none;
			}

			.double-block {
				gap: 1px;
				> :first-child {
					font-size: 13px;
					line-height: 16px;
				}

				> :last-child {
					font-size: 12px;
					line-height: 15px;
				}
			}

			.MobTimesWrap {
				display: flex;
				flex-direction: column;
				gap: 0.15rem;

				& > span {
					height: 18px;
				}
			}

			.times {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				gap: 10px;

				.stopover-indicator {
					width: 38px;
				}
			}
			.right {
				flex-direction: column;
				gap: 0.15rem;

				svg {
					// display: none;
				}

				.double-block > :first-child {
					font-size: 12px;
					line-height: 15px;
				}
			}
		}

		&__MoreInfo {
			display: none;
		}

		&__MobileInfo {
			display: block;
			width: 100vw;
			height: 100vh;
			// position: absolute;
			// top: 0;
			// left: 0;
			// z-index: 2;

			overflow: auto;

			background-color: #ffffff;
			z-index: 32;

			&--shut {
				display: none;
			}

			.FlightInfoNav {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				height: 61px;
				background-color: #ffffff;
				box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
				padding: 10px 25px;
				padding-right: 2rem;
				position: fixed;
				top: 0;
				width: 100%;

				font-weight: 450;
				font-size: 12px;
				line-height: 15px;
				color: #252627;

				svg {
					width: 18px;
					height: 18px;
					cursor: pointer;
				}
			}

			.mobileInfo_outbounds {
				display: flex;
				flex-direction: column;
				align-items: stretch;
				gap: 40px;
			}

			.padding-wrapper {
				padding: 10px 25px;
				padding-top: 0;
				padding-right: 2rem;
				padding-bottom: 110px;
				margin-top: 88px;
			}

			.Departure_nightDay {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				gap: 0.5rem;
				margin-bottom: 21px;

				svg {
					width: 14px;
					height: 14px;
				}
				p {
					font-weight: 450;
					font-size: 10.5px;
					line-height: 13px;
					color: #9c9393;
				}
			}

			.grey-info {
				font-weight: 450;
				font-size: 12px;
				line-height: 15px;
				color: #655f5f;

				display: flex;
				flex-direction: row;
				align-items: center;
				width: max-content;
				gap: 10px;
				background: rgba(196, 196, 196, 0.5);
				border-radius: 50px;
				padding: 2px 7px;
				margin-bottom: 0.5rem;
			}

			.plenty-info {
				margin-bottom: 1rem;
				> div {
					display: grid;
					grid-template-columns: 40px 1fr;
					color: #9c9393;
				}

				.black {
					color: #252627;
				}
				.flex-center {
					align-items: center;
					gap: 0.5rem;
					margin-bottom: 8px;

					img {
						width: 40px;
					}
				}
				.flex-start {
					align-items: flex-start;
					gap: 0.5rem;
					margin-bottom: 25px;

					> div {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						gap: 0.25rem;

						p {
							display: flex;
							flex-direction: row;
							align-items: center;
							gap: 1rem;
						}
					}
				}
			}

			.blue-banner {
				display: grid;
				grid-template-columns: 40px 1fr;
				gap: 0.5rem;
				> div {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					gap: 1rem;
				}

				background: rgba(112, 169, 161, 0.52);
				border-radius: 3px;
				padding: 3px 2rem;
				padding-left: 0;
				margin-bottom: 24px;
			}

			.fare-breakdown {
				display: grid;
				grid-template-columns: 40px 1fr;
				gap: 0.5rem;
				margin-bottom: 24px;

				> div {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					gap: 1rem;

					p {
						color: #40798c;
						cursor: pointer;
					}

					.iconss {
						display: flex;
						flex-direction: row;
						align-items: center;
						gap: 22px;

						svg {
							width: 18px;
							height: 18px;
							> path {
								fill: #9c9393;
							}
						}
					}
				}
			}

			.button-bottom {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				background: #f9fafa;
				border: 0.4px solid rgba(196, 196, 196, 0.5);

				position: fixed;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 76px;
				padding-top: 1rem;
				padding-bottom: 1rem;
				padding-left: 25px;
				padding-right: 2rem;

				p {
					font-weight: 500;
					font-size: 18px;
					line-height: 23px;
					color: #252627;
				}

				button {
					padding: 14px 31px;
					font-weight: 450;
					font-size: 12px;
					line-height: 15px;
				}
			}
		}
	}
}
