@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.Button {
	text-align: center;
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	&--outline {
		min-width: 89px;

		width: fit-content;
		font-weight: 450;
		font-size: 15.996px;
		line-height: 20px;
		padding: 0.5rem 11px;

		border-radius: 3.85px;
		cursor: pointer;

		background-color: transparent;
		color: #9c9393;
		border: 1px solid #dfdfdf;
	}
}

.pry {
	min-width: 89px;

	width: fit-content;
	font-weight: 500;
	font-size: 15.996px;
	line-height: 20px;
	padding: 0.5rem 11px;

	background: #243256;
	border-radius: 3.85px;
	border: 0;
	color: #fff;
	cursor: pointer;

	&:hover {
		background: lighten(#243256, 5%);
	}
}
.disabled {
	background: #e5e5e5;
	cursor: not-allowed;
	&:hover {
		background: #e5e5e5;
	}
}
.sec {
	min-width: 89px;

	// height: 32px;
	padding: 7.5px 20px;
	// left: 397px;
	color: #243256;
	border-radius: 3.85px;
	border: 0;
	background: #fff;
	cursor: pointer;
}

.btn-loader {
	-webkit-animation: spin 0.8s linear infinite; /* Safari */
	animation: spin 0.8s linear infinite;
	color: #ffffff;
}

@media screen and (max-width: 480px) {
	.pry {
		font-size: 9.55735px;
		line-height: 12px;
		padding: 4px 8px;
		min-width: 62px;
	}
}
