.UserAvatar {
	// overflow: hidden;
	font-size: 100%;
	cursor: pointer;
	position: relative;
	border: 0.8px dashed rgba(196, 196, 196, 0.5);
	border-radius: 50px;
	padding: 10px 25px 10px 10px;

	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 31px;

	font-weight: 450;
	font-size: 13.59px;
	line-height: 17px;

	&--incomplete {
		border: 1px dashed rgba(161, 15, 25, 1);

		.UserAvatar__farRight {
			color: #9c9393;
			font-size: 14.01px;
			font-weight: 450;
		}
	}

	.editFlex {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 4px;

		svg {
			width: 16px;
			height: 16px;
			color: #9c9393;
		}
	}

	&__circle {
		width: 40px;
		height: 40px;
		display: grid;
		place-items: center;
		border-radius: 50%;
		color: #ffffff;
	}

	&__farRight {
		margin-left: auto;
		color: #9c9393;

		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 13px;
	}

	img {
		width: 100%;
	}

	.UserInfo {
		display: none;
	}

	&:hover {
		.UserInfo {
			position: absolute;
			top: 100%;
			left: 0;
			width: 308px;
			height: 92px;
			background-color: #ffffff;
			border: 0.8px solid rgba(196, 196, 196, 0.5);
			box-shadow: 0px 4px 4px rgba(156, 147, 147, 0.1);
			border-radius: 8px;
			z-index: 1;
			padding: 10px 12px;

			font-family: "Circular Std";
			font-style: normal;
			font-weight: 450;
			font-size: 14.01px;
			line-height: 18px;
			color: #9c9393;
			display: grid;
			grid-template-columns: 60px 1fr;
			gap: 13px;
			// align-items: center;

			.circle {
				border-radius: 50%;
				// overflow: hidden;
				display: grid;
				place-items: center;
				font-size: 100%;
				color: #ffffff;
				cursor: pointer;
				width: 60px;
				height: 60px;
				font-size: 20.31px;
				line-height: 26px;

				img {
					width: 100%;
				}
			}
			.name {
				font-family: "Circular Std";
				font-style: normal;
				font-weight: 500;
				font-size: 16.21px;
				line-height: 21px;
				color: #252627;
				margin-bottom: 0.25rem;
			}

			.email {
				margin-bottom: 0.25rem;
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.UserAvatar {
		// overflow: hidden;

		border-radius: 50px;
		padding: 10px;

		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 30px;

		font-size: 13px;
		line-height: 16px;

		&__circle {
			width: 30px;
			height: 30px;
		}

		&__farRight {
			// display: none;
		}
	}
}
