.dashboard {
	padding: 2.8125rem 13.9vw;
	padding-left: 14.09vw;
	padding-right: 12.5vw;
	padding-bottom: calc(2.8125rem + 61px);
	background-color: #ffffff;
	* {
		font-family: "Circular Std";
		// line-height: 23px;
	}

	.on-hold-top {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin: 0 0 12px 0;
		width: 100%;
	}

	.mb-3 {
		margin-bottom: 0.75rem;
	}

	.first {
		font-family: "Circular Std";
		font-style: normal;
		font-weight: 500;
		font-size: 18.206px;
		line-height: 23px;
		color: #252627;

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		.link {
			font-weight: 500;
			font-size: 14.0088px;
			line-height: 18px;
			// color: #655f5f;
			color: #243256;

			cursor: pointer;

			// line under
			&::after {
				content: "";
				display: block;
				width: 100%;
				height: 1px;
				background: #243256;
				transition: width 0.3s;
			}
			&:hover {
				color: #40798c;
				&::after {
					background: #243256;
					width: 100%;
				}
			}
		}
	}
	.dash-top {
		display: flex;
		grid-template-columns: 1fr 310px;
		justify-content: space-between;
		gap: 2.25rem;
		// height: 30vh;
		height: 255px;
		box-sizing: border-box;

		.slider-wrapper {
			// width: 50vw;
			width: calc(100% - 310px - 2.25rem);
			max-width: 697px;
			height: 100%;
			flex-shrink: 1;

			.alice-carousel {
				height: 100%;

				& > :first-child {
					height: 100%;
				}
			}
			.alice-carousel__wrapper {
				height: 100%;
			}
			.alice-carousel__prev-btn,
			.alice-carousel__next-btn,
			.alice-carousel__dots {
				display: none;
			}
		}
		.slider {
			width: 100%;
			background: url("../../resource/img/slider-bg2.png");
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			border-radius: 8px;
			color: #fff;
			position: relative;
			height: 100%;

			::before {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: rgba(138, 137, 137, 0.219);
				z-index: -2;
				width: 100%;
				height: 100%;
				box-sizing: border-box;
				border-radius: 8px;
			}

			.slider-text-wrapper {
				// z-index: 5;
				position: absolute;
				height: 100%;
				width: 100%;

				.slider-text {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					// height: 60%;
					padding: 30px;
					padding-top: 40px;

					h2 {
						margin-bottom: 21px;
					}
					p {
						max-width: 50%;
						margin-bottom: 26px;
						font-weight: 450;
					}
				}
			}
		}
		.funding-new {
			// height: calc(100% - 5px);
			// width: 100%;
			flex-shrink: 0;
			width: 310px;
			display: flex;
			// padding: 5px;
			flex-direction: column;
			height: 100%;

			.fund-heading {
				display: flex;
				justify-content: center;
				align-items: center;
				background: #243256;
				height: 40%;

				border: 0.355462px solid rgba(101, 95, 95, 0.14);
				border-radius: 5.33763px 5.33763px 0px 0px;
				p {
					color: #fff;
					padding-left: 20px;
				}
			}
			.fund-body {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: stretch;
				height: 100%;
				border: 0.8px solid rgba(196, 196, 196, 0.5);
				padding: 10px 15px;

				.fund-line {
					// width: calc(100% - 30px);
					display: flex;
					justify-content: space-between;
					// display: grid;
					// grid-template-columns: 3fr 1fr;
					// padding: 15px 10px;
					color: #655f5f;
					padding: 10px 5px;
					padding-right: 21px;
					transition: all 0.5s;
					border-radius: 2px;

					&--active {
						cursor: pointer;
						&:hover {
							box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
						}
					}

					.img-container {
						display: flex;
						width: 60%;
						img {
							padding-right: 7.5%;
						}
						.q-mark {
							position: relative;

							&:hover {
								.CustomTooltip {
									display: inline;
								}
							}
						}
					}
				}
			}
		}
	}

	.middle-wrapper {
		padding: 5% 0;

		.middle {
			display: flex;
			width: 100%;
			justify-content: space-between;
			gap: 12px;
			min-height: 118px;

			.middle-card {
				background: #ffffff;
				border: 0.8px solid rgba(196, 196, 196, 0.5);
				border-radius: 8px;
				display: flex;
				width: 100%;
				max-width: 241px;
				height: 117.07px;
				align-items: center;
				padding: 7.5px 15px;
				justify-content: space-between;
				transition: all 0.5s;
				// display: none;u

				&:hover {
					box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
				}

				div {
					height: 50%;
					justify-content: flex-end;
					display: flex;
					flex-direction: column;

					img {
						width: 30px;
						height: 30px;
					}
				}
				.left {
					gap: 9.3px;

					p {
						font-family: "Circular Std";
						font-style: normal;
						font-weight: 300;
						font-size: 16.1089px;
						line-height: 20px;
						display: flex;
						align-items: center;

						color: #655f5f;
					}
				}
				.right {
					text-align: right;
					gap: 2px;
					.count {
						font-family: "Circular Std";
						font-style: normal;
						font-weight: 500;
						font-size: 16.3344px;
						line-height: 21px;
						text-align: right;

						color: #9c9393;
					}
					.amount {
						font-family: "Circular Std";
						font-style: normal;
						font-weight: 500;
						font-size: 16.3344px;
						line-height: 21px;
						text-align: right;

						color: #40798c;
					}
				}
			}
		}
	}

	.table-mobile {
		display: none;
	}

	.bottom {
		display: flex;
		justify-content: space-between;
		height: 40%;
		.on-hold,
		.search-history {
			h3 {
				color: #252627;
				padding: 20px 0;
			}
		}
		.on-hold,
		.search-history {
			width: 65%;
			.on-hold-heading {
				border-radius: 8px 8px 0px 0px;
			}
			.on-hold-heading,
			.on-hold-body {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
				border: 0.8px solid rgba(196, 196, 196, 0.5);
				text-align: center;
				padding: 5px 0;
				width: 100%;
				p {
					padding: 5px 0;
					font-weight: 450;
					font-size: 14.0088px;
					line-height: 18px;
					color: #655f5f;
				}
				.status {
					color: #40798c;
					text-decoration: underline;
					width: 100%;
				}
			}
			.on-hold-heading {
				p {
					color: #252627;
				}
			}
			.grey {
				background-color: #e9ebee;
			}
		}
		.search-history {
			width: 30%;
			.search-history-heading {
				border-radius: 8px 8px 0px 0px;
			}
			.search-history-heading,
			.search-history-body {
				display: grid;
				grid-template-columns: 1fr 1fr;
				border: 0.8px solid rgba(196, 196, 196, 0.5);
				padding: 5px 10px;
				box-sizing: border-box;
				width: 100%;
				p {
					padding: 5px 0;
					font-weight: 450;
					font-size: 14.0088px;
					line-height: 18px;
					color: #655f5f;
				}
				&:last-child {
					border-radius: 0px 0px 8px 8px;
				}
			}
			.search-history-heading {
				p {
					color: #252627;
				}
			}
			.search-history-body-wrapper {
				display: block;
				height: 100%;
				width: 100%;
			}
		}
		.color-text {
			font-weight: 450;
			font-size: 14.0088px;
			line-height: 18px;
			display: flex;
			align-items: center;
			text-align: center;

			color: #9c9393;
		}
		.on-hold-body-empty {
			width: 100%;
			height: 100%;
			border: 0.8px solid rgba(196, 196, 196, 0.5);
			display: flex;
			align-items: center;
			justify-content: center;
			// background-color: #40798c;
			.no-data {
				height: 35%;
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: space-between;
				text-align: center;
				p {
					font-weight: 450;
					font-size: 14.0088px;
					line-height: 18px;
					color: #9c9393;
				}
			}
		}
	}

	.fundMobile,
	.chatMobile {
		display: none;
		cursor: pointer;
	}
}
.cursor {
	cursor: pointer;
}

.no-booking {
	margin-bottom: 18px;
}

@media screen and (max-width: 1220px) {
	.dashboard {
		.bottom {
			flex-direction: column;
			gap: 30px;

			.on-hold,
			.search-history {
				width: 100%;
			}
		}

		.dash-top {
			.slider-wrapper {
				width: 100%;
				max-width: 100%;
			}
			.funding-new {
				display: none;
			}
		}

		.fundMobile {
			display: block;
			position: fixed;
			bottom: 4rem;
			right: 11.9vw;

			&__table {
				position: absolute;
				bottom: 125%;
				background-color: #ffffff;
				border-radius: 9.92px;
				width: 248px;
				right: -620%;
				overflow: hidden;
				border: 0;
				transition: all 0.3s;
				// opacity: 0;
				z-index: -1;
				box-shadow: 0px 4.96px 4.96px rgba(156, 147, 147, 0.1);

				&--open {
					right: 0%;
					// opacity: 1;
				}

				.fund-head {
					background: #243256;
					border: 0.355462px solid rgba(101, 95, 95, 0.14);
					border-radius: 5.33763px 5.33763px 0px 0px;
					font-weight: 450;
					font-size: 14.88px;
					line-height: 19px;

					display: flex;
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 9.2px;
					text-align: center;
					padding: 10px;
					padding-right: 2.5rem;
					color: #ffffff;
					cursor: pointer;

					img {
						width: 22px;
					}
				}

				.fund-body {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: stretch;
					height: 100%;
					border: 0.8px solid rgba(196, 196, 196, 0.5);
					padding: 10px 18px;

					font-weight: 450;
					font-size: 13.02px;
					line-height: 16px;
					color: #655f5f;

					.fund-line {
						// width: calc(100% - 30px);
						display: flex;
						justify-content: space-between;
						// display: grid;
						// grid-template-columns: 3fr 1fr;
						// padding: 15px 10px;
						color: #655f5f;
						padding: 9px 0px;
						// padding-right: 21px;
						transition: all 0.5s;
						border-radius: 2px;

						&--active {
							cursor: pointer;
							&:hover {
								box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
							}
						}

						.img-container {
							display: flex;
							width: 60%;
							img {
								display: none;
							}
							.q-mark {
								position: relative;

								&:hover {
									.CustomTooltip {
										display: inline;
									}
								}
							}
						}

						& > p {
							min-width: 18%;
							// text-align: left;
						}
					}
				}
			}
		}
		.chatMobile {
			display: block;
			position: fixed;
			bottom: 4rem;
			left: 11.9vw;
			width: 65px;
			height: 65px;
			background-color: rgb(36, 50, 86);
			border-radius: 50%;

			display: grid;
			place-items: center;

			svg {
				color: #ffffff;
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.dashboard {
		padding: 2.815rem 1.5rem;
		padding-bottom: 93px;
		.dash-top {
			display: block;
			height: 122px;

			.slider-wrapper {
				max-width: 100%;
			}

			.slider {
				width: 100%;

				.slider-text-wrapper {
					.slider-text {
						padding: 21px 14px;

						h2 {
							margin-bottom: 10px;
							font-size: 14px;
							line-height: 18px;
						}
						p {
							max-width: 70%;
							font-weight: 300;
							font-size: 9px;
							line-height: 12px;
							margin-bottom: 14px;
						}
					}

					button {
						font-size: 10px;
						padding: 4px 8px;
					}
				}
			}
			.funding-new {
				display: none;
			}

			.funding {
				display: none;
			}
		}

		.middle-wrapper {
			padding: 1.875rem 0;
			.middle {
				display: grid;
				grid-template-columns: 1fr 1fr;
				// gap: 20px;
				.middle-card {
					// width: 80%;
					max-width: unset;
				}
			}
		}
		.fundMobile {
			display: block;
			position: fixed;
			bottom: 15px;
			right: 15px;
			&__table {
				right: -420%;

				&--open {
					right: 0%;
					// opacity: 1;
				}
			}
		}
		.chatMobile {
			bottom: 15px;
			left: 15px;
		}
	}
}

//mobile
@media screen and (max-width: 480px) {
	.dashboard {
		padding: 2.8125rem 1.5rem;
		padding-bottom: 93px;

		.on-hold-top {
		}

		.first {
			font-family: "Circular Std";
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 18px;
			margin: 0 0 6px 0;
		}

		.middle-wrapper {
			padding: 1.875rem 0;
			.middle {
				display: grid;
				grid-template-columns: 1fr 1fr;
				// gap: 20px;
				.middle-card {
					// width: 80%;

					.left p,
					.right .count,
					.right .amount {
						font-size: 13.59px;
						line-height: 17px;
					}
				}
			}
		}
		.table-mobile {
			display: block;
		}
		.table-desktop {
			display: none;
		}
	}
}
