@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(1440deg);
	}
}

.title {
	font-weight: 450;
	font-size: 14px;
	line-height: 25px;
	color: #252627;
}

.desc {
	font-weight: 450;
	font-size: 16.21px;
	line-height: 21px;
	color: #655f5f;
}

.ShareModal {
	padding: 50px;
	background-color: #ffffff;
	border-radius: 8px;
	width: 460px;
	font-size: 13.59px;
	line-height: 17px;
	> div {
		background: rgba(196, 196, 196, 0.2);
		border-radius: 8px;
		padding: 2rem;
		font-weight: 450;

		.title {
			font-weight: 500;
			margin-bottom: 0.5rem;
			text-transform: uppercase;
		}

		.flightTitle {
			font-weight: 700;
			margin-bottom: 0.5rem;
			font-size: 1rem;
		}

		.trips {
			margin-top: 1.5rem;
		}
		.flight-batch {
			margin-bottom: 1rem;
		}

		.text-row {
			display: flex;
			align-items: center;
			// gap: 2rem;
			color: #252627;
			margin-bottom: 0.5rem;

			> :first-child {
				color: #655f5f;
			}
		}
	}
	.copy-text {
		text-align: center;
		margin-top: 15px;
		background-color: transparent;
		display: flex;
		padding: 0;

		button {
			width: max-content;
			color: #40798c;
			background: transparent;
			cursor: pointer;
			border-radius: 4px;
			border: 0;
			margin: 0 auto;
			padding: 9px 12px;
			font-weight: 500;
			font-size: 13.59px;
			line-height: 17px;
		}

		&--copied {
			button {
				color: #ffffff;
				background: #21d047;
			}
		}
	}
}

.FareBreakdownModal {
	background-color: #ffffff;
	border-radius: 8px;
	width: 300px;
	font-size: 13.59px;
	line-height: 17px;

	border-radius: 3px;
	font-weight: 450;

	& > div {
		padding: 0 34px;
	}

	& > :last-child {
		background: rgba(196, 196, 196, 0.2);
	}

	.body {
		padding-top: 20px;
	}

	.total {
		padding: 20px 34px;
		display: grid;
		grid-template-columns: 100px 1fr;
		gap: 2rem;
	}

	.text-row {
		display: grid;
		grid-template-columns: 100px 1fr;
		gap: 2rem;
		color: #252627;
		margin-bottom: 20px;

		> :first-child {
			color: #655f5f;
		}
	}
	.copy-text {
		text-align: center;
		margin-top: 15px;
		color: #40798c;
		cursor: pointer;
	}
}

.PaymentMethodModal {
	background-color: #ffffff;
	border-radius: 8px;
	width: 80vw;
	max-width: 605px;
	overflow: hidden;

	&__head {
		padding: 20px 56px;
		background: #f5f5f5;
		border-bottom: 0.339149px solid rgba(0, 0, 0, 0.25);

		p {
			font-weight: 500;
			font-size: 20.31px;
			line-height: 26px;
			color: #252627;
		}
	}
	&__body {
		padding: 20px 56px;
		.radio-option {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			font-weight: 450;
			font-size: 13.59px;
			line-height: 17px;
			color: #9c9393;
			// max-width: 340px;
			margin-bottom: 22px;

			& > div {
				cursor: pointer;
			}

			.title {
				margin-top: 0.5rem;
				color: #252627;
			}
		}

		.btn_wrapper {
			display: flex;
			margin-top: 58px;

			button {
				margin-left: auto;
			}
		}
	}
}

.PassengerDetailsModal {
	background-color: #ffffff;
	border-radius: 3px;
	width: 80vw;
	max-width: 605px;
	overflow: hidden;

	&__head {
		padding: 20px 56px;
		background: #f5f5f5;
		border-bottom: 0.339149px solid rgba(0, 0, 0, 0.25);

		p {
			font-weight: 500;
			font-size: 20.31px;
			line-height: 26px;
			color: #252627;
		}
	}

	&__body {
		padding: 20px 56px;

		.form-section {
			width: 100%;
			.form-row {
				width: 100%;
				display: flex;
				justify-content: space-between;
				margin-bottom: 1rem;

				.conjoined {
					width: 45%;
					display: grid;
					grid-template-columns: 1.4fr 2fr;

					.form-item {
						width: 100%;
					}
				}

				.form-item {
					width: 45%;
					> p {
						font-size: 14px;
						line-height: 18px;
						color: #655f5f;
						padding: 5px 0;
					}
					input,
					select {
						width: 100%;
						background: #ffffff;
						border: 1px solid rgba(101, 95, 95, 0.2);
						border-radius: 2.56819px;
						font-size: 12.8409px;
						line-height: 16px;
						color: rgba(37, 38, 39, 0.72);
						padding: 5px 0;
						padding-left: 10px;
					}
					select {
						border: 0;
						outline: none;
					}
					.select-wrapper {
						width: 100%;
						border: 1px solid rgba(101, 95, 95, 0.2);
						padding-right: 10px;
					}

					.CustomDropdown__box {
						padding: 5px;
						padding-left: 10px;
						font-size: 12.8409px;
						line-height: 16px;
					}
				}
			}
			.button-wrapper-container {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: 2rem;
				p {
					font-weight: 450;
					font-size: 12.8409px;
					line-height: 16px;
					text-align: center;
					cursor: pointer;
					color: #40798c;
				}
				button {
					font-weight: 450;
					font-size: 13.125px;
					line-height: 17px;
				}
			}
		}
	}
}

.FlightInfoModal {
	background: #f5f5f5;
	color: #655f5f;
	border: 0.8px solid rgba(196, 196, 196, 0.5);
	border-radius: 0px;
	min-height: 233px;
	max-height: 800px;
	font-size: 12px;
	line-height: 15px;
	overflow: hidden;
	width: 73vw;
	// transition: all 0.5s;

	padding: 32px 76px;
	border: 0.839px solid rgba(196, 196, 196, 0.5);
	border-radius: 8px;

	.Departure_nightDay {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 1rem;
	}

	.singleFlight {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		// margin-bottom: 72px;
		margin-bottom: 15px;

		.logo {
			margin-right: 36px;
			margin-top: 1rem;
			width: 60px;
		}

		&__imgData {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
		}

		&__left-grid {
			display: grid;
			grid-template-columns: 30px 52px 1fr;
			grid-gap: 19px;

			> :nth-child(3) {
				margin-left: 0.5rem;
			}
		}
		.big-black {
			font-size: 13.59px;
			line-height: 17px;

			color: #252627;
		}

		.onePlane {
			> :first-child {
				> * {
					margin-bottom: 19px;
				}
			}
		}

		.planeInfo {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 22px;
		}
		.extras {
			margin-left: 128px;
			> :first-child {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 24px;
				margin-bottom: 18px;
			}
			svg {
				cursor: pointer;
				margin-right: 22px;
			}
		}
		.two-grid {
			display: grid;
			grid-template-columns: 16px 1fr;
			grid-gap: 24px;
			span {
				color: #9c9393;
			}
		}
		.layover-banner {
			background: rgba(112, 169, 161, 0.52);
			border-radius: 3px;
			font-size: 13.59px;
			line-height: 17px;
			color: #000000;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			gap: 1rem;
			padding: 6px 30px;
			padding-left: 60px;
			margin-top: 22px;

			div {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 1.5rem;
			}
		}
	}

	.bottom {
		margin-left: 97px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		background: none;
		padding: unset;
		.fare {
			font-weight: 450;
			font-size: 12px;
			line-height: 15px;
			color: #40798c;
			text-decoration: none;
			cursor: pointer;
		}

		&__left-grid {
			display: grid;
			grid-template-columns: 30px 1fr;
			grid-gap: 19px;
			text-align: left;

			> :nth-child(3) {
				margin-left: 0.5rem;
			}
		}
	}
}

.FullItenaryModal {
	background: #f5f5f5;
	color: #655f5f;
	border: 0.8px solid rgba(196, 196, 196, 0.5);
	border-radius: 0px;
	min-height: 233px;
	max-height: 800px;
	font-size: 12px;
	line-height: 15px;
	overflow: hidden;
	width: 73vw;
	// transition: all 0.5s;

	padding: 32px 76px;
	border: 0.839px solid rgba(196, 196, 196, 0.5);
	border-radius: 8px;

	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 1.25rem;

	.FlightInfoModal {
		padding: 0;
		min-height: unset;
		max-height: unset;
		width: unset;
		border-radius: 0;
		border: 0;
	}
}

.SuccessfulModal {
	width: 348px;
	height: 219px;
	background: #ffffff;
	border: 0.8px solid rgba(196, 196, 196, 0.5);
	box-shadow: 0px 4px 4px rgba(156, 147, 147, 0.1);
	border-radius: 8px;
	outline: 0;
	padding: 52px 12.5px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;

	svg {
		color: #21d047;
	}

	.spinner {
		-webkit-animation: spin 0.8s linear infinite; /* Safari */
		animation: spin 0.8s linear infinite;
	}

	p {
		text-align: center;
		color: #252627;
		font-weight: 450;
		font-size: 16px;
		line-height: 20px;
	}
}

.HeadsUpModal {
	background: #ffffff;
	mix-blend-mode: normal;
	border-radius: 8px;
	width: 420.44px;
	padding: 24px;
	text-align: center;

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;

	.title {
		font-weight: 500;
		font-size: 20px;
		line-height: 25px;
		color: #252627;
	}

	.desc {
		font-weight: 450;
		font-size: 16.21px;
		line-height: 21px;
		color: #655f5f;
	}

	.buttons {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 48px;
		.button {
			font-weight: 450;
			font-size: 16.21px;
			line-height: 21px;
			padding: 0.25rem 1rem;
			border-radius: 3.6px;
			border: 0;
			background: #243256;
			color: #ffffff;
			cursor: pointer;
			width: 96px;
			border: 0.355462px solid rgba(101, 95, 95, 0.14);

			&--white {
				background: #ffffff;
				color: #243256;
				border: 0.8px solid rgba(196, 196, 196, 0.5);
			}
		}
	}

	&--delete {
		.buttons {
			.button {
				background: #e74c3c;
				&--white {
					background: #ffffff;
					color: #243256;
					border: 0.8px solid rgba(196, 196, 196, 0.5);
				}
			}
		}
	}
}

.ConvertPointsModal {
	background: #ffffff;
	mix-blend-mode: normal;
	border-radius: 8px;
	width: 420.44px;
	height: 265px;
	padding: 38px;
	padding-bottom: 51px;
	text-align: center;

	.title {
		margin-bottom: 21px;
		font-weight: 500;
		font-size: 20px;
		line-height: 25px;
	}

	.desc {
		font-size: 14.01px;
		line-height: 18px;
		margin-bottom: 35px;
	}

	button {
		width: 142px;
		height: 41px;
		font-size: 15px;
		line-height: 19px;
	}
}

.CreditFacilityModal {
	background: #ffffff;
	mix-blend-mode: normal;
	border-radius: 8px;
	width: 80vw;
	max-width: 532px;

	overflow: hidden;

	&__head {
		padding: 22px 27px;
		background: #f5f5f5;
		border-bottom: 0.339149px solid rgba(0, 0, 0, 0.25);

		p {
			font-weight: 500;
			font-size: 20.31px;
			line-height: 26px;
			color: #252627;
		}
	}
	&__body {
		padding: 25px 60px;
		border: 0.8px solid rgba(196, 196, 196, 0.5);
		background-color: #ffffff;

		.form-section {
			display: flex;
			flex-direction: column;
			font-family: "Circular Std";
			width: 100%;
			form {
				width: 100%;
				.form-row {
					display: grid;
					grid-template-columns: 1fr 1fr;
					grid-column-gap: 40px;
					padding: 10px 0;
					width: 100%;
				}
				.form-item {
					width: 100%;
					margin-bottom: 28px;
					h5 {
						margin-bottom: 11px;
						color: #655f5f;
						font-size: 15px;
						line-height: 19px;
						font-weight: 100;
						font-family: "Circular Std";
						font-style: normal;
						font-weight: 450;
						color: #252627;
					}
					.parent-label {
						.custom-wrapper {
							display: flex;
							justify-content: flex-end;
							width: 70%;
							.custom {
								display: flex;
								visibility: hidden;
								font-style: normal;
								font-size: 70%;
								align-items: center;
								background: rgba(37, 38, 39, 0.7);
								justify-content: center;
								padding: 5px 10px;
								text-align: center;
								color: #fff;
								border-radius: 4px;
							}
						}
						.info-wrapper {
							display: flex;
							align-items: center;

							h5 {
								padding: 2% 0;
								font-family: "Circular Std";
								font-style: normal;
								font-weight: 450;
								font-size: 15px;
								line-height: 19px;
								color: #252627;
							}
							.infosvg {
								width: 16px;
								padding-left: 10px;
								align-self: center;
							}
						}
						&:hover {
							.custom-wrapper {
								.custom {
									visibility: visible;
								}
							}
						}
					}
					input,
					select {
						background: #f9fafa;
						border: 0.8px solid rgba(196, 196, 196, 0.5);
						border-radius: 4px;
						padding: 11px;
						padding-left: 20px;
						width: 100%;
						// height: 40%;

						color: #252627;
						box-sizing: border-box;
					}
					.select-wrapper {
						padding: 11px 20px;
						padding-right: 10px;
						border: 0.8px solid rgba(196, 196, 196, 0.5);
						background: #f9fafa;
						border-radius: 4px;
					}
					select {
						border: 0;
						padding: 0px;
					}
					.prompt {
						color: red;
						font-size: 70%;
						padding: 5px 0;
					}
				}

				& > .form-item:last-of-type {
					margin-bottom: 0;
				}

				& .button-wrapper {
					width: 100%;
					.previous-wrapper {
						display: flex;
						align-items: center;
						display: flex;
						padding: 12px 0;
						&:hover {
							cursor: pointer;
						}
						p {
							padding-left: 7.5px;
							color: #655f5f;
						}
					}
					display: flex;
					padding: 2.5% 0;
					// padding-bottom: 5%;
					justify-content: flex-end;
					grid-gap: 5%;
					align-items: center;
					p {
						font-size: 80%;
					}
					img {
						width: 15px;
						color: pink;
					}
					.disabled {
						background: #9c9393;
						cursor: not-allowed;
					}
				}

				.credit-button-wrapper {
					width: 100%;
					display: grid;
					// padding-top: 10px;
					grid-template-columns: 1fr;
					margin-top: 22px;
					justify-content: flex-end;
					align-items: center;

					button {
						font-weight: 450;
						font-size: 13.59px;
						line-height: 17px;
						padding: 10.5px;
					}

					& > button:first-of-type {
						background: #243256;
						color: #ffffff;
						font-weight: 500;
						font-size: 15px;
						line-height: 19px;
					}
					.disabled {
						background: #9c9393;
						cursor: not-allowed;
					}
				}
			}
		}
	}
}

.FundAccountModal {
	padding: 47.5px;
	background: #ffffff;
	border-radius: 8px;
	width: 348px;

	.modal_head_text {
		color: #252627;
		font-weight: 450;
		font-size: 15px;
		line-height: 19px;
		display: flex;
		align-items: center;
		margin-bottom: 22px;
	}
}

.SaveCardModal {
	padding: 37px 5.14vw;
	padding-bottom: 45px;
	background: #ffffff;
	border-radius: 8px;
	width: 527px;

	.title {
		font-weight: 500;
		font-size: 20px;
		line-height: 25px;
		color: #252627;
		text-align: center;
		margin-bottom: 35px;
	}

	.modal_head_text {
		color: #252627;
		font-weight: 450;
		font-size: 15px;
		line-height: 19px;
		display: flex;
		align-items: center;
		margin-top: 10px;
		margin-bottom: 62px;
		text-align: center;
	}

	label {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 4px;
		font-weight: 450;
		font-size: 14px;
		line-height: 18px;
		color: #252627;
		margin-bottom: 28px;
		input {
			margin-bottom: 0;
		}

		input#search {
			// background-image: url(bg.jpg);
			background-repeat: no-repeat;
			text-indent: 20px;
		}

		input#search:focus {
			background-image: none;
		}
	}

	button {
		width: 100%;
		margin-top: 6px;
	}
}

.PreferredPageModal {
	background: #ffffff;
	text-align: center;
	width: 350px;
	padding: 46px 78px;
	border-radius: 0.5rem;

	p {
		font-size: 11px;
		line-height: 14px;
		color: #9c9393;
		margin-bottom: 0.5rem;
	}

	input {
		width: 100%;
		background: #ffffff;
		border: 1px solid rgba(101, 95, 95, 0.2);
		border-radius: 2.56819px;
		font-size: 16px;
		line-height: 20px;
		color: rgba(37, 38, 39, 0.72);
		padding: 11px 0;
		padding-left: 10px;
		text-align: left;
		margin-bottom: 22px;
	}

	button {
		font-weight: 450;
		font-size: 13.59px;
		line-height: 17px;
		padding: 8px;
		border-radius: 6px;
	}
}

.ShareTripDetailsModal {
	background: #ffffff;
	// text-align: center;
	width: 90vw;
	max-width: 508px;
	border-radius: 0.5rem;
	overflow: hidden;

	&__head {
		padding: 20px 56px;
		background: #f5f5f5;
		border-bottom: 0.339149px solid rgba(0, 0, 0, 0.25);

		p {
			font-weight: 500;
			font-size: 18.418px;
			line-height: 22px;
			color: #252627;
			margin-bottom: 0;
		}
	}

	&__body {
		padding: 38px 56px;
		padding-bottom: 2rem;
		p {
			font-weight: 450;
			font-size: 14px;
			line-height: 18px;
			color: #252627;
			margin-bottom: 0.5rem;
		}

		input[type="email"] {
			width: 100%;
			background: #ffffff;
			border: 1px solid rgba(101, 95, 95, 0.2);
			border-radius: 2.56819px;
			color: rgba(37, 38, 39, 0.72);
			padding: 8px 15px;
			padding-bottom: 6px;
			text-align: left;
			margin-bottom: 20px;
			font-weight: 450;
			font-size: 12.7903px;
			line-height: 16px;
		}

		label {
			display: flex;
			align-items: center;
			gap: 13px;
			font-weight: 450;
			font-size: 14px;
			line-height: 18px;
			color: #9c9393;
			margin-bottom: 30px;
		}

		input[type="checkbox"] {
			accent-color: #252627;
		}

		.btn_wrapper {
			display: flex;
			button {
				font-weight: 450;
				font-size: 12.8409px;
				line-height: 16px;
				padding: 10px 18px;
				border-radius: 6px;
				margin-left: auto;
				min-width: 66px;
			}
		}
	}
}

.AddNewTravellerModal {
	// position: absolute;
	// top: 50%;
	// left: 50%;
	// transform: translate(-50%, -50%);
	width: 494px;

	&-mt {
		// padding-top: 80px;
	}
	.top {
		font-weight: 500;
		padding: 19.5px 2rem;
		background: #f5f5f5;
		border-radius: 8px 8px 0px 0px;
		h3 {
			font-weight: 500;
			font-size: 20.31px;
			line-height: 26px;

			color: #000000;
		}
	}
	.bottom {
		background: #fff;
		display: flex;
		flex-direction: column;
		// align-items: center;
		justify-content: space-between;
		padding: 29px 2rem;
		border-radius: 0 0 8px 8px;
		overflow: hidden;

		.id-info {
			margin-bottom: 44px;

			.id-text {
				background: rgba(112, 169, 161, 0.26);
				border-radius: 2.24551px;
				padding: 5px 0px;
				padding-left: 10px;
				border-left: 2.6px solid #40798c;
				font-weight: 450;
				font-size: 13.59px;
				line-height: 17px;
				color: #776d6d;
				width: 100%;
			}
		}
		.form-section {
			width: 100%;

			.form-row {
				width: 100%;
				display: flex;
				justify-content: space-between;
				margin-bottom: 29px;

				.conjoined {
					width: 45%;
					display: grid;
					grid-template-columns: 1fr 2fr;

					.form-item {
						width: 100%;
					}
				}

				.form-item {
					width: 45%;
					> p {
						font-size: 14px;
						line-height: 18px;
						color: #655f5f;
						padding: 5px 0;
					}
					input,
					select {
						width: 100%;
						background: #ffffff;
						border: 1px solid rgba(101, 95, 95, 0.2);
						border-radius: 2.56819px;
						font-size: 12.8409px;
						line-height: 16px;
						color: rgba(37, 38, 39, 0.72);
						padding: 5px 0;
						padding-left: 10px;
					}
					select {
						border: 0;
						outline: none;
					}
					.select-wrapper {
						width: 100%;
						border: 1px solid rgba(101, 95, 95, 0.2);
						padding-right: 10px;
					}
				}
			}
			.button-wrapper-container {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				p {
					font-weight: 450;
					font-size: 12.8409px;
					line-height: 16px;
					text-align: center;
					cursor: pointer;
					color: #40798c;
				}
				button {
					font-weight: 450;
					font-size: 13.125px;
					line-height: 17px;
				}
				.save-profile {
					background-color: transparent;
					color: #40798c;
					svg {
						color: #40798c;
					}
				}
			}
		}

		.CustomDropdown__box {
			padding: 5px 10px;
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;

			font-weight: 450;
			font-size: 12.8409px;
			line-height: 16px;
			color: rgba(37, 38, 39, 0.72);
			min-width: 72px;
		}

		.CustomDropdown__drop--open {
			max-height: 150px;
			overflow-y: scroll;
		}
	}
}

.AddNewTravellerModalRewrite {
	width: 556px;
	margin-bottom: 1rem;

	.top {
		font-weight: 500;
		padding: 30px;
		padding-bottom: 15px;
		background: #ffffff;
		border-radius: 15px 15px 0px 0px;

		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 10px;

		h3 {
			font-weight: 700;
			font-size: 20px;
			line-height: 25px;

			color: #655f5f;
		}

		.closeIcon {
			margin-left: auto;
			cursor: pointer;
		}
	}
	.bottom {
		background: #fff;
		display: flex;
		flex-direction: column;
		// align-items: center;
		justify-content: space-between;
		padding: 30px;
		padding-bottom: 50px;
		padding-top: 0;
		border-radius: 0 0 15px 15px;
		overflow: hidden;

		.switchPassenger {
			position: relative;

			&__search {
				padding: 10px;
				padding-left: 1rem;
				background: #f9fafa;
				border-radius: 100px;

				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 1.5rem;

				margin-bottom: 35px;

				input[type="search"] {
					width: 100%;
					border: 0;
					font-weight: 450;
					font-size: 14.01px;
					line-height: 18px;
					background-color: transparent;

					&::placeholder {
						color: #9c9393;
					}
				}
			}
		}

		.id-info {
			margin-bottom: 30px;
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 10px;
			background: #fff7e5;
			border-radius: 5px;
			padding: 8px 10px;
			max-width: max-content;

			.id-text {
				font-weight: 450;
				font-size: 12px;
				line-height: 15px;
				color: #655f5f;
			}
		}
		.form-section {
			width: 100%;

			.form-row {
				width: 100%;
				display: flex;
				justify-content: space-between;
				margin-bottom: 30px;

				.conjoined {
					width: 45%;
					display: grid;
					grid-template-columns: 1fr 2fr;

					.form-item {
						width: 100%;

						.CustomDropdown {
							&__box {
								border-top-right-radius: 0px;
								border-bottom-right-radius: 0px;
								border-right: 0;

								input {
									border-top-right-radius: 0px;
									border-bottom-right-radius: 0px;
									background-color: #f9fafa;
								}
								svg {
									background-color: #f9fafa;
								}
							}
						}
					}
				}

				.form-item {
					width: 45%;

					.CustomDropdown {
						&__drop {
							border-radius: 0 0 5px 5px;
							.drop-item {
								padding: 10px;
								font-weight: 450;
								font-size: 14px;
								line-height: 18px;
								color: #655f5f;

								&--selected {
									background-color: rgba(84, 170, 228, 0.1);
								}
							}
						}
					}

					> p {
						font-weight: 500;
						font-size: 14px;
						line-height: 18px;
						color: #655f5f;
						padding: 5px 0;

						display: flex;
						flex-direction: row;
						min-width: max-content;
						width: 100%;
						gap: 4px;

						.required {
							color: #db1919;
							font-weight: 500;
							font-size: 14px;
							line-height: 18px;
						}
						.optional {
							font-weight: 450;
							font-size: 14px;
							line-height: 18px;
							color: #c4c4c4;
							margin-left: auto;
						}
					}
					input,
					select {
						width: 100%;
						background: #ffffff;
						border: 0.8px solid rgba(196, 196, 196, 0.5);
						border-radius: 5px;
						padding: 10px;

						font-weight: 450;
						font-size: 14px;
						line-height: 18px;
						color: #655f5f;
					}
					select {
						border: 0;
						outline: none;
					}
					.select-wrapper {
						width: 100%;
						border: 1px solid rgba(101, 95, 95, 0.2);
						padding-right: 10px;
					}
				}
			}
			.button-wrapper-container {
				width: 100%;
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 27px;
				margin-top: 58px;

				&--edit {
					grid-template-columns: unset;
				}

				p {
					font-weight: 450;
					font-size: 12.8409px;
					line-height: 16px;
					text-align: center;
					cursor: pointer;
					color: #40798c;
				}
				button {
					font-weight: 500;
					font-size: 14px;
					line-height: 18px;
					width: unset;
					padding: 15px 1.5rem;
					border-radius: 5px;
				}
				.save-profile {
					background-color: transparent;
					color: #655f5f;
					border: 0.8px solid rgba(196, 196, 196, 0.5);
					svg {
						color: #40798c;
					}
				}
			}
		}
	}
	.CustomDropdown__box {
		padding: 10px;
		// border-top-right-radius: 0px;
		// border-bottom-right-radius: 0px;

		font-weight: 450;
		font-size: 14px;
		line-height: 18px;
		color: rgba(37, 38, 39, 0.72);
		min-width: 72px;
		height: 39.333px;

		input {
			color: #655f5f !important;
			padding-left: 10px !important;
		}

		& > div > svg {
			color: #c4c4c4;
		}
	}

	.CustomDropdown__drop--open {
		max-height: 150px;
		overflow-y: scroll;
	}
}

.SelectTravellerModal {
	background: #ffffff;
	border-radius: 8px;
	width: 100%;
	width: 547px;
	max-height: 85vh;
	// overflow: scroll;

	display: flex;
	flex-direction: column;
	align-items: stretch;

	&__top {
		.titleDesc {
			border: 0.428031px solid rgba(156, 147, 147, 0.46);
			padding: 19px 8.8%;

			h3 {
				font-weight: 500;
				font-size: 20.3144px;
				line-height: 26px;

				color: #252627;
			}
			p {
				font-weight: 450;
				font-size: 13.59px;
				line-height: 17px;
				color: #655f5f;
			}
		}
		.goBack {
			display: none;
		}
	}

	&__search {
		margin: 12px auto;
		margin-bottom: 17px;
		background: rgba(240, 229, 229, 0.41);

		& > input[type="text"] {
			background: rgba(240, 229, 229, 0.41);
			border-radius: 2.56819px;
			padding: 5px 40px;
			padding-left: 39px;
			padding-right: 41px;
			font-style: normal;
			font-weight: 450;
			font-size: 12px;
			line-height: 15px;
			text-align: center;
			border: 0;
			color: #000000;
		}

		svg {
			display: none;
		}
	}

	&__btnWrap {
		// background-color: #ffffff;
		margin: 0 auto;
		margin-bottom: 14px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 0.55rem;

		button {
			font-weight: 450;
			font-size: 15px;
			line-height: 19px;
			padding: 5px 17.5px;
		}
		.addNew {
			border: 0;
			background: transparent;
			font-weight: 450;
			font-size: 12.17px;
			line-height: 15px;
			text-align: center;
			padding: 8px 0;
			color: #40798c;
			cursor: pointer;
		}
	}

	&__body {
		padding: 0 8.8%;
		padding-bottom: 40px;

		.EmptyMessage {
			text-align: center;
			padding: 2rem 1rem;
		}

		.passenger-item-wrapper {
			width: 100%;
			height: calc(85vh - 250px);
			overflow-y: auto;

			div.passenger-item:last-child {
				.passenger-info {
					border-bottom: 1px solid #ededed;
				}
			}
		}
	}
}

.AppendTravellerModal {
	background: #ffffff;
	border-radius: 8px;
	width: 100%;
	width: 547px;
	max-height: 85vh;
	// overflow: scroll;

	display: flex;
	flex-direction: column;
	align-items: stretch;

	padding: 0 8.8%;
	padding-bottom: 0px;
	padding-bottom: 40px;
}

.passengerItem {
	display: flex;
	align-items: center;
	display: flex;
	width: 100%;

	// justify-content: space-between;

	&--disabled {
		h5 {
			color: #9c9393 !important;
		}
		p {
			color: #9c9393 !important;
		}
	}

	.checkbox {
		background: #ffffff;
		border: 0.5px solid #655f5f;
		border-radius: 50%;
		min-width: 28px;
		width: 28px;
		height: 28px;
		display: flex;
		justify-content: center;
		align-self: center;
		margin-right: 25px;
		img {
			width: 50%;
		}
	}
	.passenger-info {
		display: flex;
		width: 100%;
		max-width: 373px;
		align-items: center;
		padding: 7.5px 5%;
		padding-bottom: 10px;
		border: 1px solid #ededed;
		border-bottom: 0px;
		cursor: pointer;
		overflow-x: hidden;

		.passenger-icon {
			background: #c4c4c4;
			border-radius: 50%;
			color: #655f5f;
			height: 28px;
			width: 28px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 12.17px;
			line-height: 15px;
			color: #9c9393;
			min-width: 28px;
		}
		.passenger-details {
			padding-left: 5px;
			h5 {
				font-weight: 450;
				font-size: 12.17px;
				line-height: 15px;
				color: #655f5f;
			}
			p {
				font-weight: 450;
				font-size: 12.17px;
				line-height: 15px;

				color: #9c9393;
			}
		}
	}
	.mob {
		display: none;
	}

	&--selected {
		border-radius: 2.56819px;
		.checkbox {
			background: #70a9a1;
			border: 0.5px solid #70a9a1;
		}
		.passenger-info {
			background: #e5e5e5;
			border-radius: 2.56819px;

			.passenger-icon {
				color: #252627;
				// min-width: max-content;
			}
			.passenger-details {
				h5 {
					color: #252627;
				}
			}
		}
	}
}

.modal-box {
	padding: 47.5px;
	background: #ffffff;
	border-radius: 8px;
	max-width: 348px;
}

.modal_head_text {
	color: #252627;
	font-weight: 450;
	font-size: 15px;
	line-height: 19px;
	display: flex;
	align-items: center;
	margin-bottom: 22px;
}

.modal-form {
	background: #ffffff;
	border: 0.8px solid rgba(196, 196, 196, 0.5);
	border-radius: 4px;
	color: #9c9393;
	width: 100%;
	padding: 10px 0px 10px 10px;
	margin-bottom: 15px;
	box-sizing: border-box;

	/* Chrome, Safari, Edge, Opera */
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	-moz-appearance: textfield;
}

.modal-button {
	background: #243256;
	border-radius: 5px;
	border: 0;
	padding: 10px 0px 10px 10px;
	gap: 10px;
	width: 100%;
	color: #ffffff;
	font-size: 14px;
	margin-bottom: 8px;
	cursor: pointer;
}

.payment-cancel {
	color: #655f5f;
	border: none;
	background-color: transparent;
	margin: auto;
}

.fund-wallet {
	background: #243256;
	border: 0.355462px solid rgba(101, 95, 95, 0.14);
	border-radius: 5.33763px 5.33763px 0px 0px;
	font-size: 16.21px;
	line-height: 21px;
	display: flex;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 9.2px;
	text-align: center;
	padding: 9px 0px 10px 10px;
	color: #ffffff;
	cursor: pointer;
}

.fund-wallet-text {
	font-size: 16.21px;
	line-height: 21px;
	/* identical to box height */

	display: flex;
	align-items: center;
	text-align: center;

	color: #ffffff;
}

.LoadingModal {
	width: 348px;
	height: 219px;
	background: #ffffff;
	border: 0.8px solid rgba(196, 196, 196, 0.5);
	box-shadow: 0px 4px 4px rgba(156, 147, 147, 0.1);
	border-radius: 8px;
	padding: 1rem;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 30px;

	svg {
		width: 45px;
		height: 45px;

		-webkit-animation: spin 1.2s cubic-bezier(0.5, 0.3, 0.9, 0.9) infinite; /* Safari */
		animation: spin 1.2s cubic-bezier(0.5, 0.3, 0.9, 0.9) infinite;
	}

	p {
		max-width: 221px;
		text-align: center;
		font-weight: 450;
		font-size: 15px;
		line-height: 19px;
		color: #252627;
	}
}

.LoadingSuccessModal {
	width: 348px;
	height: 219px;
	background: #ffffff;
	border: 0.8px solid rgba(196, 196, 196, 0.5);
	box-shadow: 0px 4px 4px rgba(156, 147, 147, 0.1);
	border-radius: 8px;
	padding: 1rem;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 30px;

	svg {
		width: 45px;
		height: 45px;
		color: #21d047;
	}
	.spinner {
		-webkit-animation: spin 0.8s cubic-bezier(0.5, 0.3, 0.9, 0.9) infinite; /* Safari */
		animation: spin 0.8s cubic-bezier(0.5, 0.3, 0.9, 0.9) infinite;
	}

	p {
		max-width: 221px;
		text-align: center;
		font-weight: 450;
		font-size: 15px;
		line-height: 19px;
		color: #252627;
	}
}

.SearchExpiredModal {
	background: #ffffff;
	mix-blend-mode: normal;
	border-radius: 8px;
	width: 420.44px;
	padding: 24px 0;
	text-align: center;

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;

	.title {
		font-weight: 500;
		font-size: 20px;
		line-height: 25px;
		color: #252627;
		padding-bottom: 0.5rem;
		width: 100%;
		border-bottom: 1px solid rgba(64, 121, 140, 0.5);
	}

	.desc {
		font-weight: 450;
		font-size: 16.21px;
		line-height: 21px;
		color: #655f5f;
		padding: 0 2rem;
	}

	.buttons {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 48px;
		margin-top: 1.5rem;
		.button {
			font-weight: 450;
			font-size: 16.21px;
			line-height: 21px;
			padding: 0.25rem 1rem;
			border-radius: 3.6px;
			border: 0;
			background: #243256;
			color: #ffffff;
			cursor: pointer;
			border: 0.355462px solid rgba(101, 95, 95, 0.14);

			&--white {
				background: #ffffff;
				color: #243256;
				border: 0.8px solid rgba(196, 196, 196, 0.5);
			}
		}
	}

	&--delete {
		.buttons {
			.button {
				background: #e74c3c;
				&--white {
					background: #ffffff;
					color: #243256;
					border: 0.8px solid rgba(196, 196, 196, 0.5);
				}
			}
		}
	}
}

.AdditionalNoteModal {
	background: #ffffff;
	border-radius: 4.69529px;
	padding: 36px;
	padding-bottom: 30px;

	width: 339px;
	height: 285.47px;

	textarea {
		width: 100%;
		margin-bottom: 1.25rem;
		border: 0;
		font-weight: 450;
		font-size: 13.1468px;
		line-height: 17px;
		resize: none;
		outline: 0;
	}

	&__btnWrap {
		display: flex;
	}

	button {
		font-weight: 450;
		font-size: 12.2078px;
		line-height: 15px;

		color: #40798c;
		padding: 4.8px 9.4px;
		margin-left: auto;
		cursor: pointer;
		background-color: transparent;
		border: 0.939058px dashed #40798c;
		border-radius: 5.63435px;
	}
}

.NewGroupModal {
	border-radius: 8px;
	background: #fff;
	padding: 35px 25px;
	width: 408px;
	max-width: 80vw;

	&__title {
		color: #252627;
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 22px; /* 122.222% */
		margin-bottom: 25px;
	}

	&__btnWrap {
		display: flex;
		button {
			color: #40798c;
			font-size: 13.59px;
			font-style: normal;
			font-weight: 450;
			line-height: normal;
			padding: 7.4px 40px;
			border: 0;
			border-radius: 3.85px;
			background: #f7f7f7;
			cursor: pointer;

			margin: 0 auto;
			margin-top: 25px;
		}
	}

	label {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		gap: 10px;

		color: #655f5f;
		font-size: 13.59px;
		font-style: normal;
		font-weight: 450;
		line-height: normal;

		input[type="text"] {
			font-size: 14px;
			font-style: normal;
			font-weight: 450;
			line-height: normal;
			color: #252627;
			padding: 10px;

			border-radius: 3.85px;
			border: 0.8px solid rgba(196, 196, 196, 0.5);

			&::placeholder {
				color: #c4c4c4;
			}
		}
	}
}

.ReferralModal {
	border-radius: 8px;
	background: #fff;
	padding: 35px 25px;
	width: 550px;
	max-width: 80vw;

	&__title {
		color: #252627;
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 22px; /* 122.222% */
		margin-bottom: 1rem;
	}

	&__desc {
		font-size: 14.01px;
		line-height: 18px;
		margin-bottom: 1rem;
		color: #655f5f;
	}

	.formish {
		border: 0.8px solid hsla(0, 0%, 77%, 0.5);
		display: grid;
		grid-template-columns: 1fr 82px;
		align-items: center;
		border-radius: 5px;
		overflow: hidden;

		font-size: 14px;

		p {
			background: #f9fafa;
			margin-left: 0.5rem;
		}

		.copyBtn {
			padding: 0.5rem;
			border: 0;
			background: #243256;
			color: #fff;
			cursor: pointer;

			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			gap: 0.5rem;

			&--copied {
				background: #169d53;
			}
		}
	}
}

@media screen and (max-width: 780px) {
	.CreditFacilityModal {
		border-radius: 4px;
		&__head {
			padding-top: 9px;
			padding-bottom: 5px;
			padding-left: 14px;
			p {
				font-weight: 500;
				font-size: 16px;
				line-height: 20px;
			}
		}

		&__body {
			padding: 15px 32px;
			padding-right: 42px;

			.form-section form {
				.form-item {
					margin-bottom: 8px;
					h5 {
						font-size: 12px;
						line-height: 15px;
						margin-bottom: 4px;
					}
					input,
					select {
						font-size: 10.5px;
						line-height: 13px;
						padding: 7px 14px;
					}
					.select-wrapper {
						padding: 0;
					}
				}
				.credit-button-wrapper {
					margin-top: 14px;
					& > button:first-of-type,
					button {
						padding: 7.5px;
						font-size: 12px;
						line-height: 15px;
					}
				}
			}
		}
	}

	.ShareModal {
		width: 90vw;
		// width: 100%;
		padding: 2rem;
	}

	.ShareTripDetailsModal {
		&__head {
			padding: 8px 22px;
			background: #f5f5f5;
			border-bottom: 0.339149px solid rgba(0, 0, 0, 0.25);

			p {
				font-weight: 500;
				font-size: 14px;
				line-height: 18px;
			}
		}

		&__body {
			padding: 23px 29px;
			padding-left: 40px;
			padding-bottom: 22px;
			p {
				font-weight: 450;
				font-size: 14px;
				line-height: 18px;
				color: #252627;
				margin-bottom: 7px;
			}

			input[type="email"] {
				width: 100%;
				background: #ffffff;
				border: 1px solid rgba(101, 95, 95, 0.2);
				border-radius: 2.56819px;
				color: rgba(37, 38, 39, 0.72);
				padding: 8px 15px;
				padding-bottom: 6px;
				text-align: left;
				margin-bottom: 16px;
				font-weight: 450;
				font-size: 12.7903px;
				line-height: 16px;
			}

			label {
				display: flex;
				align-items: center;
				gap: 4px;
				font-weight: 450;
				font-size: 10px;
				line-height: 13px;
				color: #655f5f;
				margin-bottom: 14px;
			}

			input[type="checkbox"] {
				accent-color: #252627;
			}

			.btn_wrapper {
				display: flex;
				button {
					font-weight: 450;
					font-size: 13px;
					line-height: 16px;
					padding: 6px 18px;
					border-radius: 6px;
					margin-left: auto;
					min-width: 66px;
				}
			}
		}
	}

	.AddNewTravellerModal {
		// width: 100%;
		// top: 0;
		// position: absolute;
		width: 90vw;
		// height: 95vh;
		.bottom {
			align-items: center;
			padding: 17px 1rem;
			.form-section {
				box-sizing: border-box;
				.form-row {
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: stretch;
					gap: 1rem;
					padding-bottom: 5px;
					margin-bottom: 1rem;

					.conjoined {
						width: 100%;
						grid-template-columns: 5rem 1fr;
					}
					.form-item {
						width: 100%;
					}
				}
				.button-wrapper-container {
					width: 100%;
					display: flex;
					justify-content: space-between;
					flex-direction: row-reverse;
					align-items: center;
					padding: 5% 0;
					padding-bottom: 0;
					button {
						font-weight: 450;
						font-size: 13px;
						line-height: 16px;
						padding: 11.5px 16.5px;
					}
				}
			}
			.id-info {
				margin-bottom: 40px;
			}
		}
	}

	.AddNewTravellerModalRewrite {
		// width: 100%;
		// top: 0;
		// position: absolute;
		width: 100vw;
		max-height: 95vh;
		// height: 95vh;
		.bottom {
			align-items: center;
			padding: 17px 1rem;

			.id-info {
				width: 100%;
			}

			.form-section {
				box-sizing: border-box;

				.form-row {
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: stretch;
					gap: 1rem;
					padding-bottom: 5px;
					margin-bottom: 1rem;

					.conjoined {
						width: 100%;
						grid-template-columns: 5rem 1fr;
					}
					.form-item {
						width: 100%;
					}
				}
				.button-wrapper-container {
					width: 100%;
					display: flex;
					flex-direction: column-reverse;
					align-items: center;
					gap: 5px;

					margin-top: 40px;

					button {
						width: 80%;
						padding: 10px;
					}

					.save-profile {
						border: 0;
					}
				}
			}
			.id-info {
				margin-bottom: 40px;
			}
		}
	}

	.SelectTravellerModal {
		width: 100%;

		&__top {
			.titleDesc {
				display: none;
			}
			.goBack {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
				width: 100%;
				gap: 0.8rem;
				cursor: pointer;
				padding: 2rem 1rem;
				padding-bottom: 0.25rem;
			}
		}

		&__search {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 1rem;
			background: rgba(240, 229, 229, 0.41);
			border: 0.0312081px solid #ffffff;
			border-radius: 25px;
			padding: 10px 16px;
			width: 92%;

			// margin-left: 2rem;

			& > input[type="text"] {
				padding: 0;
				text-align: left;
				background-color: transparent;
			}

			svg {
				display: inline;
			}
		}

		&__btnWrap {
			padding: 15px 7.5%;
			padding-top: 0;
			display: flex;
			flex-direction: row !important;
			box-sizing: border-box;
			width: 100%;
			justify-content: space-between;
			height: unset;

			background-color: #ffffff;

			button {
				font-weight: 450;
				font-size: 13px;
				line-height: 16px;
				padding: 11.5px 16.5px;
			}
		}

		&__body {
			.passenger-item-wrapper {
				padding: 0.5rem 0;
				max-height: calc(93vh - 69px - 67px);
				overflow-y: auto;
				.passenger-item {
					.checkbox {
						display: none;
					}

					.passenger-info {
						width: 100%;
						display: none;
						max-width: unset;
					}
					.mob {
						display: flex;
						padding: 9px 21px;
						overflow-x: hidden;

						.passenger-icon {
							width: 36.7px;
							height: 36.7px;
							font-size: 15.9507px;
							line-height: 20px;
							min-width: 36.7px;
						}
						.passenger-details {
							padding-left: 7px;

							h5 {
								font-weight: 450;
								font-size: 13px;
								line-height: 16px;
							}

							p {
								font-weight: 450;
								font-size: 14px;
								line-height: 18px;
							}
						}

						& > svg {
							margin-left: auto;
							color: #40798c;
							display: none;
						}
					}
					&.selected {
						& > .mob > svg {
							display: inline;
						}
					}
				}
			}
		}
	}

	.PaymentMethodModal {
		background-color: #ffffff;
		border-radius: 8px;
		width: 90vw;
		max-width: 605px;
		overflow: hidden;

		&__head {
			padding: 16px 22px;
			background: #f5f5f5;
			border-bottom: 0.339149px solid rgba(0, 0, 0, 0.25);

			p {
				font-weight: 500;
				font-size: 14px;
				line-height: 18px;
				color: #252627;
			}
		}
		&__body {
			padding: 15px 24px;
			.radio-option {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				font-weight: 450;
				font-size: 13px;
				line-height: 16px;
				color: #9c9393;
				// max-width: 340px;
				margin-bottom: 21px;

				.title {
					margin-top: 0.5rem;
					color: #252627;
				}
			}

			.btn_wrapper {
				display: flex;
				margin-top: 22px;

				button {
					margin-left: auto;
					font-weight: 500;
					font-size: 14px;
					line-height: 20px;
					padding: 0.5rem 11px;
				}
			}
		}
	}

	.HeadsUpModal {
		width: 320px;
		padding: 24px;
		gap: 1rem;

		.title {
			font-size: 16px;
			line-height: 20px;
		}

		.desc {
			font-size: 13px;
			line-height: 16px;
			margin-bottom: 11px;
		}

		.buttons {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 48px;
			.button {
				font-size: 14px;
				line-height: 18px;
				padding: 6px 14px;
				border-radius: 3.6px;
			}
		}

		&--delete {
			.buttons {
				.button {
					background: #e74c3c;
					&--white {
						background: #ffffff;
						color: #243256;
						border: 0.8px solid rgba(196, 196, 196, 0.5);
					}
				}
			}
		}
	}

	.PassengerDetailsModal {
		background-color: #ffffff;
		border-radius: 3px;
		width: 90vw;
		max-width: 605px;
		overflow: hidden;

		&__head {
			padding: 1rem 20px;
			background: #f5f5f5;
			border-bottom: 0.339149px solid rgba(0, 0, 0, 0.25);

			p {
				font-weight: 500;
				font-size: 20.31px;
				line-height: 26px;
				color: #252627;
			}
		}

		&__body {
			padding: 20px;

			.form-section {
				width: 100%;
				.form-row {
					width: 100%;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					margin-bottom: 0;

					.conjoined {
						width: 45%;
						display: grid;
						grid-template-columns: 1.4fr 2fr;

						.form-item {
							width: 100%;
						}
					}

					.form-item {
						width: 100%;
						margin-bottom: 1rem;
						> p {
							font-size: 14px;
							line-height: 18px;
							color: #655f5f;
							padding: 5px 0;
						}
						input,
						select {
							width: 100%;
							background: #ffffff;
							border: 1px solid rgba(101, 95, 95, 0.2);
							border-radius: 2.56819px;
							font-size: 12.8409px;
							line-height: 16px;
							color: rgba(37, 38, 39, 0.72);
							padding: 5px 0;
							padding-left: 10px;
						}
						select {
							border: 0;
							outline: none;
						}
						.select-wrapper {
							width: 100%;
							border: 1px solid rgba(101, 95, 95, 0.2);
							padding-right: 10px;
						}

						.CustomDropdown__box {
							padding: 5px;
							padding-left: 10px;
							font-size: 12.8409px;
							line-height: 16px;
						}
					}
				}
				.button-wrapper-container {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					gap: 2rem;
					margin-top: 1.5rem;
					p {
						font-weight: 450;
						font-size: 12.8409px;
						line-height: 16px;
						text-align: center;
						cursor: pointer;
						color: #40798c;
					}
					button {
						font-weight: 450;
						font-size: 13.125px;
						line-height: 17px;
					}
				}
			}
		}
	}

	.ReferralModal {
		.formish {
			border: 0.8px solid hsla(0, 0%, 77%, 0.5);
			display: flex;
			flex-direction: column;
			align-items: stretch;
			border-radius: 5px;
			overflow: hidden;

			font-size: 14px;

			p {
				margin: 0.5rem;
			}

			.copyBtn {
				padding: 0.5rem;
				border: 0;
				background: #243256;
				color: #fff;
				cursor: pointer;

				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				gap: 0.5rem;

				&--copied {
					background: #169d53;
				}
			}
		}
	}
}
