.Restrictions {
	max-width: 44vw;
	display: grid;
	grid-template-rows: 68px 1fr;

	&__topbar {
		background: #f5f5f5;
		border-bottom: 0.339149px solid rgba(0, 0, 0, 0.25);
		border-radius: 0px;
		padding: 21px 23px 0 3.05vw;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;

		.tabs {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 2rem;
		}

		.tabItem {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 11px;
			padding: 0 1rem;
			padding-bottom: 24px;
		}

		.vaccine-toggle {
			display: flex;
			flex-direction: row;
			align-items: center;
		}
	}

	&__body {
		padding: 21px 23px 2rem 3.05vw;
		padding-right: 7.7vw;
		max-height: calc(100vh - 68px);
		overflow-y: auto;
		.country-flag {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 1rem;
			justify-content: space-between;
			font-weight: 450;
			font-size: 30px;
			line-height: 38px;
			color: #252627;
			margin-bottom: 1px;

			img {
				width: 80px;
			}
		}

		.time {
			font-weight: 450;
			font-size: 12px;
			line-height: 15px;
			color: #9c9393;
			margin-bottom: 28px;
		}

		.red {
			font-weight: 450;
			font-size: 12px;
			line-height: 15px;
			color: #db1919;
			background: #ffdada;
			border-radius: 3px;
			padding: 0.5rem 35px;
			margin-bottom: 68px;
		}

		.details {
			font-weight: 450;
			font-size: 13.59px;
			line-height: 17px;
			color: #655f5f;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 18px;
			margin-bottom: 37px;
			padding-left: 3.2vw;
		}

		.title {
			font-weight: 450;
			font-size: 16px;
			line-height: 20px;
			color: #252627;
			margin-bottom: 5px;
		}
		.desc {
			font-weight: 450;
			font-size: 13.59px;
			line-height: 17px;
			color: #655f5f;
		}
	}
}

.EditFlightSearch {
	.bottom {
		background-color: #fff;
		padding: 45px 13.9vw;
		border-radius: 0px 0px 8px 8px;

		.close-edit {
			display: none;
		}

		.actions {
			display: flex;
			padding: 15px 0;
			padding-top: 18px;
			align-items: center;
			width: 100%;
			justify-content: space-between;

			.trip-type {
				display: flex;
				gap: 4vw;

				.trip-item {
					display: flex;
					align-items: center;
					.radio {
						width: 12px;
						height: 12px;
						border-radius: 50%;
						border: 1px solid #9c9393;
					}
					.active {
						display: flex;
						align-items: center;
						justify-content: center;
						border: 1px solid black;

						.active-circle {
							background-color: #000000;
							width: 50%;
							height: 50%;
							border-radius: 50%;
						}
					}
					p {
						padding-left: 10px;
						color: #9c9393;
					}
				}
				.mob {
					display: none;
				}
			}
			.flight-type {
				display: flex;
				gap: 1rem;
				p {
					padding: 7.5px 15px;
					color: #6193a3;
					border-radius: 4px;
					border: 1px solid #c4c4c4;
				}
				.current {
					background: #6193a3;
					color: #fff;
					border: 0.970695px solid #6193a3;
				}
			}

			.tripTypeToggle {
				// color: #655f5f;

				.tripTypeLabel {
					cursor: pointer;
					color: rgb(156, 147, 147);

					font-weight: 450;
					font-size: 13.517px;
					line-height: 17px;

					&--selected {
						color: #243256;
					}
				}
			}
		}
		.form-section {
			padding: 20px 0;
			align-items: center;
			width: 100%;

			form {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr 2fr 1fr;
				gap: 2vw;
				width: 100%;
				color: #9c9393;
				.form-item {
					min-width: 17.5%;
					position: relative;

					.label {
						// font-weight: 450;
						// font-size: 13.517px;
						// line-height: 17px;
					}

					.del-trip {
						position: absolute;
						left: 100%;
						cursor: pointer;
						top: 50%;
						margin-left: 0.75rem;
					}
					.stuff {
						border: 1px solid #c4c4c4;
						border-radius: 4.44444px;
						background-color: #fff;
						// padding: 7.5px 20px;
						padding-left: 10px;
						display: flex;
						align-items: center;
						box-sizing: border-box;
						// justify-content: space-between;
						// * {
						//   align-items: center;
						// }
						.css-ie7qsw {
							display: flex;
							width: 100%;
						}
						.css-1okebmr-indicatorSeparator {
							display: none;
						}

						img {
							// position: absolute;
							padding-right: 10px;
						}
						.myselect {
							display: flex;
							box-sizing: border-box;
							width: 87%;
							.css-17guccl-Control {
								width: 100%;
								display: flex;
								.css-319lph-ValueContainer {
									padding: 0;
									.css-14el2xx-placeholder {
										color: #9c9393;
									}
								}
								.css-1okebmr-indicatorSeparator {
									display: none;
								}
								.css-qc6sy-singleValue {
									box-sizing: border-box;
									.one {
										padding-bottom: 0;
										color: #9c9393;
									}
									.two {
										display: none;
									}
								}
							}
							.css-26l3qy-menu {
								// display: block;
								// width: 150%;
								// width: 300%;

								display: flex;
								align-items: center;
								justify-content: center;
								z-index: 15;

								.css-4ljt47-MenuList {
									background: transparent;
									position: absolute;
									z-index: 15;
									top: 10%;
									border: 0;

									width: 100%;
									.css-ftcqfo-option {
										// position: relative;
										// z-index: 15;
										z-index: 15;
										position: relative;
										width: 100%;
										// background-color: #fff;
									}
								}
							}
							.css-qc6sy-singleValue {
								.parent-select {
									div .one {
										color: "#9c9393";
									}
									.two {
										display: none;
									}
								}
							}
							.css-ie7qsw {
								.css-1d8n9bt {
									.css-qc6sy-singleValue {
										.parent-select {
											.one {
												color: red;
											}
										}
									}
								}
							}
						}
					}
					p {
						// padding-bottom: 10px;
						color: #9c9393;
					}

					.select-wrapper,
					.date-wrapper {
						border: 1px solid #c4c4c4;
						border-radius: 4.44444px;
						background-color: #fff;
						padding: 8px 20px;
						padding-left: 10px;
						display: flex;
						align-items: center;
						img {
							width: 20px;
							height: 20px;
							padding-right: 10px;
						}
						input,
						select {
							border: 0;
							outline: none;
							appearance: none;
							color: #9c9393;
						}
						input[type="date"]::-webkit-calendar-picker-indicator {
							color: transparent;
							background: none;
							z-index: 1;
						}
						select {
							padding: 1.5px 0;
						}
					}

					.rmdp-container {
						width: 100%;

						.rmdp-week-day {
							color: #c4c4c4;
						}
						.rmdp-header-values {
							color: #252627;
						}
						.rmdp-range {
							background-color: #40798c;
							color: #fff !important;
						}
						.rmdp-day.rmdp-today span {
							background-color: transparent;
							color: #252627;
						}
						.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
							background-color: #40798c;
						}
						.rmdp-day {
							color: #252627;
						}
						.rmdp-disabled {
							color: #a0a9b1;
						}
					}
					.custom-rmdp-input {
						border: 1px solid #c4c4c4;
						border-radius: 4.44444px;
						background-color: #fff;
						padding: 11.11px 50px;
						font-weight: 450;
						font-size: 13.517px;
						line-height: 17px;
						width: 100%;

						color: #000000;
						height: 42px;

						&::placeholder {
							color: rgb(156, 147, 147);
							opacity: 1;
						}
					}
					.date-wrapper {
						grid-column-gap: 20px;
						padding: 8.5px 20px;
						// input[type="date"]::-webkit-inner-spin-button,
						// input[type="date"]::-webkit-calendar-picker-indicator {
						//   display: none;
						//   -webkit-appearance: none;
						// }

						.input-wrapper {
							display: flex;
							align-items: center;
							color: #655f5f;
						}
					}
					.date-wrapper-new {
						display: flex;
						border: 1px solid #c4c4c4;
						padding: 8px 20px;
						border-radius: 4.44444px;
						img {
							padding-right: 5px;
						}
						input {
							border: 0;
							outline: none;
							appearance: none;
							color: #9c9393;
							width: 100%;
						}
						input[type="date"]::-webkit-calendar-picker-indicator {
							color: #9c9393;
							// background: url("../../resource/img/caretdown.svg") no-repeat
							//   right center;
							background: url("../../resource/img/caretdown.svg");
							background-repeat: no-repeat;
							background-position: center;
							height: 10px;
							width: 10px;
							z-index: 1;
						}
					}
				}
			}
			.multi-trip {
				form {
					padding-bottom: 5px;
					margin-bottom: 21px;
					grid-template-columns: 1fr 1fr 3fr 1fr;
					// gap: 0;
				}

				& > form:first-of-type {
					.dateTypeWrap {
						display: grid;
						grid-template-columns: 1fr 2fr;
						gap: 2vw;
					}
				}

				.multi-actions {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					p {
						font-weight: 450;
						font-size: 13.517px;
						line-height: 17px;
						padding: 7.5px 0px;
						color: #40798c;
					}
					.cancel {
						display: none;
					}
				}
				.add-trip {
					font-weight: 450;
					font-size: 13.517px;
					line-height: 17px;
					/* identical to box height */

					padding: 7.5px 0px;
					color: #40798c;
				}
			}
		}
		.button-container {
			padding: 25px 0;
			padding-top: 5%;
			// width: 90%;
			display: flex;
			justify-content: flex-end;
			gap: 38px;

			.email-request {
				display: flex;
				flex-direction: row;
				align-items: center;

				input {
					border-color: #9c9393;
				}
				p {
					padding-left: 10px;
					color: #9c9393;
				}
			}
		}
	}
}

.MobileFilters {
	position: fixed;
	top: 0;
	left: 0;

	width: 100vw;
	height: 100vh;
	background-color: #ffffff;
	z-index: 32;

	&__Nav {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		height: 61px;
		background-color: #ffffff;
		// box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
		border-bottom: 1px solid rgba(196, 196, 196, 0.5);
		padding: 10px 25px;
		padding-right: 20px;
		position: fixed;
		top: 0;
		width: 100%;

		font-weight: 450;
		font-size: 12px;
		line-height: 15px;
		color: #252627;

		svg {
			width: 18px;
			height: 18px;
			cursor: pointer;
		}
	}

	&__rest {
		padding: 15px;
		padding-bottom: 75px;
		margin-top: 75px;
		height: calc(100vh - 75px);
		overflow: auto;

		.Showing {
			margin-bottom: 21px;
			font-weight: 450;
			font-size: 12px;
			line-height: 15px;
			color: #252627;
		}
	}

	&__Footer {
		width: 100vw;
		position: fixed;
		bottom: 0;
		left: 0;
		background: #ffffff;
		border-top: 1px solid #e1e1e1;
		border-bottom: 1px solid #e1e1e1;
		padding: 23px;

		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 1rem;
		justify-content: space-between;

		p {
			font-weight: 500;
			font-size: 16px;
			line-height: 20px;
			color: #252627;
		}

		button {
			font-weight: 450;
			font-size: 12px;
			line-height: 15px;
			padding: 10px;
		}
	}
}

@media screen and (max-width: 780px) {
	.EditFlightSearch {
		.bottom {
			display: block;
			box-sizing: border-box;
			min-height: 600px;
			height: 100vh;
			padding-top: 100px;

			.close-edit {
				display: flex;
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				padding: 21px 24px 18px 34px;
				background: #ffffff;
				border-bottom: 1px solid rgba(196, 196, 196, 0.5);
				// box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);

				p {
					font-weight: 500;
					font-size: 13px;
					line-height: 16px;

					color: #252627;
				}

				svg {
					margin-left: auto;
				}
			}

			.actions {
				display: block;
				box-sizing: border-box;
				width: 100%;
				padding-top: 11px;
				padding-bottom: 0;
				.trip-type {
					justify-content: space-between;
					width: 100%;
					.trip-item {
						display: none;
						.radio,
						.active {
							display: none;
						}
						p {
							padding: 0;
						}
					}
					.mob {
						display: block;
						cursor: pointer;
						.mob-active {
							color: #40798c;
						}
					}
				}
				.flight-type {
					width: 100%;
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					gap: 22px;
					padding-bottom: 2rem;

					p {
						font-weight: 450;
						font-size: 12px;
						line-height: 15px;
						padding: 11px 8px;
						text-align: center;
					}
				}
			}
			.form-section {
				box-sizing: border-box;
				width: 100%;
				padding-top: 13px;
				padding-bottom: 22px;

				.multi-trip {
					.multi-actions {
						.cancel {
							display: block;
							cursor: pointer;
						}
					}

					& > form:first-of-type {
						.dateTypeWrap {
							display: block;
						}
					}
				}

				form {
					width: 100%;
					display: block;
					box-sizing: border-box;
					.form-item {
						padding-bottom: 15px;
						.select-wrapper {
							select {
								width: 100%;
								background-color: transparent;
							}
						}
						input {
							padding: 7.5px 0;
						}

						del-trip {
							display: none;
						}
					}
				}
			}
			.button-container {
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 1rem;
				justify-content: space-between;

				button {
					font-size: 12px;
					line-height: 15px;
					padding: 12px 25px;
				}
			}
		}
	}
}
