@keyframes rotateLoader {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.PortLoader {
	height: 200px;
	display: grid;
	place-items: center;

	svg {
		animation: rotateLoader 0.75s linear 0s infinite normal forwards;
		width: 30px;
		height: 30px;
	}
}
