.header {
	position: fixed;
	top: 0;
	display: flex;
	z-index: 30;
	width: 100vw;
	height: 61px;
	background: #ffffff;
	padding: 5px 13.9%;
	padding-left: 14.09vw;
	padding-right: 12.5vw;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
	font-weight: 450;
	font-size: 14px;
	line-height: 18px;
	color: #655f5f;
	overflow-y: hidden;
	scrollbar-gutter: stable;

	.help a {
		color: #655f5f;
		text-decoration: none;
	}

	.site-logo {
		height: 20px;
	}
	div {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.link-wrapper {
		width: 50%;
	}
	.link-wrapper a {
		text-decoration: none;
	}
	.user-section {
		width: 15%;
		gap: 15px;
		justify-content: flex-end;

		.bell-wrappers {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
		}
	}
	.help {
		.help-text {
			cursor: pointer;
		}
	}

	.pic-section {
		position: relative;
		.user-pic {
			width: 34px;
			cursor: pointer;
			border-radius: 50%;
		}

		&__drop {
			position: fixed;
			// top: 100%;
			// left: 0;

			min-width: 128px;
			background: #ffffff;
			border: 0.8px solid rgba(196, 196, 196, 0.5);
			border-radius: 4px;
			display: none;
			flex-direction: column;
			align-items: stretch;
			z-index: 31;
			transform: translateY(70%);

			&--open {
				display: flex;
			}

			.name {
				padding: 7px 17px;
				padding-top: 6px;
				background: #40798c;
				color: #ffffff;
				border-radius: 4px 4px 0px 0px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.options {
				display: flex;
				flex-direction: column;
				align-items: stretch;
				margin: 0.25rem 0;

				.option {
					padding: 7px 8px;
					padding-bottom: 6px;
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 1rem;
					justify-content: unset;
					transition: background 0.3s;

					&:hover {
						cursor: pointer;
						background: #f5f5f5;
					}

					&--help {
						padding: 5px 1rem;
						// padding-top: 8px;
						display: flex;
						flex-direction: row;
						align-items: center;
						// gap: 34px;
						padding-right: 41px;
						color: #40798c;
						width: 188px;

						&:hover {
							cursor: pointer;
							background: transparent;
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
	.menu-hamburger {
		display: none;
	}

	.HelpDrop {
		position: fixed;
		// top: 100%;
		// left: 0;

		min-width: 128px;
		background: #ffffff;
		border: 0.8px solid rgba(196, 196, 196, 0.5);
		border-radius: 4px;
		display: none;
		flex-direction: column;
		align-items: stretch;
		z-index: 31;
		transform: translateY(70%);

		&--open {
			display: flex;
		}

		.name {
			padding: 7px 17px;
			padding-top: 6px;
			background: #40798c;
			color: #ffffff;
			border-radius: 4px 4px 0px 0px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.options {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			margin: 0.25rem 0;

			.option {
				padding: 7px 8px;
				padding-bottom: 6px;
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 1rem;
				justify-content: unset;
				transition: background 0.3s;

				&:hover {
					cursor: pointer;
					background: #f5f5f5;
				}

				&--help {
					padding: 5px 1rem;
					// padding-top: 8px;
					display: flex;
					flex-direction: row;
					align-items: center;
					// gap: 34px;
					padding-right: 41px;
					color: #40798c;
					width: 188px;

					&:hover {
						cursor: pointer;
						background: transparent;
						text-decoration: underline;
					}
				}
			}
		}
		.supportPhase {
			width: 100vw;
			max-width: 510px;
			height: 178px;
			display: grid;
			grid-template-columns: 41.5fr 58.5fr;
			border-radius: 3px;
			font-size: 15px;
			font-weight: 450;
			line-height: 16px;
			color: #655f5f;
			& > * {
				height: 100%;
			}

			&__txt {
				background: #f5f5f5;
				border-right: 0.8px solid rgba(196, 196, 196, 0.5);
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 10.5px;
				padding-bottom: 30px;

				&__title {
					font-size: 20px;
					line-height: 22px;
					color: #252627;
				}
			}

			&__contact {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: space-between;
				padding: 32px 35px;

				div {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 2rem;
					transition: color 0.3s;

					&:hover {
						color: #40798c;
						text-decoration: underline;
					}

					svg {
						width: 1rem;
						height: 1rem;
						color: #40798c;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 1024px) {
	.header {
		padding: 0.9375rem 1.5rem;
		box-shadow: unset;
		// box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);

		.link-wrapper,
		.user-section {
			display: none;
		}

		.menu-hamburger {
			display: block;
			cursor: pointer;
		}

		.pic-section {
			&__drop {
				right: 1.5rem;

				.options {
					.option {
						&--help {
							padding-right: 1rem;
							width: 150px;
						}
					}
				}
			}
		}
		.HelpDrop {
			right: 1.5rem;

			.options {
				.option {
					&--help {
						padding-right: 1rem;
						width: 150px;
					}
				}
			}
			.supportPhase {
				max-width: 300px;
				height: 120px;
				&__txt {
					display: none;
				}

				&__contact {
					padding: 16px 24px;
				}
			}
		}
	}
}
