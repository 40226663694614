.CustomDropdown {
	width: 100%;
	position: relative;

	&__box {
		width: max-content;
		min-width: 80px;
		width: 100%;

		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 1rem;
		padding: 0.75rem;
		border: 1.11111px solid #c4c4c4;
		border-radius: 4.44444px;
		cursor: pointer;
		transition: border 0.3s;

		&:hover {
			border: 0.8px solid #655f5f;
		}

		& > div {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 1rem;
			justify-content: space-between;
			width: 100%;

			& > svg {
				margin-left: auto;
				background-color: #ffffff;
				display: block;
				z-index: 1;
			}
		}

		&--plain {
			padding: 0;
			border: 0;
			&:hover {
				border: 0;
			}
		}

		.dropInput {
			padding: 0 1px !important;
			border: 0;
			outline: 0;
			// max-width: 45px;
			// max-height: 17px;
			width: 80%;
			height: 100%;
			padding-left: 1rem !important;
			background: transparent;
			color: #9c9393;
			position: absolute;
			top: 0;
			left: 0;

			&:hover {
				border: 0 !important;
			}

			&--hasIcon {
				padding-left: 42px !important;
			}
		}
	}

	&__drop {
		height: 0;
		overflow: hidden;
		font-family: "Circular Std";
		font-style: normal;
		font-weight: 450;
		font-size: 12.17px;
		line-height: 15px;
		box-shadow: 0px 0px 5px 0px rgba(64, 121, 140, 0.25);
		// box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
		border-radius: 2.57px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		background-color: #ffffff;

		position: absolute;
		top: 95%;
		left: 0;
		width: 100%;
		z-index: 1400;
		opacity: 0;
		transition: all 0.3s;

		&--open {
			height: max-content;
			min-width: 128px;
			top: calc(100% + 7px);
			opacity: 1;
		}

		.drop-item {
			padding: 0.5rem;
			transition: background 0.3s;
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 1rem;
			justify-content: space-between;
			color: #252627;
			background: #ffffff;

			&:hover {
				cursor: pointer;
				background: #f5f5f5;
			}
			svg {
				visibility: hidden;
			}

			&--selected {
				background: #40798c;
				color: #ffffff;
				&:hover {
					cursor: pointer;
					background: #40798c;
				}
				svg {
					visibility: visible;
				}
			}
		}

		.noOptions {
			padding: 2.5rem 0.5rem;

			p {
				text-align: center;
				color: #252627 !important;
			}
		}
	}
}
