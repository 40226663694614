.CustomTooltip {
	display: none;

	position: absolute;
	// position: fixed;
	top: 120%;
	left: -50%;
	width: max-content;
	max-width: 410px;
	transform: translateX(-50%);
	z-index: 2;
	color: #ffffff;
	font-weight: 450;
	font-size: 14.01px;
	line-height: 18px;
	background: #252627;
	border-radius: 8px;
	padding: 10px 14px;
}
