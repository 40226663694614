.CarPhase2 {
	background-color: #ffffff;
	border: 0.970695px solid #f5f5f5;
	border-radius: 0px 8px 8px 8px;
	padding: 48px 42px 33px 48px;
	display: grid;
	grid-template-columns: 43.1fr 46.9fr;
	gap: 6.3vw;

	.car-title {
		font-weight: 700;
		font-size: 20px;
		line-height: 25px;
		color: #655f5f;
		margin-left: 12px;
	}
	.car-price {
		font-weight: 700;
		font-size: 25px;
		line-height: 32px;
		text-align: right;
		margin-bottom: 0.5rem;
	}

	&__left {
		& > img {
			width: 100%;
		}
	}

	&__right {
		&__edit {
			display: flex;
			padding: 0.75rem;
			p {
				margin-left: auto;
				font-weight: 450;
				font-size: 14px;
				line-height: 18px;
				color: #40798c;
				cursor: pointer;
			}
		}

		&__card {
			background: #f9fafa;
			border-radius: 10px;
			padding: 30px;

			.btnWrap {
				display: grid;
				grid-template-columns: 1.1fr 1fr;
				gap: 3vw;

				& > button:first-of-type {
					font-size: 13.517px;
					line-height: 17px;
				}

				& > button:last-of-type {
					font-weight: 450;
					font-size: 15.5556px;
					line-height: 20px;
					padding: 10px 44px;
					width: 100%;
				}
			}
		}

		.carInfo {
			margin-bottom: 1.5rem;
			&__row {
				padding-bottom: 17px;
				margin-bottom: 17px;
				border-bottom: 1px solid #d9d9d9;

				display: grid;
				grid-template-columns: 1.1fr 1fr;
				align-items: flex-start;
				gap: 3vw;

				.carInformation {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					gap: 10px;
					&__row {
						display: flex;
						flex-direction: row;
						align-items: center;
						gap: 5px;

						svg {
							width: 15px;
							height: 15px;
							color: #252627;
						}
					}
				}

				.priceBreakdown {
					font-weight: 450;
					font-size: 13.59px;
					line-height: 17px;

					&__title {
						color: #252627;
						margin-bottom: 15px;
					}
					&__double {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: space-between;
						gap: 15px;
						margin-bottom: 9px;

						& > :first-child {
							color: #9c9393;
						}

						.price {
							font-weight: 500;
							font-size: 18px;
							line-height: 23px;
							color: #252627;
						}
					}
					.total {
						margin-top: 2rem;
					}
				}
			}
		}

		.rentalTag {
			padding: 3px 5px;
			padding-left: 6px;
			background: #fff1eb;
			border-radius: 3px;
			width: max-content;

			color: #fca17d;
			font-weight: 450;
			font-size: 12px;
			line-height: 20px;

			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 0.25rem;
			cursor: pointer;
		}
	}
}

.CarInfoBunch {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 0.5rem;

	&__left {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;

		& > p {
			font-weight: 450;
			font-size: 13.59px;
			line-height: 17px;
		}

		.label {
			font-weight: 450;
			color: #655f5f;
		}
		.value {
			font-weight: 450;
			color: #252627;

			.MuiTextField-root {
				margin-top: -2px;
			}
			.MuiOutlinedInput-input {
				font-family: "Circular Std";
				padding: 0;
				font-size: 13.517px;
				line-height: 17px;
				max-width: 60px;
				min-width: 40px;
			}
			.MuiOutlinedInput-notchedOutline {
				border: 0;
			}
			.MuiSvgIcon-root {
				font-size: 14px;
			}
		}

		&--selectVariant {
			flex-direction: row;

			.CustomDropdown__drop--open {
				max-height: 300px;
				overflow-y: scroll;
			}
		}
	}

	&--right {
		justify-content: flex-end;

		.CarInfoBunch__left {
			align-items: flex-end;
			p {
				text-align: right;
			}
		}
	}
}

.MuiDrawer-paper {
	height: max-content !important;
	border-radius: 0px 0px 0px 8px;
}

.RentalInfoDrawer {
	width: 501px;
	height: 622px;
	padding: 56px 37px;
	padding-top: 2rem;
	padding-left: 34px;
	background: #ffeae2;
	border-radius: 8px 0px 0px 8px;

	h3 {
		font-weight: 700;
		font-size: 20px;
		line-height: 25px;
	}
	p,
	li {
		font-weight: 450;
		font-size: 13px;
		line-height: 18px;
	}

	&__pt1 {
		margin-bottom: 37px;
		h3 {
			margin-bottom: 15px;
		}
		ul {
			margin-left: 1.25rem;

			li {
				margin-bottom: 11px;
			}
		}
	}

	&__pt2 {
		h3 {
			margin-bottom: 5px;
		}

		& > p:first-of-type {
			margin-bottom: 1.2rem;
		}
	}
}

@media screen and (max-width: 1024px) {
	.CarPhase2 {
		background-color: #ffffff;
		border-radius: 0px;
		padding: 1.5rem 0;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		gap: 1rem;
		padding: 2px 5px;
		padding-top: 20px;

		.car-title {
			font-weight: 700;
			font-size: 20px;
			line-height: 25px;
			margin-left: 0;
		}
		.car-price {
			font-weight: 700;
			font-size: 25px;
			line-height: 32px;
			text-align: right;
			margin-bottom: 0.5rem;
		}

		&__left {
			width: 100%;
			padding: 0 1rem;
			& > img {
				width: 100%;
			}
		}

		&__right {
			&__edit {
				display: flex;
				padding: 0.75rem;
				p {
					margin-left: auto;
					font-weight: 450;
					font-size: 14px;
					line-height: 18px;
					color: #40798c;
					cursor: pointer;
				}
			}

			&__card {
				border-radius: 0;
				padding: 24px 0.5rem;

				.btnWrap {
					display: flex;
					justify-content: space-between;
					gap: 40px;

					button {
						font-size: 12px;
						line-height: 15px;
					}

					& > button:first-of-type {
						width: 100%;
						padding: 11.4px;
						font-size: 12px;
						line-height: 15px;
					}
					& > button:last-of-type {
						width: max-content;
						padding: 11.4px 28.5px;
						font-size: 12px;
						line-height: 15px;
					}
				}
			}

			.carInfo {
				margin-bottom: 2rem;
				&__row {
					padding-bottom: 3px;
					margin-bottom: 21px;
					border-bottom: 1px solid #d9d9d9;

					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 1rem;
					justify-content: space-between;

					.carInformation {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						gap: 10px;
						&__row {
							display: flex;
							flex-direction: row;
							align-items: center;
							gap: 5px;

							svg {
								width: 15px;
								height: 15px;
								color: #252627;
							}
						}
					}

					.priceBreakdown {
						font-weight: 450;
						font-size: 13.59px;
						line-height: 17px;
						width: 100%;
						padding-top: 12px;

						&__title {
							color: #252627;
							margin-bottom: 15px;
						}
						&__double {
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: space-between;
							gap: 15px;
							margin-bottom: 9px;

							& > :first-child {
								color: #9c9393;
							}

							.price {
								font-weight: 500;
								font-size: 18px;
								line-height: 23px;
								color: #252627;
							}
						}
						.total {
							margin-top: 13px;
						}
					}
				}
			}

			.rentalTag {
				padding: 3px 5px;
				padding-left: 6px;
				margin-top: 25px;
				background: #fff1eb;
				border-radius: 3px;
				width: max-content;

				color: #fca17d;
				font-weight: 450;
				font-size: 12px;
				line-height: 20px;

				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 0.25rem;
				cursor: pointer;
			}
		}
	}

	.CarInfoBunch {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		gap: 0.5rem;

		&__left {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 6px;
			width: 100%;

			& > p {
				font-weight: 450;
				font-size: 13.517px;
				line-height: 17px;
			}

			.label {
				font-weight: 450;
				color: #655f5f;
			}
			.value {
				font-weight: 450;
				color: #252627;
				width: 100%;

				.MuiTextField-root {
					margin-top: -2px;
					width: 100%;
				}
				.MuiOutlinedInput-input {
					font-family: "Circular Std";
					padding: 0;
					font-size: 13.517px;
					line-height: 17px;
					width: 100%;
				}
				.MuiOutlinedInput-notchedOutline {
					border: 0;
				}
				.MuiSvgIcon-root {
					font-size: 14px;
				}
			}

			&--selectVariant {
				flex-direction: row;

				.CustomDropdown__drop--open {
					max-height: 300px;
					overflow-y: scroll;
				}
			}
		}

		&--right {
			justify-content: flex-end;

			.CarInfoBunch__left {
				align-items: flex-end;
				p {
					text-align: right;
				}
			}
		}
	}

	.RentalInfoDrawer {
		width: 100%;
		height: 100%;
		max-height: 90vh;
		overflow-y: auto;
		padding: 2rem 1rem;
		border-radius: 8px 0px 0px 8px;

		h3 {
			font-weight: 700;
			font-size: 20px;
			line-height: 25px;
		}
		p,
		li {
			font-weight: 450;
			font-size: 13px;
			line-height: 18px;
		}

		&__pt1 {
			margin-bottom: 37px;
			h3 {
				margin-bottom: 15px;
			}
			ul {
				margin-left: 1.25rem;

				li {
					margin-bottom: 11px;
				}
			}
		}

		&__pt2 {
			h3 {
				margin-bottom: 5px;
			}

			& > p:first-of-type {
				margin-bottom: 1.2rem;
			}
		}
	}
}
