.menu {
	position: relative;
}

.menu-button {
	background-color: transparent;
	border: 0;
	border: 1.11111px solid #c4c4c4;
	border-radius: 4.44444px;
	width: 100%;
	padding: 9px 0.75rem;
	text-align: left;
	cursor: pointer;

	display: grid;
	grid-template-columns: 22px 1fr;
	// flex-direction: row;
	align-items: center;
	gap: 0.75rem;
	transition: border 0.3s;
	position: relative;

	&:hover {
		border: 0.8px solid #655f5f;
	}

	input {
		padding: 0 1px !important;
		border: 0;
		outline: 0;
		// max-width: 45px;
		// max-height: 17px;
		width: 100%;
		height: 100%;
		background: transparent;
		padding-left: 42px !important;

		position: absolute;
		top: 0;
		left: 0;

		&:hover {
			border: 0 !important;
		}
	}
}

.menuList {
	min-height: 60px;
	max-height: 350px;
	width: 450px;

	overflow: auto;
	z-index: 101;
	position: absolute;
	top: 50px;
	left: 0;
	background-color: #ffffff;
	box-shadow: 0px 0px 5px 0px rgba(64, 121, 140, 0.25);
	opacity: 1;
	// visibility: hidden;
	transition: all 0.3s;

	&--shut {
		min-height: 0px;
		max-height: 0px;
		top: 40px;
		opacity: 0;
	}
}

.menuList__item {
	border-bottom: 1px solid #f3f1f1;
	padding: 9px 19px;
	cursor: pointer;
	transition: background-color 0.1s;

	&:hover {
		background-color: #f5f5f5;
	}

	.ls-item {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 28px;

		.space-between {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			gap: 12px;
			width: 100%;

			font-weight: 450;
			font-size: 12.17px;
			line-height: 15px;
			color: #9c9393;

			.airport {
				color: #252627 !important;
				margin-bottom: 2px;
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.menuList {
		max-width: 100%;

		// visibility: hidden;
	}
}
