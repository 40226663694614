.BigToggle {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
	background: rgba(196, 196, 196, 0.15);
	border-radius: 100px;
	padding: 11px;

	font-weight: 500;
	font-size: 13.59px;
	line-height: 17px;
	color: #655f5f;
	text-align: center;
	max-width: 520px;

	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1px;
		padding: 5px 14px 7px 14px;
		border-radius: 100px;
		border: 1px solid transparent;
		cursor: pointer;

		.extra {
			color: rgba(156, 147, 147, 0.99);
			// width: max-content;
		}

		&--selected {
			background: #ffffff;
			border-color: #9c9393;
		}
	}
}

@media screen and (max-width: 930px) {
	.BigToggle {
		width: 100%;
		justify-content: space-between;
		&__item {
			padding: 8px 27px;
			.extra {
				display: none;
			}
		}
	}
}
