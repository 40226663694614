.flightDetails {
	color: #9c9393;
	font-weight: 450;
	font-size: 14.01px;
	line-height: 18px;

	img {
		width: 80px;
	}

	> div {
		border: 0;

		.overview {
			display: grid;
			grid-template-columns: 80px 1fr;
			// flex-direction: row;
			align-items: center;
			gap: 54px;
			padding: 1rem 0;
			padding-left: 42px;
			padding-right: 1rem;
			padding-bottom: 0;

			.details {
				> div {
					display: flex;
					flex-direction: row;
					gap: 32px;
					margin-bottom: 15px;

					> div > p {
						margin-bottom: 15px;
					}
				}
				.show-details {
					font-weight: 500;
					font-size: 14.01px;
					line-height: 18px;
					color: #40798c;
					cursor: pointer;
				}
			}
		}
	}

	.black {
		color: #252627;
		font-size: 14px;
		line-height: 18px;
	}
}

.show-details {
	font-weight: 500;
	line-height: 18px;
	color: #40798c;
	cursor: pointer;
}

.show-details {
	padding-left: 34px;
	padding-right: 1rem;
	padding-bottom: 1rem;
	margin-top: 0px;
	display: grid;
	grid-template-columns: 80px 1fr;
	gap: 63px;

	p {
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		color: #40798c;
		cursor: pointer;
	}
}
.moreDetails {
	min-height: 130px;
	max-height: 1800px;
	overflow: hidden;
	transition: all 0.5s;
	&--shut {
		height: 0;
		min-height: 0;
		max-height: 0;
	}

	.moreDetailsInner {
		padding: 11px 0;
		background: #f9fafa;
		padding-left: 42px;
		padding-right: 42px;

		border: 0.8px solid rgba(156, 147, 147, 0.2);
		gap: 48px;
		align-items: center;
		display: grid;
		grid-template-columns: 80px 1fr;

		.moreDetails__right {
			padding-top: 1rem;
			padding-bottom: 1rem;
			display: flex;
			flex-direction: column;
			align-items: stretch;
			text-align: center;

			& > :first-child {
				margin-bottom: 10px;
			}

			.journey {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				align-items: center;
				justify-content: space-between;
				font-size: 14px;
				margin-bottom: 5px;

				.names {
					p {
						font-size: 14px;
						line-height: 16px;
					}

					& > :first-child {
						color: #655f5f;
						margin-bottom: 2px;
					}
					& > :nth-child(2) {
						color: #252627;
						font-weight: 500;
					}

					& > div {
						font-weight: 450;
						font-size: 8.83585px;
						line-height: 12px;
						color: #222222;
						margin-top: 1.5rem;
						border-top: 0.8px solid rgba(37, 38, 39, 0.3);
						border-bottom: 0.8px solid rgba(37, 38, 39, 0.3);
						padding: 0.2rem 0;
					}
				}

				.duration {
					p {
						font-size: 14px;
						line-height: 16px;
						color: #9c9393;
					}
				}

				.times {
					& > div {
						font-weight: 450;
						font-size: 12px;
						line-height: 14px;
						color: #222222;
						border-top: 0.8px solid rgba(37, 38, 39, 0.3);
						border-bottom: 0.8px solid rgba(37, 38, 39, 0.3);
						padding: 0.2rem 0;
					}
				}

				.class {
					p {
						font-size: 16px;
						line-height: 16px;
						color: #9c9393;
					}
				}
			}

			.terminal {
				font-weight: 450;
				margin-top: 0.75rem;

				display: flex;
				flex-direction: column;
				align-items: stretch;
				gap: px;

				p {
					font-size: 12px;
					line-height: 15px;
					color: #655f5f;
				}
			}
		}
	}

	.moreDetailsLayover {
		font-weight: 450;
		font-size: 13px;
		line-height: 19px;
		color: #655f5f;
		text-align: center;
		padding-top: 7px;
		padding-bottom: 6px;
		margin: 4px 0;

		background: #f9fafa;
		border: 0.8px solid rgba(196, 196, 196, 0.5);
	}

	.middleSection {
		background: #f9fafa;
		margin: 10px 0;
		padding: 10px 0;
		font-size: 14px;
		text-align: center;
		border: 0.8px solid rgba(156, 147, 147, 0.2);
	}
}
.FootNoteCard {
	background: #f5f5f5;
	padding: 18px;
	padding-bottom: 28px;
	font-weight: 450;
	font-size: 13px;
	line-height: 26px;
	color: #252627;
	margin-top: 0.5rem;

	p {
		color: #655f5f;
	}

	a {
		text-decoration: none;
		color: #252627;
	}

	.title {
		font-weight: 500;
		font-size: 20px;
		line-height: 25px;

		color: #ff7477;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 6px;
		margin-bottom: 22px;
	}
}

.PriceBreakdownBlock {
	font-weight: 500;
	font-size: 13.59px;
	line-height: 17px;

	color: #252627;
	padding: 10px;

	& > h3 {
		margin-bottom: 1rem !important;
	}
	.double-col {
		display: grid;
		grid-template-columns: 1fr 4px 1fr;
		margin-bottom: 12px;

		& > :last-child {
			text-align: right;
		}
	}
	.gray {
		color: #655f5f;
		font-weight: 450;
	}

	.total {
		padding: 15px 0;
		border-top: 1px solid rgba(196, 196, 196, 0.5);
		margin-top: 15px;

		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
	}
}

.Badge {
	padding: 2px 16px;
	text-align: center;
	border-radius: 50px;
	font-weight: 450;
	font-size: 12px;
	line-height: 15px;
	color: #252627;
	background: #ffeaf9;

	&--Departure {
		background: #ffeaef;
	}
	&--Return {
		background: #ffeaf9;
	}
}

@media screen and (max-width: 800px) {
	.flight-details {
		padding: 30.5px 20px;
		font-weight: 450;
		font-size: 13px;
		line-height: 16px;
		color: #655f5f;
		border-bottom: 1px solid #c4c4c4;

		h4 {
			margin-bottom: 0.75rem;
		}
		.section {
			margin-bottom: 0;
			.two-col {
				margin-bottom: 15px;
				display: grid;
				grid-template-columns: 90px 1fr;
				gap: 22px;
				align-items: flex-start;

				> :first-child {
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.locations {
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					gap: 30px;
				}
				.black {
					margin-bottom: 4px;
				}

				img {
					width: 60px;
				}
			}

			.blue-link {
				font-weight: 500;
				font-size: 13px;
				line-height: 16px;
				color: #40798c;
				span {
					cursor: pointer;
					border-bottom: 0.3px solid #c4c4c4;
					padding-bottom: 1rem;
				}
				// text-align: right;
			}
		}
	}

	.show-details {
		margin-bottom: 0;
		margin-top: 0;
		display: grid;
		grid-template-columns: 90px 1fr;
		gap: 22px;
		padding-left: 0;
		padding-bottom: 0;

		> p {
			font-weight: 500;
			font-size: 13px;
			line-height: 16px;
			color: #40798c;
			cursor: pointer;
			padding-bottom: 1rem;
			border-bottom: 0.3px solid #c4c4c4;
			display: inline;
			width: max-content;
		}
	}

	.moreDetails {
		background: #e5e8e8;
		// border: 0.8px solid rgba(196, 196, 196, 0.5);
		border: 0;
		margin-bottom: 1rem;

		min-height: 137px;
		max-height: 800px;
		overflow: hidden;
		transition: all 0.5s;
		&--shut {
			height: 0;
			min-height: 0;
			max-height: 0;
		}

		.moreDetailsInner {
			grid-template-columns: unset;
			padding: 5.5px 6.8px;
		}

		& > div {
			padding: 5.5px 6.8px;
			display: block;

			img {
				display: none;
			}

			.moreDetails__right {
				display: flex;
				flex-direction: column;
				align-items: stretch;
				text-align: center;

				.journey {
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					justify-content: space-between;

					.names {
						p {
							font-size: 12px;
							line-height: 16px;
						}

						& > :first-child {
							color: #655f5f;
							margin-bottom: 0.4rem;
						}
						& > :nth-child(2) {
							color: #252627;
							font-weight: 500;
						}

						& > div {
							font-weight: 450;
							font-size: 8.83585px;
							line-height: 12px;
							color: #222222;
							margin-top: 1.5rem;
							border-top: 0.8px solid rgba(37, 38, 39, 0.3);
							border-bottom: 0.8px solid rgba(37, 38, 39, 0.3);
							padding: 0.2rem 0;
						}
					}

					.times {
						p {
							font-size: 10px;
							line-height: 19px;
							color: #9c9393;
						}
						& > :first-child {
							margin-bottom: 0.1rem;
						}
					}
				}

				.terminal {
					font-weight: 450;
					margin-top: 18px;
					p {
						font-size: 10px;
						line-height: 15px;
						color: #655f5f;
					}
				}
			}
		}
	}

	.FootNoteCard {
		background: #f5f5f5;
		padding: 15px 24px;
		padding-top: 18px;
		font-weight: 450;

		margin: 41.5px 0;
		margin-bottom: 40px;
		color: #655f5f;

		p {
			font-size: 13px;
			line-height: 22px;
		}

		.title {
			font-weight: 500;
			font-size: 16px;
			line-height: 20px;
			margin-bottom: 18px;

			svg {
				width: 18px;
				height: 18px;
			}
		}

		a {
			text-decoration: none;
			color: #252627;
		}
	}

	.PriceBreakdownBlock {
		font-weight: 450;
		font-size: 13px;
		line-height: 16px;
		padding: 0;
		color: #252627;
		border-bottom: 0;

		> :first-child {
			font-size: 16px;
			line-height: 27px;
			margin-bottom: 20px !important;
		}
		.double-col {
			display: grid;
			grid-template-columns: 1fr 4px 1fr;
			margin-bottom: 12px;
		}
		.double-col.smaller {
			margin-bottom: 0.5rem;
		}
		.total {
			margin-top: 20.5px;
			padding-top: 20.5px;
			padding-bottom: 0;
			p {
				font-weight: 500;
				font-size: 15px;
				line-height: 19px;
				color: #252627;
			}
		}
		.gray {
			color: #655f5f;
		}
	}
}
