.CustomFilter {
	position: relative;

	&__box {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 0.5rem;
		justify-content: space-between;
		min-width: 70px;
		padding: 0.5rem;
		background: rgba(196, 196, 196, 0.15);
		border-radius: 7.01124px;

		font-family: "Circular Std";
		font-style: normal;
		font-weight: 450;
		font-size: 13.59px;
		line-height: 17px;
		color: #655f5f;
		cursor: pointer;
	}

	&__drop {
		position: absolute;
		top: 100%;
		left: 0;
		width: 248px;
		background: #ffffff;
		border-radius: 8px;
		box-shadow: 0px 4px 4px rgba(156, 147, 147, 0.1);
		z-index: 1;
		height: 0;
		overflow: hidden;
		color: #655f5f;
		border: 0;

		&--open {
			height: max-content;
			border: 0.8px solid rgba(196, 196, 196, 0.5);
		}

		& > div {
			padding: 21px 17px;
		}

		.title {
			text-transform: uppercase;
			margin-bottom: 1rem;
			font-weight: 500;
			font-size: 13px;
			line-height: 16px;
			color: #9c9393;
		}

		.radioItem {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 20px;
			margin-bottom: 20px;
			margin-left: 10px;
			cursor: pointer;

			&__dot {
				width: 11px;
				height: 11px;
				background: #c4c4c4;
				border-radius: 50%;

				&--checkbox {
					width: 14px;
					height: 14px;
					border-radius: 2px;
					display: grid;
					place-items: center;

					svg {
						visibility: hidden;
					}
				}

				&--selected {
					background: #252627;

					svg {
						visibility: visible;
					}
				}
			}

			p {
				font-weight: 450;
				font-size: 13.59px;
				line-height: 17px;
			}
		}
	}

	&--time {
		.CustomFilter__drop {
			width: unset;

			& > div {
				padding: 0;

				.title {
					display: none;
				}
			}
		}
	}
}

.CustomTimeFilter {
	padding: 26px 50px 27px 49px;
	min-width: 677px;

	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 89px;

	h4 {
		color: #9c9393;
		font-size: 13px;
		font-weight: 500;
		text-transform: uppercase;

		padding-bottom: 31px;
	}

	&__checkboxes {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		gap: 1.5rem;

		& > div {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 1rem;
			justify-content: space-between;

			.range {
				color: #9c9393;
				font-size: 13px;
				font-family: Circular Std;
				font-weight: 450;
			}
		}
	}
}

@media screen and (max-width: 930px) {
	.CustomFilter {
		position: relative;
		margin-bottom: 53px;
		&__box {
			display: none;
		}

		&__drop {
			// width: 248px;
			background: #ffffff;
			border-radius: 0;
			box-shadow: unset;
			height: 0;
			overflow: hidden;
			color: #655f5f;
			border: 0;
			height: max-content;
			position: unset;
			width: 100%;

			&--open {
				height: max-content;
				border: 0;
			}

			& > div {
				padding: 0;
			}

			.title {
				text-transform: uppercase;
				margin-bottom: 1rem;
				font-weight: 500;
				font-size: 13px;
				line-height: 16px;
				color: #9c9393;
				margin-left: 0 !important;
			}

			.radioItem {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 20px;
				margin-bottom: 20px;
				margin-left: 10px;
				cursor: pointer;

				&__dot {
					width: 11px;
					height: 11px;
					background: #c4c4c4;
					border-radius: 50%;

					&--checkbox {
						width: 14px;
						height: 14px;
						border-radius: 2px;
						display: grid;
						place-items: center;

						svg {
							visibility: hidden;
						}
					}

					&--selected {
						background: #252627;

						svg {
							visibility: visible;
						}
					}
				}

				p {
					font-weight: 450;
					font-size: 13.59px;
					line-height: 17px;
				}
			}
		}
	}

	.CustomTimeFilter {
		padding: 0;
		padding-right: 49px;
		min-width: unset;
		width: 100%;

		display: flex;
		flex-direction: column;
		align-items: stretch;
		gap: 41px;

		h4 {
			padding-bottom: 35px;
		}

		&__checkboxes {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			gap: 1.5rem;

			padding-left: 12px;

			.CustomCheckBox {
				color: #655f5f;
				font-size: 14px;
				font-weight: 450;
				line-height: normal;
			}
			.range {
				color: #9c9393;
				font-size: 13px;
				font-weight: 450;
				line-height: normal;
			}
		}
	}
}
