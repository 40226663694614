.CustomCheckBox {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 14px;

	color: #252627;
	font-size: 14px;
	font-weight: 450;
	cursor: pointer;
	position: relative;

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		transform: translateX(-4px) translateY(-1.75px);

		width: 17.5px;
		height: 16.25px;

		padding: 1px;
		border-radius: 2.333px;
		border: 1px solid #c4c4c4;
		background: #e6e6e6;
		box-sizing: border-box;
	}

	&--checked {
		&::after {
			content: url(../../assets/svgs/check.svg);
			position: absolute;
			top: 0;
			left: 0;
			transform: translateX(-4px) translateY(-1.75px);

			width: 17.5px;
			height: 16.25px;

			padding: 2.5px;
			border-radius: 2.333px;
			border: 0;
			background: #252627;
			box-sizing: border-box;
		}
	}

	input[type="checkbox"] {
		// accent-color: #252627;
		opacity: 0;
	}
}

@media screen and (max-width: 930px) {
	.CustomCheckBox {
		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			transform: translateX(-2px);

			width: 14px;
			height: 14px;

			padding: 1px;
			border-radius: 2.14px;
			border: 1px solid #c4c4c4;
			background: #e6e6e6;
			box-sizing: border-box;
		}

		&--checked {
			&::after {
				content: url(../../assets/svgs/check-small.svg);
				position: absolute;
				top: 0;
				left: 0;
				transform: translateX(-2px);
				// transform: translateX(-4px) translateY(-1.75px);

				width: 16px;
				height: 16px;

				padding: 1px;
				// padding-bottom: 3px;
				border-radius: 2.14px;
				background: #252627;
			}
		}
	}
}
