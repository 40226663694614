.ComingSoon {
	height: 100%;
	display: flex;
	& > div {
		margin: auto;
		margin-top: 18%;
	}

	&__title {
		text-align: center;
		margin-bottom: 0.5rem;
	}

	&__desc {
		text-align: center;
	}

	&__buttons {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		margin-top: 1.5rem;

		& > button:first-of-type {
			background-color: transparent;
			color: #243256;
			border: 1px solid #243256;
		}
	}
}

@media screen and (max-width: 780px) {
	.ComingSoon {
		height: 100%;
		display: flex;
		width: 100%;
		padding: 1rem;

		& > div {
			margin: auto;
			margin-top: 40%;
		}

		&__title {
			text-align: center;
			margin-bottom: 0.5rem;
		}

		&__desc {
			text-align: center;
		}

		&__buttons {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			gap: 1.5rem;
			margin-top: 1.5rem;

			button {
				font-weight: 450;
				font-size: 15.996px;
				line-height: 20px;
				padding: 0.5rem 11px;
			}

			& > button:first-of-type {
				background-color: transparent;
				color: #243256;
				border: 1px solid #243256;
			}
		}
	}
}
