.page1new,
.page2new,
.page3new,
.page4new {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	.heading {
		h5 {
			font-family: "Circular Std";
			font-style: normal;
			font-size: 20.21px;
			line-height: 26px;
			text-align: center;
			color: #252627;
			margin-bottom: 3.5%;
		}
		p {
			font-family: "Circular Std";
			font-style: normal;
			font-size: 13.59px;
			line-height: 17px;
			text-align: center;
			color: #655f5f;
		}

		// .small {
		//     color: #655f5f;
		// }
	}
	.form-section {
		display: flex;
		flex-direction: column;
		font-family: "Circular Std";
		width: 100%;
		// height: 100%;
		padding-top: 10%;
		form {
			width: 100%;
			.form-rows {
				& > .form-row:first-of-type {
					margin-bottom: 19px;
				}
				& > .form-row:nth-of-type(2) {
					margin-bottom: 17px;
				}
				& > .form-row:nth-of-type(3) {
					margin-bottom: 21px;
				}
			}
			.form-row {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-column-gap: 40px;
				// padding: 10px 0;
				width: 100%;
			}
			.form-item {
				width: 100%;
				width: 100%;
				// padding: 5px 0;
				label {
					// padding: 5px 0;
					// color: #655f5f
					font-weight: 100;

					color: #252627 !important;
					font-weight: 450;
					font-size: 15px;
					line-height: 19px;
					display: block;
				}
				input,
				select {
					background: #f9fafa;
					border: 0.8px solid rgba(196, 196, 196, 0.5);
					border-radius: 4px;
					padding: 12px 24px;
					padding-top: 11px;
					width: 100%;
					color: #252627;
					color: #252627;
					box-sizing: border-box;
					font-weight: 450;
					font-size: 13.59px;
					line-height: 17px;
				}
				.select-wrapper {
					border: 0.8px solid rgba(196, 196, 196, 0.5);
					background: #f9fafa;
					border-radius: 4px;
					padding: 10px 24px;
					// padding-top: 10px;

					padding-right: 10px;
				}
				select {
					border: 0;
					padding: 0;
				}
				.prompt {
					color: red;
					font-size: 70%;
					padding: 5px 0;
				}
			}
			.last-form-row {
				p {
					padding: 5% 0;
				}
			}

			.forgot-link {
				padding: 0;
				margin-top: 3px;
				font-weight: 450;
				font-size: 13.59px;
				line-height: 17px;

				a {
					font-weight: 500;
				}
			}
			.button-wrapper {
				display: flex;
				// padding: 2.5% 0;
				justify-content: flex-end;
				grid-gap: 5%;
				align-items: center;
				width: 100%;
				margin-top: 50px;
				.previous-wrapper {
					display: flex;
					align-items: center;
					display: flex;
					padding: 12px 0;
					&:hover {
						cursor: pointer;
					}
					p {
						padding-left: 7.5px;
						color: #655f5f;
					}
				}
				.spinner-container {
					width: 20%;
					// min-width: 89px;
				}
				p {
					font-size: 80%;
				}
				img {
					width: 15px;
					color: pink;
				}
				.disabled {
					background: #9c9393;
					cursor: not-allowed;
				}

				button {
					font-weight: 450;
					font-size: 12.8409px;
					line-height: 16px;
					padding: 0.5rem 28px;
					width: 89px;
					height: 32px;
				}
			}
		}
	}
	.bottom {
		text-align: center;
		p,
		a {
			// padding-top: 20px;
			color: #9c9393;
			font-size: 80%;
			font-family: "Circular Std";
			font-style: normal;
			font-weight: 100;
			font-size: 15px;
			line-height: 19px;
			text-decoration: none;
			margin: 5px 0;
		}
		a {
			color: #40789c;
			font-weight: 500;
		}
	}
	.dots {
		width: 15%;
		display: flex;
		justify-content: space-between;
		.current {
			border-radius: 5px;
			background: #243256;
		}
		div {
			width: 7.5px;
			height: 7.5px;
			border-radius: 5px;
			background: rgba(196, 196, 196, 0.5);
		}
	}
}

.page1new {
	.form-section {
		form {
			.button-wrapper {
				padding: 0;
			}
		}
	}
}

.page2new {
	.heading {
		justify-content: center;
		text-align: center;
		color: #252627;
		font-size: 16.21px;
		line-height: 26px;
	}

	.form-section {
		.form-item {
			.info-wrapper {
				margin-bottom: 11px;
			}
		}
	}

	.bottom {
		a,
		p {
			font-size: 13.59px;
			line-height: 17px;
		}

		.hover {
			color: #40798c;
			font-weight: 500;
		}
	}
}

.page3new {
	.form-section form .button-wrapper {
		margin-top: 27px;
	}
}
.page3new,
.page4new {
	.heading {
		h5 {
			font-family: "Circular Std";
			font-style: normal;
			font-weight: 450;
			font-size: 16.21px;
			line-height: 26px;
			text-align: center;
			color: #252627;
			flex: none;
			order: 0;
			flex-grow: 0;
		}
	}
	.form-section {
		padding: 0;
		form {
			.form-row {
				display: flex;
				flex-direction: row;
				// grid-template-columns: 1fr 1fr;
				gap: 32px;
				// padding: 5px 0;
				width: 100%;
			}
			.form-item {
				label {
					// padding: 5px 0;
					margin-bottom: 4px;
					font-weight: 100;
					display: block;
				}
				input {
					// padding: 5px 20px;
					color: #252627;
				}
			}
		}
	}
}
.page4new {
}
.page5new {
	height: 100%;
	width: 80%;
	display: flex;
	align-items: center;
	.popup {
		width: 100%;

		.popup-heading {
			background: #f5f5f5;
			border: 0.8px solid rgba(196, 196, 196, 0.5);
			border-radius: 8px 8px 0px 0px;
			padding: 10px 27px;
		}
		.popup-content {
			padding: 10px 45px;
			border: 0.8px solid rgba(196, 196, 196, 0.5);

			.form-section {
				display: flex;
				flex-direction: column;
				font-family: "Circular Std";
				width: 100%;
				// height: 100%;
				form {
					width: 100%;
					.form-row {
						display: grid;
						grid-template-columns: 1fr 1fr;
						grid-column-gap: 40px;
						padding: 10px 0;
						width: 100%;
					}
					.form-item {
						width: 100%;
						label {
							padding: 10px 0;
							color: #655f5f;
							font-weight: 100;

							font-family: "Circular Std";
							font-style: normal;
							font-weight: 450;
							/* identical to box height */

							color: #252627;

							/* Inside auto layout */

							flex: none;
							order: 0;
							flex-grow: 0;
							display: block;
						}
						.parent-label {
							.custom-wrapper {
								display: flex;
								justify-content: flex-end;
								width: 70%;
								.custom {
									display: flex;
									visibility: hidden;
									font-style: normal;
									font-size: 70%;
									align-items: center;
									background: rgba(37, 38, 39, 0.7);
									justify-content: center;
									padding: 5px 10px;
									text-align: center;
									color: #fff;
									border-radius: 4px;
								}
							}
							.info-wrapper {
								display: flex;
								align-items: center;

								h5 {
									padding: 2% 0;
									font-family: "Circular Std";
									font-style: normal;
									font-weight: 450;
									font-size: 15px;
									line-height: 19px;
									color: #252627;
								}
								.infosvg {
									width: 16px;
									padding-left: 10px;
									align-self: center;
								}
							}
							&:hover {
								.custom-wrapper {
									.custom {
										visibility: visible;
									}
								}
							}
						}
						input,
						select {
							background: #f9fafa;
							border: 0.8px solid rgba(196, 196, 196, 0.5);
							border-radius: 4px;
							padding: 5px;
							padding-left: 20px;
							width: 100%;
							// height: 40%;

							color: #252627;
							box-sizing: border-box;
						}
						.select-wrapper {
							padding: 7.5px 0;
							padding-right: 10px;
							border: 0.8px solid rgba(196, 196, 196, 0.5);
							background: #f9fafa;
							border-radius: 4px;
						}
						select {
							border: 0;
						}
						.prompt {
							color: red;
							font-size: 70%;
							padding: 5px 0;
						}
					}
					.last-form-row {
						p {
							padding: 5% 0;
						}
					}
					.button-wrapper {
						width: 100%;
						.previous-wrapper {
							display: flex;
							align-items: center;
							display: flex;
							padding: 12px 0;
							&:hover {
								cursor: pointer;
							}
							p {
								padding-left: 7.5px;
								color: #655f5f;
							}
						}
						display: flex;
						padding: 2.5% 0;
						// padding-bottom: 5%;
						justify-content: flex-end;
						grid-gap: 5%;
						align-items: center;
						p {
							font-size: 80%;
						}
						img {
							width: 15px;
							color: pink;
						}
						.disabled {
							background: #9c9393;
							cursor: not-allowed;
						}
					}
				}
			}
		}
	}
}
.page6new {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	height: 80%;
	padding: 0 10%;
	.top {
		h5 {
			line-height: 26px;
			font-style: normal;
			font-weight: 500;
			font-size: 1.2em;
			padding: 15px 0;
			font-family: "Circular Std";
			color: #252627;
		}
		p {
			font-family: "Circular Std";
			font-style: normal;
			font-size: 15px;
			line-height: 19px;
			text-align: center;
			padding: 15px 0;
			color: #252627;
		}
	}
	.refer {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		padding: 20px;
		font-family: "Circular Std";

		// height: 50%;
		padding-top: 25%;
		// padding-bottom: 40%;
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 40px;
		padding: 20px 0;

		.input {
			background: #f9fafa;
			border: 0.8px solid rgba(196, 196, 196, 0.5);
			border-radius: 4px;
			padding: 5px;
			padding-left: 10px;
			padding-right: 0px;
			// width: 100%;
			width: 100%;
			// height: 40%;
			color: #252627;
			box-sizing: border-box;
		}
		.button-wrapper {
			display: flex;
			width: 100%;
			padding: 2.5% 0;
			padding-bottom: 7.5%;
			justify-content: flex-end;
			grid-gap: 5%;
			align-items: center;
			p {
				font-size: 80%;
			}
			img {
				width: 10px;
			}
		}
	}
}
.page6new-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	.page6new-final {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		height: 70%;
		width: 80%;
		align-items: center;
		width: 100%;
		.top {
			height: 30%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			text-align: center;
			h5 {
				font-family: "Circular Std";
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 26px;
				text-align: center;
				color: #252627;
			}
			p {
				font-family: "Circular Std";
				font-style: normal;
				font-weight: 450;
				font-size: 15px;
				line-height: 19px;
				text-align: center;
				color: #252627;
			}
		}
		.refer {
			height: 50%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 90%;

			h5 {
				font-family: "Circular Std";
				font-style: normal;
				font-weight: 500;
				font-size: 16.21px;
				line-height: 26px;
				text-align: center;
				color: #252627;
			}
			.refer-options {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 80%;
				// background-color: palevioletred;
				.top {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					p {
						width: 50%;
					}
					.active {
						padding: 7.5px 15px;
						background: #40798c;
						border: 0.8px solid rgba(196, 196, 196, 0.5);
						border-radius: 2px;
						color: #fff;
						cursor: pointer;
					}
					.inactive {
						padding: 7.5px 20px;
						color: #655f5f;

						&:hover {
							cursor: pointer;
						}
					}
				}
				.form-item {
					.input {
						background: #f9fafa;
						border: 0.8px solid rgba(196, 196, 196, 0.5);
						border-radius: 4px;
						padding: 7.5px 10px;
						font-weight: 450;
						font-size: 13.59px;
						width: 100%;

						color: #252627;
						box-sizing: border-box;
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: space-between;

						svg {
							font-size: 12px;
							cursor: pointer;
						}
					}
					.button-wrapper {
						width: 100%;
						margin-top: 0.75rem;
						.previous-wrapper {
							align-items: center;
							display: flex;
							padding: 10px 0;
							&:hover {
								cursor: pointer;
							}
							p {
								padding-left: 7.5px;
								color: #655f5f;
							}
						}
						display: flex;
						// padding: 2.5% 0;
						// padding-bottom: 5%;
						justify-content: flex-end;
						grid-gap: 5%;
						align-items: center;
						p {
							font-size: 80%;
						}
						img {
							width: 15px;
							color: pink;
						}
						.disabled {
							background: #9c9393;
							cursor: not-allowed;
						}
					}
				}
			}
		}
	}
}

.new-parent-label {
	.new-custom {
		display: flex;
		align-items: center;
		gap: 10px;
		// align-items: flex-end;
		margin-bottom: 11px;

		h5 {
			color: #252627 !important;
			font-weight: 450;
			font-size: 15px;
			line-height: 19px;
		}
		.left-custom {
			padding-right: 5px;
		}
		.right-custom {
			min-width: 20%;
			width: 50%;
		}
		div {
			.custom {
				display: flex;
				font-style: normal;
				font-size: 70%;
				align-items: center;
				background: rgba(37, 38, 39, 0.7);
				justify-content: center;
				padding: 5px 10px;
				text-align: center;
				color: #fff;
				border-radius: 4px;
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.page1new,
	.page2new,
	.page3new,
	.page4new {
		height: 100%;
		gap: 83px;
		justify-content: unset;

		.heading {
			display: flex;
			flex-direction: column;
			align-items: center;
			h5 {
				font-family: "Circular Std";
				font-style: normal;
				font-size: 18.21px;
				line-height: 26px;
				text-align: center;
				color: #252627;
				margin-bottom: 24px;
			}

			p {
				font-size: 12px;
				line-height: 15px;
				width: 75%;
			}
		}
		.form-section {
			background-color: #ffffff;
			box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
			border-radius: 4px;
			padding: 40px 32px;

			form {
				.form-rows {
					& > .form-row:nth-of-type(1),
					.form-row:nth-of-type(2),
					.form-row:nth-of-type(3) {
						margin-bottom: 23px;
					}
				}

				.form-row {
					display: flex;
					flex-direction: column;
					align-items: stretch;
					gap: 23px;
				}
				.form-item {
					// padding: 10px 0;

					input {
						padding: 7px 12px;
					}
					input,
					select {
						font-weight: 450;
						font-size: 10.5px;
						line-height: 13px;
					}

					label {
						font-weight: 450;
						font-size: 12px;
						line-height: 15px;
						color: #252627;
						display: block;
					}
					.select-wrapper {
						padding: 6px 12px;
					}
				}
				.forgot-link {
					font-size: 10.5px;
					line-height: 13px;
				}
				.button-wrapper {
					flex-direction: column-reverse;
					align-items: stretch;
					margin-top: 21px;

					.previous-wrapper {
						align-items: center;
						justify-content: center;
					}

					button {
						width: 100%;
						font-weight: 500;
						font-size: 12.8409px;
						line-height: 16px;
						padding: 0.5rem;
					}
				}
			}
		}

		.bottom {
			p,
			a {
				font-size: 12px;
				line-height: 15px;
				margin: 4px 0;
			}
		}
		.dots {
			// display: none;
			div {
				width: 4.09px;
				height: 4.09px;
			}
		}
	}

	.page1new {
		.form-section {
			form {
				.form-item {
					padding: 0;
				}
			}
		}
	}

	.page2new {
		padding-top: 40px;
		gap: 72px;
		.heading {
			font-size: 12px;
			line-height: 15px;
			margin-bottom: 8px;
			span > span {
				display: inline-block;
			}
		}
		.form-section form .form-item {
			input {
				font-size: 12px;
				line-height: 15px;
			}
			.info-wrapper {
				margin-bottom: 8px;
			}
		}

		.bottom {
			margin-bottom: 24px;
		}
	}

	.page3new,
	.page4new {
		.heading h5 {
			margin-bottom: 0;
		}

		.form-section {
			padding: 32px;
			padding-left: 31px;
			padding-top: 30px;
			form {
				.form-row {
					gap: 23px;
				}
				.button-wrapper {
					margin-top: 42px;
				}
			}
		}
	}

	.page3new,
	.page4new {
		gap: 34px;
	}

	.page6new-wrapper {
		.page6new-final {
			height: 100%;
			justify-content: flex-start;
			gap: 76px;

			.top {
				height: unset;
				gap: 20px;

				h5 {
					font-size: 16.21px;
					line-height: 26px;
				}

				p {
					font-weight: 450;
					font-size: 12px;
					line-height: 15px;
				}
			}

			.refer {
				height: unset;
				gap: 20px;
				background: #ffffff;
				box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
				border-radius: 4px;
				padding-top: 36px;
				padding-right: 27px;
				padding-left: 30px;
				padding-bottom: 80px;

				h5 {
					font-size: 12px;
					line-height: 26px;
				}

				.refer-options {
					height: unset;
					gap: 20px;
					justify-content: unset;
					.top {
						font-size: 12px;
						line-height: 26px;
						gap: 20px;
						.active {
							border-radius: 4px;
							padding: 3px;
							padding-left: 0px;
							padding-right: 0px;
						}
						.inactive {
							padding: 3px;
							padding-left: 0px;
							padding-right: 0px;
						}
					}

					.form-item {
						.input {
							font-size: 10.5px;
							line-height: 16px;
							padding-top: 7px;
							padding-bottom: 9px;
						}
						.button-wrapper {
							flex-direction: column-reverse;
							align-items: stretch;
							margin-top: 34px;

							.previous-wrapper {
								align-items: center;
								justify-content: center;
							}

							button {
								width: 100%;
								font-weight: 500;
								font-size: 12.8409px;
								line-height: 16px;
								padding: 0.5rem;
							}
						}
						.previous-wrapper {
							font-weight: 450;
							font-size: 12px;
							line-height: 15px;
						}
					}
				}
			}
		}
	}

	.new-parent-label .new-custom {
		gap: 6.5px;
		margin-bottom: 8px;
		h5 {
			color: #252627 !important;
			font-weight: 450;
			font-size: 12px;
			line-height: 15px;
		}
		img {
			width: 12px;
		}
	}
}
