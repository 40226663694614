.TripHistory {
	padding: 3.375rem 13.9vw;
	padding-left: 14.09vw;
	padding-right: 12.5vw;
	padding-bottom: calc(3.375rem + 61px);
	background-color: #ffffff;
	height: 100%;
	overflow-y: auto;

	.big-title {
		display: flex;
		flex-direction: row;

		margin-bottom: 3rem;

		h4 {
			font-size: 30px;
			font-weight: 500;
			padding-right: 10rem;
		}
		button {
			display: none;
		}
	}

	.mobile-search {
		display: none;
	}

	.desk-search {
		padding: 8px 20px;
		width: 494px;
		height: 44px;
		display: flex;
		flex-direction: row;
		gap: 44px;
		background: #f9fafa;
		border: 0.8px solid rgba(196, 196, 196, 0);
		border-radius: 13px;

		input[type="text"] {
			color: #655f5f;
			border: 0;
			font-size: 13px;
			line-height: 13px;
			width: 100%;
			padding: 4px;
			background-color: #f9fafa;
		}
	}

	h4 {
		font-weight: 500;
		font-size: 20px;
		line-height: 37px;
		color: #252627;
	}

	&__tabSection {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		gap: 1rem;
		justify-content: space-between;
		margin-bottom: 0.75rem;

		button {
			font-weight: 450;
			font-size: 13.59px;
			line-height: 18px;
		}
	}

	&__tabs {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 2rem;
		flex-wrap: wrap;
		color: #9c9393;

		.tab {
			font-size: 14px;
			line-height: 18px;
			cursor: pointer;

			&--active {
				text-decoration-line: underline;
				color: #40798c;
			}
		}
	}

	&__LoadMore {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-top: 2rem;
	}

	.HistoryEmptyState {
		&__main {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 0.25rem;
			min-height: 280px;
			text-align: center;

			.title {
				font-weight: 450;
				font-size: 18px;
				line-height: 23px;
				color: #252627;
				margin-bottom: 10px;
			}
			.desc {
				font-weight: 450;
				font-size: 13.59px;
				line-height: 17px;
				color: #655f5f;
				margin-bottom: 20px;
			}

			button {
				font-size: 13.59px;
				line-height: 20px;
				padding: 9px 18px;
			}
		}

		&__email-request {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			& > div {
				display: flex;
				flex-direction: column;
				align-items: center;
				text-align: center;
				gap: 0.5rem;
				max-width: 280px;

				font-size: 11.0258px;
				line-height: 14px;
				color: #9c9393;

				.title {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 0.75rem;
					font-size: 13.1515px;
					line-height: 17px;
					color: #655f5f;
				}

				a {
					color: #40798c;
					text-decoration: none;
				}
			}
		}
	}
}

.TripInfoDrawer {
	margin-bottom: 6px;

	.overviewOpen {
		border-radius: 8px 8px 0px 0px;
	}
	&__Overview {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		font-size: 14px;
		line-height: 18px;
		color: #655f5f;
		border: 0.5px solid rgba(196, 196, 196, 0.9);
		border-radius: 8px;
		padding: 15px 10px;
		cursor: pointer;

		.bottomDrawer {
			// display: flex;
			// flex-direction: row;
			// align-items: center;
			// justify-content: space-between;

			display: grid;
			grid-template-columns: 4fr 1fr 1fr;
			gap: 1rem;

			.left-flex {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				// width: 40%;
				gap: 25px;

				svg > path {
					// fill: #54aae4;
				}

				.plenty-text {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					gap: 10px;

					.namesNum {
						display: flex;
						flex-direction: row;
						align-items: center;
						gap: 9px;

						p {
							font-weight: 500;
							font-size: 16px;
							line-height: 20px;
							color: #252627;
						}

						span {
							width: 22px;
							height: 22px;
							border-radius: 50%;
							background: #ffeae2;
							display: grid;
							place-items: center;

							font-weight: 450;
							font-size: 12px;
							line-height: 15px;
							text-align: center;
							color: #ff7477;
						}
					}
				}

				.dot {
					color: #c4c4c4;
				}

				.title {
					color: #655f5f;
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 10px;
					font-weight: 450;
					font-size: 14px;
					line-height: 18px;
				}

				.bottom {
					display: flex;
					flex-direction: row;
					align-items: center;
					flex-wrap: wrap;
					color: #655f5f;
					gap: 10px;
					font-size: 13.59px;
					line-height: 17px;
				}
			}

			.center-flex {
				align-self: center;
				// padding-right: 4rem;
				width: max-content;

				p {
					padding: 0.5rem 0.6rem;
					border-radius: 3px;
				}

				.ticket-status {
					font-size: 13.59px;
					color: #655f5f;

					&__Open {
						background-color: #ffeaf9;

						&--processing {
							background-color: #ffefe4;
							color: #ff7a25;
						}
					}

					&__Processing {
						background-color: #ffeae2;
					}

					&__Completed {
						background-color: #e2ffee;
					}
				}
			}

			.right-flex {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				justify-content: center;
				gap: 28px;

				.actions {
					color: #56899e;
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 0.4rem;
					margin-right: 40px;

					> span {
						// transition: all 0.5s;
						&:hover {
							cursor: pointer;
							text-decoration: underline;
						}
					}
				}
				.mobile-actions {
					display: none;
				}
			}
		}
	}

	&__MoreInfo {
		background: #f9f9f9;
		border: 0.39px solid rgba(156, 147, 147, 0.6);
		border-radius: 0px;
		color: #252627;
		font-weight: 450;
		font-size: 13.59px;
		line-height: 17px;
		min-height: 200px;
		max-height: 800px;
		overflow: hidden;
		transition: all 0.5s;

		&--shut {
			height: 0;
			min-height: 0;
			max-height: 0;
			border: none;
		}

		& > div {
			// display: flex;
			// flex-direction: row;
			display: grid;
			grid-template-columns: 1fr 285px;
			align-items: flex-start;
			gap: 10vw;
			padding: 32px 60px 35px 88px;
		}

		.mobile-actions {
			display: none;
		}

		.left {
			// max-width: 55%;
			display: flex;
			flex-direction: column;
			height: 100%;
			.title {
				font-size: 15px;
				line-height: 22px;
				color: #9c9393;
				margin-bottom: 12px;
			}

			.names {
				line-height: 30px;
				margin-bottom: 21px;
			}
			.blue-link {
				background-color: #e6e6e6;
				color: #40798c;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-around;
				flex-wrap: nowrap;
				width: 32%;
				padding: 0.5rem 0.7rem 0.5rem;
				border-radius: 5px;

				> * {
					cursor: pointer;
				}
			}

			.inOut-card {
				padding: 14px;
				padding-right: 2rem;
				display: flex;
				flex-direction: column;
				align-items: stretch;
				justify-content: space-between;
				gap: 15px;
				background: rgba(229, 229, 229, 0.4);
				border-radius: 2.65382px;

				& > div {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					gap: 12px;
					color: #655f5f;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;

					.right-side {
						color: #252627;
					}
				}
			}

			.total-amount {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 1rem;
				justify-content: space-between;
				line-height: 22px;
				padding-top: 14.5px;
				padding-bottom: 11.5px;
				border-top: 0.39px solid rgba(156, 147, 147, 0.6);
				border-bottom: 0.39px solid rgba(156, 147, 147, 0.6);
				margin-top: auto;
				min-width: max-content;
				max-width: 300px;

				p {
					font-weight: 500;
					font-size: 13.59px;
					color: #252627;
				}
			}
		}

		.right {
			width: 100%;

			.actions {
				&__title {
					font-weight: 500;
					font-size: 13.59px;
					line-height: 37px;
					color: #252627;
					margin-bottom: 12px;
				}

				.action {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 1rem;
					margin-bottom: 25px;
					cursor: pointer;

					&--red {
						color: #ff0f0f;
						svg > path {
							fill: #ff0f0f;
						}
					}

					&--active {
						color: #40798c;
						svg > path {
							fill: #40798c;
						}
					}
					&--inactive {
						color: #c4c4c4;
						cursor: not-allowed;
						svg > path {
							fill: #c4c4c4;
						}
					}
				}
			}

			.open-cancel {
				display: flex;
				justify-content: space-between;
			}
		}
	}
}

.mobileMenu {
	width: 100%;
	padding: 2rem 1.7rem;
	background: #ffffff;
	border: 0.8px solid rgba(196, 196, 196, 0.5);
	box-shadow: 0px 4px 4px rgba(156, 147, 147, 0.1);
	border-radius: 8px;

	.actions {
		.action {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 1rem;
			margin-bottom: 37px;
			cursor: pointer;

			p {
				font-size: 13px;
			}

			&--red {
				color: #ff0f0f;
				svg > path {
					fill: #ff0f0f;
				}
			}

			&--active {
				color: #40798c;
				svg > path {
					fill: #40798c;
				}
			}
			&--inactive {
				color: #c4c4c4;
				cursor: not-allowed;
				svg > path {
					fill: #c4c4c4;
				}
			}
		}
		.action:last-child {
			margin-bottom: 0px;
		}
	}
}

//modals
.modalForm {
	width: 100%;

	.modalFormLabel {
		display: grid;
		width: 100%;
		gap: 4rem;
		grid-template-columns: auto auto;

		input {
			font-size: 14px;
			background: #fafafa;
			padding: 5px 0;
			border: 0.8px solid #c4c4c480;
			border-radius: 9px;
			padding: 15px;
			padding-left: 20px;
			width: 100%;
			color: #252627;
			box-sizing: border-box;
			margin-top: 0.75rem;
		}
	}

	.formLabel {
		display: block;
		margin-bottom: 1.5rem;
		font-size: 14px;
		color: #252627;

		&--time {
			color: #9c9393;
		}

		&--strikethrough {
			text-decoration: line-through;
		}
	}

	.options {
		display: flex;
		flex-direction: column;

		.formLabel {
			margin-bottom: 0.8rem;
		}

		.selection {
			font-size: 14px;
			color: #252627;
			padding: 5px 10px;
			background: #fafafa;
			border-radius: 9px;
		}
	}

	.changeTicket {
		.changeType {
			margin-bottom: 0.8rem;
			font-size: 14px;
			color: #252627;
		}

		.flightsSelectedEdits {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			gap: 1.5rem;

			.flightNum {
				font-weight: 700;
				margin-bottom: 0.5rem;
			}
		}

		.boxes {
			display: grid;
			width: 100%;
			gap: 2rem;
			grid-template-columns: auto auto;
			margin-bottom: 1.8rem;
		}

		.custom-rmdp-input {
			padding: 1rem 1.2rem;
			border: 0.8px solid rgba(196, 196, 196, 0.5);
			border-radius: 9px;
			margin-bottom: 1.5rem;

			display: grid;
			grid-template-columns: 1rem 1fr;
			gap: 10px;
			cursor: pointer;

			p {
				font-size: 13.52px;
				color: #9c9393;
			}
		}

		.innerBox {
			border: 0.8px solid rgba(196, 196, 196, 0.5);
			border-radius: 9px;
			padding: 2rem 1.5rem;
			margin-bottom: 1.5rem;

			.boxes {
				margin-bottom: 0rem;
				> div {
					display: flex;
					align-items: center;
					gap: 10px;

					> input {
						margin-right: 10px;
					}

					.innerLabels {
						display: flex;
						flex-direction: column;
						gap: 0;

						.formLabel {
							margin-bottom: 0;
						}
					}
				}
			}

			.newDate {
				margin-bottom: 1.8rem;
			}
		}

		.datePicker {
			.innerBox {
				display: flex;
				gap: 15px;
				padding: 1.05rem 1.2rem;

				p {
					font-size: 13.52px;
					color: #9c9393;
				}
			}
		}

		.changeRoute {
			.innerBox {
				padding: 0 1.2rem;
				margin: 0;

				.selection {
					font-size: 13.5px;
					color: #9c9393;
					width: 100%;
				}
			}
		}

		.formLabel {
			display: inline;
			margin-left: 10px;
		}
	}

	.textArea {
		display: flex;
		flex-direction: column;

		.formLabel {
			margin: 1.5rem 0 0.8rem;
		}

		.boxes {
			display: flex;
			justify-content: space-between;
			margin: 0;

			p {
				color: #655f5f;
				font-size: 13px;
			}
		}

		.textBox {
			padding: 20px;
			padding-bottom: 50px;
			box-sizing: border-box;
			border: 0.8px solid rgba(196, 196, 196, 0.5);
			border-radius: 9px;
			background-color: #fafafa;
			font-size: 13px;
			resize: none;
			margin-bottom: 2rem;
			line-height: 16px;
			background-image: url("../../components/pieces/linesIcon.svg");
			background-repeat: no-repeat, repeat;
			background-position: right 1.3rem top 90%, 0 0;
			background-size: 23px, 23px;

			&::placeholder {
				color: #c4c4c4;
			}
		}
	}

	.form-btn {
		font-size: 14px;
		line-height: 15px;
		padding: 15px 19px;
		width: 100%;
	}

	.form-para {
		width: 100%;
		text-align: justify;
		color: #9c9393;
		font-size: 13px;
		background-color: #f9fafa;
		padding: 1rem;
		margin-bottom: 1rem;
		line-height: 16.44px;

		a {
			color: #655f5f;
			text-decoration: underline;
		}
	}
}

.TicketSuccess {
	color: #655f5f;
	font-weight: 450;
	font-size: 14.01px;
	line-height: 18px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: 61px;
	padding-top: 58px;
}

.EmailModal {
	width: 381px;
	padding: 3rem 2rem;
	padding-bottom: 2.3rem;
	background: #ffffff;
	border: 0.8px solid rgba(196, 196, 196, 0.5);
	box-shadow: 0px 4px 4px rgba(156, 147, 147, 0.1);
	border-radius: 4px;

	label {
		color: #252627;
		display: block;
	}

	input {
		width: 100%;
		margin-top: 1.5rem;
		padding: 1rem;
		color: #a8a0a0;
		background-color: #ffffff;
		border-color: #c4c4c480;
		font-size: 0.75rem;
	}

	.email-btn {
		text-align: center;
		margin-top: 20px;

		button {
			font-size: 12.84px;
			padding: 14px 15;
			height: 36px;
		}
	}
}

.mobile-hide {
	display: none;
}

@media screen and (max-width: 1569px) {
	.TripInfoDrawer {
		&__MoreInfo {
			.left {
				.blue-link {
					width: max-content;
				}
			}
		}
	}
}

@media screen and (max-width: 1427px) {
	.TripInfoDrawer {
		&__MoreInfo {
			.left {
				.blue-link {
					// width: 40%;
					width: max-content;
				}
			}
		}
	}
}

@media screen and (max-width: 1129px) {
	.TripInfoDrawer {
		&__MoreInfo {
			.left {
				.blue-link {
					// width: 45%;
					width: max-content;
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.TripHistory {
		padding: 1.375rem 1.5rem;
		padding-bottom: 85px;

		h4 {
			padding-right: 0;
		}
	}
}

@media screen and (max-width: 930px) {
	.desktop-hide {
		// display: none;
	}
	.mobile-hide {
		display: inherit;
	}

	.TripHistory {
		padding-bottom: 85px;
		.big-title {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 1rem;
			justify-content: space-between;
			margin-bottom: 22px;
			min-height: 50px;

			h4 {
				font-weight: 500;
				font-size: 24px;
				line-height: 37px;
				padding-right: 0;
			}

			button {
				display: inline-block;
				font-size: 14px;
				line-height: 33px;
				border-radius: 4.4px;
				padding: 5px 25px;
			}
		}

		.desk-search {
			display: none;
		}

		.mobile-search {
			padding: 8px 20px;
			width: 100%;
			display: flex;
			flex-direction: row;
			gap: 44px;
			background: #f9fafa;
			border: 0.8px solid rgba(196, 196, 196, 0);
			border-radius: 13px;
			margin: 30px 0;

			input[type="text"] {
				color: #655f5f;
				border: 0;
				font-size: 13px;
				line-height: 13px;
				width: 100%;
				padding: 4px;
				background-color: #f9fafa;
			}
		}

		&__tabSectionMobile {
			margin-bottom: 2rem;
			.mobileDropDown {
				font-size: 13px;
				color: #40798c;
				padding: 0 10px;
				background: #ffffff;
				border-radius: 6px;
				border: 1px solid rgba(196, 196, 196, 0.5);
				min-width: 40%;
			}
		}

		&__tabSection {
			button {
				display: none;
			}
		}

		&__tabs {
			display: none;

			.tab {
				display: none;
			}
		}
	}

	.TripInfoDrawer {
		&__Overview {
			padding: 10px;
			gap: 0.5rem;
			.topDrawer {
				align-self: flex-end;

				.open-ticket {
					width: 110px;
					color: #ffffff;
					font-size: 14px;
					font-weight: 400;
					background: #ffefe4;
					border-radius: 2.65382px 0 0 2.65382px;
					padding: 3px 14px;
					padding-right: 10px;
					cursor: pointer;

					&--processing {
						background: #ffefe4;
						color: #ff7a25;
					}
				}
			}

			.bottomDrawer {
				grid-template-columns: 1fr;
				.left-flex {
					width: 100%;
					gap: 14px;

					.bottom {
						gap: 10px;
						font-size: 12px;
						line-height: 15px;
						color: #655f5f;
					}
					.title {
						gap: 1.5rem;
						font-weight: 500;
						font-size: 12px;
						line-height: 15px;
					}
					.plenty-text {
						gap: 9px;
					}
				}
				.right-flex {
					display: none;
					.actions {
						display: none;
					}
					.mobile-actions {
						color: #40798c;
						display: flex;
						flex-direction: row;
						align-items: center;
						gap: 1rem;
						margin-right: 1rem;
					}
					.mobile-actions-inner {
						background-color: #fff;
						padding: 100px;
						width: 100%;
					}
				}
			}
		}

		&__MoreInfo {
			font-weight: 450;
			font-size: 12px;
			line-height: 15px;
			& > div {
				display: flex;
				flex-direction: column;
				gap: 19.28px;
				padding: 1.5rem 1rem;
			}

			.mobile-actions {
				display: block;
				padding-top: 0;
				padding-bottom: 0;
				& > div {
					width: 100%;
					display: flex;
					flex-direction: row;
					align-items: flex-end;
					gap: 1.5rem;
					justify-content: flex-end;
					border-bottom: 0.39px solid rgba(156, 147, 147, 0.6);
					padding-top: 24px;
					padding-bottom: 20px;

					svg {
						color: #655f5f;
						cursor: pointer;
						font-size: 30px;
					}
				}

				.action {
					&--red {
						path {
							fill: #ff0f0f;
						}
					}

					&--active {
						path {
							fill: #40798c;
						}
					}
					&--inactive {
						cursor: not-allowed;
						path {
							fill: #c4c4c4;
						}
					}
				}
			}

			.left {
				max-width: unset;
				width: 100%;
				.blue-link {
					width: max-content;
					// width: 80%;
					justify-content: space-around;
					margin-bottom: 0;
				}
				.inOut-card {
					display: none;
				}
				.names {
					margin-bottom: 27px;
				}
				.total-amount {
					margin-bottom: 0;
					padding-top: 16px;
					padding-bottom: 12px;
					margin-top: 14px;
					max-width: unset;
					width: 100%;
				}
			}

			.right {
				.actions {
					display: none;
				}
			}
		}
	}

	.modalForm {
		display: flex;
		flex-direction: column;
		width: 100%;

		.modalFormLabel {
			display: flex;
			flex-direction: column;
			gap: 0;
		}

		.changeTicket {
			.changeType {
				margin-top: 1rem;
				margin-bottom: 1rem;
			}
			.boxes {
				padding-left: 10px;
				display: flex;
				flex-direction: column;
				align-items: stretch;
				gap: 1rem;
			}

			.innerBox {
				padding: 2rem 1.5rem;
				margin-bottom: 1.5rem;

				.boxes {
					margin-bottom: 0rem;
					> div {
						display: flex;
						align-items: center;
						gap: 12px;
					}
					.menu-button {
						padding: 0.75rem;
					}
				}
			}

			.datePicker {
				.innerBox:nth-child(1) {
					margin-bottom: 0;
				}
			}

			.changeRoute {
				.innerBox {
					padding: 0 1.2rem;
					margin: 0;
				}
			}

			.textArea {
				.boxes {
					padding: 0 5px 0 0;
					flex-direction: row;
				}
			}
		}
	}

	.EmailModal,
	.modalForm {
		button {
			font-size: 12px;
			line-height: 15px;
			padding: 16px 21px;
			height: 53px;
		}
	}
}

@media screen and (max-width: 486px) {
	.modalForm {
		width: 100%;
		display: flex;
		flex-direction: column;
		width: unset;
	}

	.EmailModal {
		width: 100%;
		padding: 3rem 2rem;
		padding-bottom: 2.3rem;
		background: #ffffff;
		border: 0.8px solid rgba(196, 196, 196, 0.5);
		box-shadow: 0px 4px 4px rgba(156, 147, 147, 0.1);
		border-radius: 4px;

		label {
			color: #252627;
			display: block;
		}

		input {
			width: 100%;
			margin-top: 1.5rem;
			padding: 1rem;
			color: #a8a0a0;
			background-color: #ffffff;
			border-color: #c4c4c480;
			font-size: 0.75rem;
		}

		.email-btn {
			text-align: center;
			margin-top: 20px;

			button {
				font-size: 12.84px;
				padding: 14px 15;
				height: 26px;
			}
		}
	}
}
