.parent {
    // width: 100%;
    min-width: 89px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #243256;
    border-radius: 3.85px;
    padding: 7.5px 0;

    .loader {
        border: 2px solid transparent;
        border-radius: 50%;
        border-top: 2px solid #d9d9d9;
        width: 10px;
        height: 13px;
        -webkit-animation: spin 0.8s linear infinite; /* Safari */
        animation: spin 0.8s linear infinite;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
