.SummarySkeleton {
	display: grid;
	grid-template-columns: 1fr 43.75vw;
	gap: 65px;
	height: 100%;

	&__left {
		padding-top: 56px;
		padding-left: 14.09vw;
		padding-bottom: 30px;

		display: grid;
		grid-template-rows: repeat(5, 1fr);
		gap: 2.2rem;

		.circle {
		}

		& > * {
			display: block;
			height: 100% !important;
		}
	}

	& > :last-child {
		// display: block;
		height: 100%;
	}
}

@media screen and (max-width: 1024px) {
	.SummarySkeleton {
		display: grid;
		grid-template-columns: unset;
		grid-template-rows: 1fr 7fr;

		padding: 1.5rem;
		gap: 1.5rem;

		&__left {
			padding-top: 0;
			padding-left: 0;
			padding-bottom: 0;
			gap: 1.2rem;
		}
	}
}
