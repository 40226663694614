.ReportingPage {
	padding: 3.375rem 13.9vw;
	padding-left: 14.09vw;
	padding-right: 12.5vw;

	display: grid;
	grid-template-columns: 1fr 342px;
	gap: 27px;
	padding-bottom: calc(3.375rem + 61px);

	.page-title,
	.mobile-cards,
	.mobile-activities_filters {
		display: none;
	}

	.balanceCard {
		background: #ffffff;
		border: 0.8px solid rgba(196, 196, 196, 0.5);
		border-radius: 0.5rem;
		padding: 0;

		&__creditCards {
			height: 240px;
			background: url("../../../public/images/cards-bg.png") no-repeat;
			background-size: contain;
			overflow: hidden;
			border-top-left-radius: 0.5rem;
			border-top-right-radius: 0.5rem;
			padding: 0 32px;
			padding-top: 30px;

			.cards {
				width: 100%;
				position: relative;
			}

			.card {
				font-weight: 450;
				font-size: 14.9459px;
				line-height: 19px;
				color: #ffffff;
				backdrop-filter: blur(47.9595px);
				height: 158px;
				width: 100%;
				position: absolute;
				border-radius: 14.0443px;
				padding: 14px 17px;
				padding-top: 21px;

				display: flex;
				flex-direction: column;
				align-items: stretch;
				justify-content: space-between;
				transform: scale(0.9);
				margin-top: 40px;
				cursor: pointer;
				transition: transform 0.4s;

				&:hover {
					transform: scale(0.95);
				}

				&--active {
					transform: scale(1);
					margin-top: 0;
					z-index: 2;
					&:hover {
						transform: scale(1);
					}
				}

				.card_number {
					text-align: right;
					font-size: 11.7432px;
					line-height: 15px;
					margin-bottom: 2px;
				}

				.value {
					font-weight: 500;
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 1rem;
					justify-content: space-between;
				}
			}
		}

		&__body {
			padding: 12px 28px;
			padding-bottom: 30px;
			.title {
				font-weight: 500;
				font-size: 14px;
				line-height: 18px;
				color: #252627;
				margin-bottom: 17px;
			}

			.filters {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				gap: 0.75rem;
				margin-bottom: 1rem;
			}

			.activities {
				display: flex;
				flex-direction: column;
				align-items: stretch;
				gap: 1rem;
			}

			.activity {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;

				font-weight: 450;
				font-size: 12.7483px;
				line-height: 16px;
				color: #252627;

				> :first-child {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 24px;
				}

				.icon-wrapper {
					width: 48px;
					height: 48px;
					display: grid;
					place-items: center;
					background: #40798c;
					border-radius: 0.25rem;
				}
			}
		}
	}

	.charts {
		& > * {
			// height: 290px;
			// width: 100%;
			margin-bottom: 16px;
		}
	}
}

.ReportingTable {
	background: #ffffff;
	border: 0.8px solid rgba(196, 196, 196, 0.5);
	border-radius: 7.23013px;
	font-size: 14px;
	line-height: 18px;
	font-weight: 450;
	color: #655f5f;
	padding: 22px 32px;
	margin-bottom: 0.5rem;

	.title {
		font-weight: 500;
		font-size: 15px;
		line-height: 19px;
		color: #252627;
	}

	.second {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 1rem;
		justify-content: space-between;
		color: #9c9393;
		margin-bottom: 22px;

		.MuiBox-root {
			display: none;
		}
	}

	.top {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 1rem;
		justify-content: space-between;
		margin-bottom: 0.5rem;
	}

	.main-content {
		// display: flex;
		// flex-direction: column;
		// align-items: stretch;

		.row {
			margin-bottom: 15px;
			display: grid;
			grid-template-columns: 2.5fr 2fr 2fr 16px;
			// gap: 5.6vw;
		}

		.line-grid {
			display: flex;
		}

		.line {
			width: 84px;
			background: #c4c4c4;
			height: 3px;
			margin-top: auto;
		}
		.data2 {
			// padding-left: 5vw;
		}
	}
}

@media screen and (max-width: 1024px) {
	.ReportingPage {
		padding: 1.375rem 1.5rem;
		padding-bottom: 85px;
	}
}

@media screen and (max-width: 780px) {
	.ReportingPage {
		display: block;
		padding-bottom: 85px;
		.page-title {
			display: block;
			font-weight: 500;
			font-size: 24px;
			line-height: 30px;
			margin-bottom: 2rem;

			color: #000000;
		}

		.mobile-cards {
			display: block;
			width: 100%;
			position: relative;

			.card {
				font-weight: 450;
				font-size: 14.9459px;
				line-height: 19px;
				color: #ffffff;
				backdrop-filter: blur(47.9595px);
				height: 158px;
				width: 100%;
				border-radius: 14.0443px;
				padding: 14px 17px;
				padding-top: 21px;

				display: flex;
				flex-direction: column;
				align-items: stretch;
				justify-content: space-between;

				.card_number {
					text-align: right;
					font-size: 11.7432px;
					line-height: 15px;
					margin-bottom: 2px;
				}

				.value {
					font-weight: 500;
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 1rem;
					justify-content: space-between;
				}
			}
			.alice-carousel {
				.alice-carousel__dots {
					margin-top: 10px;
					margin-bottom: 36px;
				}

				.alice-carousel__prev-btn,
				.alice-carousel__next-btn {
					display: none;
				}
			}
		}

		.mobile-activities_filters {
			display: block;
			margin-bottom: 1rem;
			.title {
				font-weight: 500;
				font-size: 14px;
				line-height: 18px;
				color: #252627;
				margin-bottom: 17px;
			}

			.filters {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				gap: 0.75rem;
				margin-bottom: 1rem;
			}

			.activitiesCard {
				background: #ffffff;
				border: 1px solid rgba(196, 196, 196, 0.5);
				border-radius: 10px;
				padding: 13px 12px 32px 18px;
			}

			.activities {
				display: flex;
				flex-direction: column;
				align-items: stretch;
				gap: 9px;
			}

			.activity {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;

				font-weight: 450;
				font-size: 12.7483px;
				line-height: 16px;
				color: #252627;
				.grey {
					color: #655f5f;
				}

				> :first-child {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 10px;
				}

				.icon-wrapper {
					width: 36px;
					height: 36px;
					display: grid;
					place-items: center;
					background: #40798c;
					border-radius: 0.25rem;
				}
			}
		}

		.balanceCard {
			display: none;
		}
	}

	.ReportingTable {
		font-weight: 450;
		font-size: 13px;
		line-height: 16px;
		padding: 0.75rem 0.75rem 1rem 1.4rem;

		.top {
			margin-bottom: 1rem;
			.MuiBox-root {
				display: none;
			}
		}

		.second {
			margin-bottom: 1rem;
			.MuiBox-root {
				display: block;
			}
		}

		.main-content {
			// display: flex;
			// flex-direction: column;
			// align-items: stretch;

			.row {
				margin-bottom: 13px;
				display: grid;
				grid-template-columns: 1.3fr 0.9fr 1fr 16px;
				// gap: 5.6vw;
			}

			.line {
				width: 64px;
			}
		}
	}
}
