.SettingsPage {
	padding: 3.375rem 14.02vw;
	padding-right: 21.11vw;
	padding-bottom: calc(3.375rem + 61px);
	background-color: #ffffff;
	min-height: 100%;

	.settings-grid {
		display: grid;
		grid-template-columns: 210px 1fr;
		gap: 2rem;
	}

	.paddedTop {
		.CustomDropdown,
		.UploadPhoto {
			display: none;
		}

		.CustomDropdown {
			input {
				margin-top: unset;
				border: 0.8px solid rgba(196, 196, 196, 0.5);
			}
		}
	}

	h3 {
		font-weight: 500;
		font-size: 20px;
		line-height: 37px;
		color: #252627;
		margin-bottom: 0.5rem;
	}

	label {
		display: block;
		margin-bottom: 30px;
		font-weight: 450;
		font-size: 14.01px;
		line-height: 18px;
		color: #655f5f;
	}

	input[type="text"],
	input[type="email"],
	input[type="password"],
	textarea {
		background: #f9fafa;
		padding: 5px 0;
		border: 0.8px solid rgba(196, 196, 196, 0.5);
		border-radius: 4px;
		padding: 10px;
		padding-left: 20px;
		width: 100%;
		color: #252627;
		box-sizing: border-box;
		margin-top: 0.75rem;
		resize: none;
	}

	input:disabled {
		background-color: #e6e6e6;
		color: #9c9393;
	}

	aside {
		ul {
			list-style-type: none;

			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 1.5rem;
			font-weight: 450;
			font-size: 14.01px;
			line-height: 18px;
			color: #9c9393;
			margin-top: 20px;

			.sideItem {
				cursor: pointer;

				&--active {
					color: #40798c;
					text-decoration-line: underline;
				}
			}
		}
	}

	.protect-title {
		line-height: 25px;
		margin-bottom: 30px;
	}

	.SettingsCrux {
		display: grid;
		grid-template-columns: 1fr 120px;
		gap: 4.861vw;

		&__form {
			width: 100%;

			.CustomDropdown__drop--open {
				max-height: 150px;
				overflow-y: scroll;
			}

			.CustomDropdown input {
				margin-top: unset;
				border: 0.8px solid rgba(196, 196, 196, 0.5);
			}
		}

		&__main {
			.topbar {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 20px;

				.search-div {
					display: flex;
					flex-direction: row;
					align-items: center;
					background: #f9fafa;
					border: 0.8px solid rgba(196, 196, 196, 0.5);
					border-radius: 50px;
					padding: 7px 11px;

					svg {
						width: 20px;
						height: 20px;

						> path {
							fill: #40798c;
						}
					}

					input {
						border: 0;
						outline: 0;
						background-color: transparent;
					}
				}
			}
			.submit-div {
				display: flex;
				margin-top: 60px;
				button {
					margin-left: auto;
					height: 36px;
				}
			}
		}

		&__LoadMore {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			margin-top: 2rem;
		}

		.notifications {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 4.94vw;
			font-weight: 450;
			font-size: 14.01px;
			line-height: 18px;
			color: #252627;

			.title {
				margin-bottom: 0.75rem;
				color: #655f5f;
			}
		}

		.card {
			padding: 29px 75px 23px 43px;
			background: #f9fafa;
			border: 0.8px solid rgba(196, 196, 196, 0.5);
			border-radius: 3px;
			margin-bottom: 18px;

			.title {
				font-weight: 450;
				font-size: 20px;
				line-height: 25px;
				margin-bottom: 2rem;
			}

			.desc {
				font-weight: 450;
				font-size: 14.01px;
				line-height: 22px;
				color: #655f5f;
				margin-bottom: 30px;
			}
		}

		.banner {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 26.5px;
			padding: 14px 41px;
			color: #655f5f;
			background: rgba(244, 203, 211, 0.4);
			border: 0.8px solid rgba(196, 196, 196, 0.5);
			border-radius: 3px;
			margin-bottom: 2rem;

			svg {
				width: 26.5px;
				height: 26.5px;
				color: #f15c79;
			}
		}

		.saved-cards {
			.title {
				font-weight: 450;
				font-size: 20px;
				line-height: 25px;
				color: #252627;
				margin-bottom: 9px;
			}
		}

		.cardItem {
			display: grid;
			grid-template-columns: 60px 1fr;
			gap: 14px;
			padding: 26px 43px 26px 17px;
			background: #ffffff;
			border: 0.8px solid rgba(196, 196, 196, 0.5);
			box-shadow: 0px 2px 0.5px rgba(101, 95, 95, 0.1);
			border-radius: 3px;
			margin-bottom: 18px;

			&:hover {
				svg {
					opacity: 1;
					cursor: pointer;
				}
			}

			svg {
				transition: opacity 0.3s;
				opacity: 0;
				// cursor: pointer;
			}

			> div {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				font-weight: 450;
				font-size: 14.01px;
				line-height: 22px;
				color: #655f5f;

				.main-text {
					color: #252627;
				}
			}
		}
	}

	.travellers {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		gap: 0.5rem;
	}

	.TravellerRow {
		background: #ffffff;
		border: 0.8px solid rgba(196, 196, 196, 0.5);
		border-radius: 3px;
		font-size: 14.01px;
		line-height: 18px;
		padding: 0.5rem 1.25rem;
		padding-right: 1rem;

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		gap: 20px;

		&__info {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 19px;
			color: #252627;
			img {
				width: 32px;
				border-radius: 50%;
			}
		}

		&__actions {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 1rem;
			color: #9c9393;

			svg {
				cursor: pointer;
			}

			> * {
				cursor: pointer;
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.SettingsPage {
		padding: 3.375rem 1.5rem;
	}
}

@media screen and (max-width: 780px) {
	.SettingsPage {
		padding: 3.375rem 10px;
		padding-bottom: calc(3.375rem + 61px);
		background-color: #f9fafa;
		min-height: 100%;

		.settings-grid {
			display: block;
		}

		.paddedTop {
			padding: 0 17px;
			margin-bottom: 22px;

			&__titleImg {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 18px;
			}

			.CustomDropdown {
				display: block;

				&__box {
					background-color: #ffffff;
				}
			}
			.UploadPhoto {
				display: block;
			}
		}

		h3 {
			font-weight: 450;
			font-size: 16px;
			line-height: 37px;
			margin-bottom: 0;
		}

		label {
			margin-bottom: 25px;
			font-size: 13px;
			line-height: 16px;
		}

		input[type="text"],
		input[type="email"],
		input[type="password"],
		textarea {
			padding: 11px;
			padding-left: 19px;
			margin-top: 7px;
		}

		aside {
			display: none;
		}

		.SettingsCrux {
			display: block;
			background-color: #ffffff;
			padding: 18px 16px;
			border: 0.8px solid rgba(196, 196, 196, 0.5);
			border-radius: 4px;

			&__form {
				width: 100%;

				.CustomDropdown__drop--open {
					max-height: 150px;
					overflow-y: scroll;
				}
			}

			&__main {
				.topbar {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 20px;
					gap: 24px;

					.search-div {
						display: flex;
						flex-direction: row;
						align-items: center;
						background: #f9fafa;
						border: 0.8px solid rgba(196, 196, 196, 0.5);
						border-radius: 50px;
						padding: 7px 11px;

						svg {
							width: 20px;
							height: 20px;

							> path {
								fill: #40798c;
							}
						}

						input {
							border: 0;
							outline: 0;
							background-color: transparent;
							max-width: 120px;
						}
					}

					button {
						padding: 0.5rem 1rem;
						font-weight: 500;
						font-size: 10.8649px;
						line-height: 14px;
						min-width: max-content;
					}
				}
				.submit-div {
					display: block;
					margin-top: 30px;
					button {
						width: 100%;
						font-weight: 450;
						font-size: 12.8409px;
						line-height: 16px;
						height: 36px;
					}
				}
			}

			&__aside {
				display: none;
			}

			.notifications {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 4.94vw;
				font-weight: 450;
				font-size: 14.01px;
				line-height: 18px;
				color: #252627;

				.title {
					margin-bottom: 0.75rem;
					color: #655f5f;
				}
			}

			.card {
				padding: 29px 75px 23px 43px;
				background: #f9fafa;
				border: 0.8px solid rgba(196, 196, 196, 0.5);
				border-radius: 3px;
				margin-bottom: 18px;

				.title {
					font-weight: 450;
					font-size: 20px;
					line-height: 25px;
					margin-bottom: 2rem;
				}

				.desc {
					font-weight: 450;
					font-size: 14.01px;
					line-height: 22px;
					color: #655f5f;
					margin-bottom: 30px;
				}
			}

			.banner {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 26.5px;
				padding: 14px 41px;
				color: #655f5f;
				background: rgba(244, 203, 211, 0.4);
				border: 0.8px solid rgba(196, 196, 196, 0.5);
				border-radius: 3px;
				margin-bottom: 2rem;

				svg {
					width: 26.5px;
					height: 26.5px;
					color: #f15c79;
				}
			}

			.saved-cards {
				.title {
					font-weight: 450;
					font-size: 20px;
					line-height: 25px;
					color: #252627;
					margin-bottom: 9px;
				}
			}

			.cardItem {
				display: grid;
				grid-template-columns: 60px 1fr;
				gap: 14px;
				padding: 26px 43px 26px 17px;
				background: #ffffff;
				border: 0.8px solid rgba(196, 196, 196, 0.5);
				box-shadow: 0px 2px 0.5px rgba(101, 95, 95, 0.1);
				border-radius: 3px;
				margin-bottom: 18px;

				&:hover {
					svg {
						opacity: 1;
						cursor: pointer;
					}
				}

				svg {
					transition: opacity 0.3s;
					opacity: 0;
					// cursor: pointer;
				}

				> div {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					font-weight: 450;
					font-size: 14.01px;
					line-height: 22px;
					color: #655f5f;

					.main-text {
						color: #252627;
					}
				}
			}
		}
	}
}
