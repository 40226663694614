.PhoneDropdown {
	display: grid;
	grid-template-columns: 65px 1fr;

	.CustomDropdown {
		// max-width: 80px;
		min-width: unset !important;
		&__box {
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;
			border-right: 0;
			min-width: unset !important;
			padding-right: 5px;

			input {
				border-top-right-radius: 0px !important;
				border-bottom-right-radius: 0px !important;
				background-color: #f9fafa !important;
			}
			svg {
				background-color: #f9fafa;
			}
		}

		&__drop {
			width: 230px;
		}
	}

	&__textInput {
		border-top-left-radius: 0px !important;
		border-bottom-left-radius: 0px !important;
	}
}
