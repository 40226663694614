.ButtonDropdown {
	position: relative;
	&__button {
		padding: 10px 20px;
		border-radius: 6px;
		font-weight: 450;
		font-size: 14px;
		line-height: 18px;
		background-color: transparent;

		&--active {
			color: #40798c;
			border: 0.5px solid #40798c;
			cursor: pointer;
		}

		&--inActive {
			color: #c4c4c4;
			border: 0.5px solid #c4c4c4;
			cursor: not-allowed;
		}

		svg {
			color: #40798c;
		}
	}

	&__drop {
		height: 0;
		overflow: hidden;
		font-weight: 500;
		font-size: 13.59px;
		line-height: 17px;
		box-shadow: 0px 0px 5px 0px rgba(64, 121, 140, 0.25);
		// box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
		border-radius: 2.57px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		background-color: #ffffff;
		padding: 5px;

		position: absolute;
		top: 95%;
		left: 0;
		width: 100%;
		z-index: 1400;
		opacity: 0;
		transition: all 0.3s;

		&--open {
			height: max-content;
			min-width: 220px;
			top: calc(100% + 7px);
			opacity: 1;
		}

		&__item {
			padding: 0.5rem;
			transition: background 0.3s;
			display: flex;
			flex-direction: column;
			align-items: stretch;
			gap: 3px;
			color: #252627;
			background: #ffffff;

			&:hover {
				cursor: pointer;
				background: #f5f5f5;
			}

			&__title {
				color: #655f5f;
			}

			&__desc {
				color: #9c9393;
			}
		}
	}
}
