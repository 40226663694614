.AuthLayout {
	// height: 50%;
	// max-height: 418px;
	// position: fixed;
	// top: 0;
	// right: 0;
	// left: 0;
	// background: url("../resource/img/Onboarding-background.svg");
	background-repeat: no-repeat;
	background-size: 100% auto;
	position: relative;
	padding-bottom: 61px;

	&__body {
		padding: 10vh 13.9vw;
		padding-left: 14.09vw;
		padding-right: 12.5vw;
	}

	.half-bg {
		position: absolute;
		top: 0;
		width: 100%;
		// z-index: -1;
	}
}

.AuthCard {
	display: flex;
	// height: 75vh;
	height: 578px;
	border-radius: 10px;
	overflow: hidden;
	// box-shadow: -14px -3px 77px -8px rgba(78, 76, 76, 0.75);
	min-height: 500px;
	max-height: 580px;
	max-width: 980px;
	margin-left: auto;
	margin-right: auto;
	box-shadow: 0px 3.54529px 3.54529px rgba(156, 147, 147, 0.1);
	position: relative;
	z-index: 1;

	&__left {
		background: url("../resource/img/register-circles.svg");
		background-color: #243256;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		width: 42%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		.up-left {
			height: 81%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 66px;
			color: #f5f5f5;
			display: flex;
			flex-direction: column;
			// align-items: center;
			padding-left: 20.2%;
			padding-bottom: 0.5rem;

			h4 {
				font-family: "Circular Std";
				font-style: normal;
				font-weight: 700;
				font-size: 27px;
				line-height: 35px;
				max-width: 80%;
				padding-left: 5px;
				color: #f5f5f5;
			}
			.left-check {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 1rem;
				width: 80%;
				p {
					font-family: "Circular Std";
					font-style: normal;
					font-weight: 450;
					font-size: 18.4052px;
					line-height: 23px;
					text-align: left;
				}
				img {
					width: 31px;
				}
			}
		}
		.down-left {
			height: 19%;
			width: 100%;
			background: #40798c;
			display: flex;
			justify-content: flex-end;
			padding: 7px 24px;
			img {
				// padding: 10px 0;
				// padding-right: 10px;
			}
		}
	}

	&__right {
		padding: 5.32%;
		padding-bottom: 35px;
		background-color: #fff;
		width: 58%;
		display: flex;
		align-items: stretch;
	}
}

.SimpleModalLayout {
	width: 90%;
	border-radius: 17px;
	margin: auto;
	overflow: hidden;
	position: relative;

	&__title {
		font-size: 20px;
		padding: 36px 46px;
		padding-bottom: 0;
		background: #ffffff;
	}

	&__para {
		font-size: 14px;
		color: #655f5f;
		padding: 10px 46px;
		background: #ffffff;
	}

	&__body {
		background-color: #ffffff;
		padding: 36px 46px;
		padding-top: 10px;
		max-height: 80vh;
		overflow: auto;
		scrollbar-width: thin;
	}

	.close-btn {
		margin: 16px 25px;
		font-size: 20px;
		color: #c4c4c4;
		background-color: rgba(255, 255, 255, 0);
		border: none;
		position: absolute;
		top: 10px;
		right: 10px;
		padding: 5px 7px;
	}

	.close-btn:hover {
		color: #655f5f;
		cursor: pointer;
	}
}

.ConfirmationLayout {
	display: grid;
	grid-template-columns: 1fr 43.75vw;
	gap: 0;

	padding: 0;

	background-color: #ffffff;

	&__left {
		padding-top: 56px;
		padding-left: 14.09vw;
		padding-bottom: 100px;
		padding-right: 65px;
		height: calc(100vh - 61px);
		overflow: scroll;

		&::-webkit-scrollbar {
			display: none;
		}
		-ms-overflow-style: none; /* Internet Explorer 10+ */
		scrollbar-width: none;
	}

	&__right {
		background-color: #f9fafa;
		padding: 36px;
		padding-right: 12.6vw;
	}
}

@media screen and (max-width: 1024px) {
	.AuthLayout {
		padding: 2.815rem 2rem;
		padding-bottom: 85px;
		background: #f9fafa;
		min-height: 100%;
		position: relative;
		max-height: unset;
		// height: unset;
		.half-bg {
			display: none;
		}

		&__body {
			padding: 0;
		}
	}

	.SimpleModalLayout {
		width: 100%;

		&__title {
			padding: 36px 26px;
			padding-bottom: 0;
		}

		&__para {
			padding: 10px 26px 13px 26px;
		}

		&__body {
			padding: 36px 26px;
			padding-top: 0;
		}

		.close-btn {
			top: 15px;
			right: 10px;
		}
	}

	.AuthCard {
		box-shadow: unset;
		max-height: unset;
		height: max-content;
		overflow: auto;
		&__left {
			display: none;
		}

		&__right {
			width: 100%;
			background-color: transparent;
			padding: 5% 0;
		}
	}
	.ConfirmationLayout {
		&__left {
			padding-left: 1.5rem;
		}
		&__right {
			background-color: #f9fafa;
			padding: 36px;
			padding-right: 1.5rem;
		}
	}
}

@media screen and (max-width: 486px) {
	.SimpleModalLayout {
		width: 100vw;
		max-width: 336px;
	}
}
