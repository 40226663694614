.FlightConfirmationPage {
	background-color: #ffffff;

	h3 {
		font-style: normal;

		margin-bottom: 1.25rem;
	}

	&__wrapper {
		max-width: 403px;
	}

	.msg {
		font-weight: 450;
		font-size: 16px;
		line-height: 25px;
		color: #655f5f;
		max-width: 400px;
	}

	.top-section {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		gap: 1rem;
		justify-content: space-between;
		margin-bottom: 28px;

		.mobile-button {
			display: none;
		}

		&__writeUp {
			padding: 10px;
			padding-right: 43px;

			border-radius: 8px;
			background: #ffeaf9;

			display: flex;
			flex-direction: row;
			align-items: flex-start;
			gap: 10px;

			span {
				color: #655f5f;
				font-size: 16px;
				font-weight: 450;
				line-height: 22px;
			}
		}
	}

	.two-column {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		gap: 70px;
		margin-bottom: 28px;
	}

	.passenger-section {
		margin-bottom: 28px;
	}

	.flightCategorisation {
		margin: 49px 0;

		h4 {
			color: #252627;
			font-size: 16px;
			font-weight: 500;
			margin: 12.5px 0;
		}

		&__box {
			border-radius: 8px;
			border: 0.8px solid rgba(196, 196, 196, 0.5);
			background: #fff;
			padding: 20px;

			.tags {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 10px;
				flex-wrap: wrap;
			}

			.group {
				&__title {
					color: #655f5f;
					font-size: 13.59px;
					font-family: Circular Std;
					font-weight: 450;
					margin-bottom: 10px;
				}
			}

			.dropDown {
				position: relative;
				display: inline-block;

				border-radius: 3.85px;
				border: 0.8px solid rgba(196, 196, 196, 0.5);
				margin-bottom: 18px;
				width: 100%;

				&__selected {
					font-size: 14px;
					font-weight: 450;
					padding: 10px;
					color: #000000;

					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 1rem;
					justify-content: space-between;
					cursor: pointer;

					&--label {
						color: #c4c4c4;
					}
				}

				&__content {
					position: absolute;
					top: 100%;
					left: 0;
					width: 100%;
					min-width: 160px;

					border-radius: 8px;
					border: 0.8px solid rgba(196, 196, 196, 0.5);
					background: #fff;
					box-shadow: 0px 4px 4px 0px rgba(156, 147, 147, 0.1);

					z-index: 1;
					padding: 0;

					margin-bottom: 18px;
					height: 0;
					overflow: hidden;
					opacity: 0;
					transition: all 0.3s ease-in-out;

					&--show {
						height: auto;
						opacity: 1;
						padding: 11px 0;
						transform: translateY(0.25rem);
					}

					&__optionList {
						max-height: 200px;
						overflow-y: auto;
					}

					&__option {
						padding: 8.65px 18px;
						cursor: pointer;
						transition: all 0.3s ease-in-out;

						color: #655f5f;
						font-size: 14px;
						font-style: normal;
						font-weight: 450;
						line-height: normal;
						margin-bottom: 0.25rem;

						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: space-between;

						svg {
							opacity: 0;
						}

						&:hover {
							background-color: #eef7fc;
						}

						&--selected {
							svg {
								opacity: 1;
							}
						}

						&--noHover {
							&:hover {
								background-color: #fff;
								cursor: default;
							}
						}
					}

					&__btnWrap {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;

						button {
							border: 0;
							border-radius: 3.85px;
							background: #f7f7f7;
							padding: 6px 5.5px;
							padding-left: 8 px;
							cursor: pointer;

							display: flex;
							flex-direction: row;
							align-items: center;
							gap: 8px;

							color: #40798c;
							font-size: 13.59px;
							font-style: normal;
							font-weight: 450;
							line-height: normal;

							// svg {
							// 	// padding: 7px;
							// }
						}
					}
				}
			}
		}
	}

	.back {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 1.25rem;
		font-weight: 450;
		font-size: 13.429px;
		line-height: 17px;
		color: #40798c;
		cursor: pointer;
		margin-bottom: 22px;
	}

	.details-desktop {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		gap: 28px;
	}

	.details-mobile {
		display: none;
	}

	.SecuredPayment__info {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 5px;
		justify-content: flex-end;
		font-weight: 450;
		font-size: 12px;
		line-height: 20px;
		color: #9c9393;
	}
	.btnWrap {
		display: flex;
		margin-top: 10px;
		& > button {
			font-weight: 500;
			font-size: 17.28px;
			line-height: 37px;
			padding: 7px 73px;
			height: 51px;
			width: 100%;
		}
	}

	.flag {
		display: flex;
		margin-top: 0.75rem;
	}
}

.FlightConfirmationPage-mobile {
	display: none;
}

.tableHead {
	font-size: 13.59px;
	line-height: 17px;
	color: #252627;
	margin-left: 0.75rem;

	.double-col {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 150px;
		// margin-bottom: 0.75rem;
	}

	.col {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 1rem;
		justify-content: space-between;
		font-weight: 450;

		.details {
			color: #40798c;
			cursor: pointer;
		}
		.depart {
			color: #655f5f;
			margin-bottom: 2px;
		}
	}
}

.tableBody {
	font-size: 13.59px;
	line-height: 17px;
	color: #655f5f;
	font-weight: 500;
	.double-col {
		display: grid;
		grid-template-columns: 2fr 1fr;
		margin-bottom: 0.75rem;
		font-weight: 450;

		& > :nth-child(2) {
			color: #a8a0a0;
			font-weight: 500;
		}
	}

	.double-col-mobile {
		display: grid;
		grid-template-columns: 43px 1fr;
		align-items: center;
		gap: 28px;
		margin-bottom: 1.75rem;

		> div {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 3px;
			font-weight: 450;
			color: #252627;
		}
	}

	.total {
		color: #252627;
		margin-bottom: 0;
		font-size: 15px;
		line-height: 19px;
		font-weight: 500;

		& > :nth-child(2) {
			color: #252627;
			font-weight: 500;
		}
	}

	.big-text {
		font-weight: 450;
		font-size: 15px;
		line-height: 19px;

		color: #252627;
	}
}

.travelIte {
	// min-height: 117px;
	&__location {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 0.5rem;
		margin-bottom: 0.7rem;
	}

	&__info {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 0.5rem;
		margin-bottom: 0.75rem;
		flex-wrap: wrap;
		font-weight: 450;
	}

	&__bottom {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		gap: 1rem;
		flex-wrap: wrap;
		justify-content: space-between;

		.share {
			font-weight: 450;
			font-size: 13px;
			line-height: 16px;
			cursor: pointer;

			color: #40798c;
		}

		.users {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			gap: 2px;
			// min-width: 200px;

			background: #ffffff;
			border: 0.346774px solid #c4c4c4;
			border-radius: 56.5789px;
			padding: 4px;
		}

		.mobile-users {
			display: none;
		}
	}
}

.detailsTable {
	margin: 0.5rem 20px;
	.double-col {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 150px;
		margin-bottom: 1.75rem;
	}
	img,
	svg {
		width: 43.88px;
	}

	.block {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 28px;
		margin-bottom: 28px;

		> div {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 7px;
			font-weight: 450;
			color: #252627;
		}
	}
	.big-text {
		font-weight: 450;
		font-size: 15.3292px;
		line-height: 19px;
	}

	.gray {
		color: #9c9393;
	}
}

@media screen and (max-width: 1220px) {
	.FlightConfirmationPage {
		.two-column {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 0.75rem;
			margin-bottom: 28px;
		}
	}
}

@media screen and (max-width: 800px) {
	.FlightConfirmationPage {
		display: none;
	}
	.FlightConfirmationPage-mobile {
		display: block;
		padding: 2.815rem 20px;
		padding-bottom: 6rem;
		background-color: #ffffff;

		h3 {
			margin-bottom: 0.75rem;
			span {
				color: #40798c;
			}
		}

		.msg {
			font-weight: 450;
			font-size: 14px;
			line-height: 20px;
			color: #655f5f;
		}

		.passenger-section {
			padding: 25px 0;
			padding-top: 5px;

			h4 {
				margin-bottom: 1.25rem;
			}
		}

		.details-desktop {
			display: none;
		}
		.details-mobile {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			gap: 0.75rem;
		}

		.top-section {
			display: block;
			margin-bottom: 40px;
			button {
				display: none;
			}
			.msg {
				max-width: 100%;
			}

			.mobile-button {
				display: grid;
				place-items: center;
				width: 100%;
				height: 72px;

				background: #243256;
				border-radius: 0px;
				border: 0px;
				color: #ffffff;
				cursor: pointer;

				position: fixed;
				bottom: 0;
				left: 0;

				font-weight: 500;
				font-size: 20px;
				line-height: 25px;
			}
		}

		.two-column {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			gap: 25px;
		}
	}

	.detailsTable {
		margin: 0;
		.block-mobile {
			margin-bottom: 15px;
		}
		.double-col-mobile {
			display: grid;
			grid-template-columns: 25vw 1fr;
			gap: 1rem;
			margin-bottom: 15px;

			> div {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 3px;
				font-weight: 450;
				color: #252627;
			}
		}
	}

	.tableHead {
		margin-left: 2px;
		.col {
			display: grid;
			grid-template-columns: 25vw 1fr;
			p {
				color: #252627;
				font-weight: 500;
				font-size: 13.59px;
				line-height: 17px;
			}
			.depart {
				margin-bottom: 0;
				color: #252627;
			}
		}
	}

	.tableBody {
		font-weight: 450;
		font-size: 13px;
		line-height: 16px;
		margin-left: 2px;
		.big-text {
			font-weight: 450;
			font-size: 13px;
			line-height: 16px;
		}

		.trip-col {
			display: grid;
			grid-template-columns: 1fr 4px 1fr;
			gap: 40px;
			margin-bottom: 0.75rem;

			& > :last-child {
				color: #252627;
				font-weight: 500;
			}
		}
		.total {
			padding: 10px 0;
			border-top: 1px solid #c4c4c4;
			margin-top: 19px;
			// margin-bottom: 1rem;
			p {
				font-weight: 500;
				font-size: 15px;
				line-height: 19px;
				color: #252627;
			}
		}
	}

	.travelIte {
		&__location {
			margin-bottom: 0.75rem;
		}
		&__info {
			margin-bottom: 21px;
			flex-direction: row;
			align-items: flex-start;
			gap: 6px;

			.dot {
				display: none;
			}
		}
		&__bottom {
			// flex-direction: column;
			// align-items: flex-start;
			gap: 0.25rem;

			.users {
				display: none;
			}

			.mobile-users {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				gap: 2px;
				min-width: max-content;

				background: #ffffff;
				border: 0.346774px solid #c4c4c4;
				border-radius: 56.5789px;
				padding: 4px;
			}
		}
	}
}
