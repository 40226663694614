.LoadMore {
	padding: 0.75rem 1.5rem;
	background: transparent;

	border: 1px solid rgba(196, 196, 196, 0.9);
	border-radius: 90px;
	width: max-content;

	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
	cursor: pointer;
	color: #252627;

	span {
		font-weight: 700;
		font-size: 15px;
		line-height: 16px;
	}

	svg {
		width: 20px;
		height: 20px;
	}

	&--loading {
		svg {
			-webkit-animation: spin 1s linear infinite; /* Safari */
			animation: spin 1s linear infinite;
		}
	}

	&--disabled {
		color: #9c9393;
		cursor: not-allowed;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
