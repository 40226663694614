.TripPage {
	height: calc(100vh - 61px);
	overflow: auto;
	position: relative;

	&__header {
		border: 1px solid rgba(196, 196, 196, 0.5);
		background: #fff;
		padding: 20px 25px;
		padding-left: 35px;

		position: fixed;
		top: 0;
		left: 0;
		z-index: 32;
		width: 100%;

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		p {
			color: #252627;
			font-size: 13px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
	}
}

.welcome-message {
	text-align: center;
	padding-top: 1rem;
	padding-bottom: 1.25rem;
	background-color: #f9fafa;

	.welcome {
		font-weight: 450;
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 0.75rem;
		color: #655f5f;
	}
	.link {
		font-weight: 450;
		font-size: 20px;
		line-height: 25px;
		color: #243256;
	}
}

@media screen and (max-width: 780px) {
	.TripPage {
		padding-bottom: 61px;
	}
}
