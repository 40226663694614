.UploadPhoto {
	display: flex;
	flex-direction: column;
	align-items: center;

	&__box {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		gap: 0.5rem;
		width: 100px;
		height: 100px;
		background: #f9fafa;
		border: 0.8px solid rgba(196, 196, 196, 0.5);
		border-radius: 3px;
		padding: 16px 16px 12px 14px;

		svg {
			color: #40798c;
		}

		button {
			width: 100%;
			font-family: "Circular Std";
			font-style: normal;
			font-weight: 450;
			font-size: 13px;
			line-height: 16px;
			color: #ffffff;
			border: 0;
			background: #40798c;
			border-radius: 50px;
			padding: 2px 12px;
			cursor: pointer;
			text-align: center;
		}
	}
	p {
		font-weight: 450;
		font-size: 14.01px;
		line-height: 18px;
		color: #9c9393;
		text-align: center;
		margin-top: 6px;
	}
}

@media screen and (max-width: 780px) {
	.UploadPhoto {
		width: max-content;
		&__box {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 33px;
			height: 33px;
			background: #f9fafa;
			border: 0.8px solid rgba(196, 196, 196, 0.5);
			border-radius: 50%;
			padding: 0;
			background: #40798c;

			svg {
				color: #ffffff;
				width: 19px;
				height: 19px;
			}

			button {
				display: none;
			}
		}
	}
}
