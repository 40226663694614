.EmptyStateBig {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 0.25rem;
	min-height: 280px;
	text-align: center;

	.title {
		font-weight: 450;
		font-size: 18px;
		line-height: 23px;
		color: #252627;
	}
	.desc {
		font-weight: 450;
		font-size: 13.59px;
		line-height: 17px;
		color: #655f5f;
		margin-bottom: 20px;
	}

	button {
		font-size: 13.59px;
		line-height: 20px;
		padding: 9px 18px;
	}
}
