.EmptyState {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 14px;

    p {
        font-weight: 450;
        font-size: 14.0088px;
        line-height: 18px;
        color: #9c9393;
    }
    button {
        font-weight: 450;
        font-size: 12.609px;
        line-height: 16px;
    }
}
