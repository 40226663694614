.login-right {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	.sign-in {
		width: 100%;
		.heading {
			text-align: center;
			h5 {
				font-size: 1em;
				padding: 5px 0;
				font-family: "Circular Std";
				color: #252627;
			}
			.small {
				color: #655f5f;
				font-size: 85%;
			}
		}

		.bottom {
			text-align: center;
			p {
				padding: 5px 0;
				padding-top: 20px;
				color: #9c9393;
				font-size: 80%;
			}
			a {
				text-decoration: none;
				color: #40789c;
				padding: 5px 0;
			}
		}
		.dots {
			padding: 5% 0;
			height: 30px;
		}
	}

	.dots {
		display: flex;
		justify-content: center;
		padding: 0;
		align-items: flex-end;
		color: #252627;

		.current {
			font-size: 32px;
			color: #40798c;
			padding: 0px;
			justify-self: center;
		}
	}
}

@media screen and (max-width: 480px) {
	.login-right {
		width: 100%;
		.sign-in {
			// padding-top: 15%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			// padding: 25%;
			// padding-top: 40%;
			.heading {
				h5 {
					font-size: 1.8em;
					font-weight: 500;
					padding-bottom: 10%;
				}
			}
			width: 90%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			.form-section {
				box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
				display: flex;
				align-items: center;
				padding: 7.5px;
				padding-left: 15px;
				border-radius: 4px;
				height: 80%;

				form {
					width: 85%;
					height: 60%;
					padding-top: 15%;
					select {
						display: block;
						color: #444;
						line-height: 1.3;
						padding: 0.6em 1.4em 0.5em 0.8em;
						width: 100%;
						max-width: 100%;
						box-sizing: border-box;
						margin: 0;
						border-radius: 0.5em;
						-moz-appearance: none;
						-webkit-appearance: none;
						appearance: none;
						background-color: #fff;
						background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
							linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
						background-repeat: no-repeat, repeat;
						background-position: right 0.7em top 50%, 0 0;
						background-size: 0.65em auto, 100%;
					}
					.form-row {
						display: grid;
						grid-template-columns: 1fr;
						padding: 0px;
					}
				}
			}
		}
		.forgot-password {
			.heading {
				p {
					// padding-bottom: 20px;
				}
			}
			.form-section {
				height: 35%;
			}
		}
	}
}
