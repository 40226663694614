.register {
	padding: 5% 15%;
	padding-top: 7.5%;
	height: 50%;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	background: url("../../resource/img/Onboarding-background.svg");
	background-repeat: no-repeat;
	background-size: 100% auto;
	.register-block {
		display: flex;
		height: 75vh;
		border-radius: 10px;
		overflow: hidden;
		box-shadow: -14px -3px 77px -8px rgba(78, 76, 76, 0.75);
		.register-left {
			background: url("../../resource/img/register-circles.svg");
			background-color: #243256;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			width: 45%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			.up-left {
				height: 80%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				.left1 {
					color: #f5f5f5;
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;
					h4 {
						font-family: "Circular Std";
						font-style: normal;
						font-weight: 700;
						font-size: 27px;
						line-height: 35px;
						padding-bottom: 30px;
						max-width: 80%;
					}
					div {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 80%;
						p {
							font-family: "Circular Std";
							font-style: normal;
							font-weight: 450;
							font-size: 18.4052px;
							line-height: 23px;
							padding-left: 10px;
							text-align: left;
						}
					}
				}
			}
			.down-left {
				height: 20%;
				width: 100%;
				background: #40798c;
				display: flex;
				justify-content: flex-end;

				img {
					padding: 10px 0;
					padding-right: 10px;
				}
			}
		}
		.register-right {
			padding: 4% 0;
			background-color: #fff;
			width: 55%;
			// padding-bottom: 10%;
			display: flex;
			flex-direction: column;
			// justify-content: space-between;
			align-items: center;
			// .dots {
			//   display: flex;
			//   align-items: flex-end;
			//   color: #252627;
			//   .current {
			//     font-size: 32px;
			//     color: #40798c;
			//     padding: 0px;
			//     justify-self: center;
			//   }
			// }
		}
	}
}

@media screen and (max-width: 480px) {
	.register {
		background: #f9fafa;
		padding: 0;
		height: 100vh;
		.register-block {
			width: 100%;
			height: 100%;
			.register-left {
				display: none;
			}
			.register-right {
				width: 100%;
			}
		}
	}
}
