.trip {
	// width: 100vw;
	min-height: 150vh;
	height: max-content;
	// background: url("../../resource/img/tripBackgroud.svg");
	// background-repeat: no-repeat;
	// background-size: 100% auto;
	display: flex;
	// align-items: center;
	justify-content: center;
	// padding: 0 5%;
	position: relative;
	background-color: #f9fafa;

	.tripbg {
		position: absolute;
		width: 100%;
	}

	.trip-wrap {
		padding-top: 7.5%;
		overflow-x: hidden;
		z-index: 1;
		max-width: 1038px;
		min-height: 100%;
		// height: 100%;
		// overflow-y: visible;
	}

	.trip-content {
		width: 100%;
		.tabs,
		.mob-tabs {
			display: flex;
			width: 45%;
			display: flex;
			justify-content: space-between;
			p {
				width: 22.5%;
				padding: 8px 12px;
				padding-bottom: 12px;
				font-style: normal;
				font-weight: 450;
				font-size: 15.913px;
				background: #fff;
				line-height: 20px;
				text-align: center;
				color: #655f5f;
				border-left: 2px solid #f5f5f5;
				border-top: 2px solid #f5f5f5;
				border-bottom: 2px solid #f5f5f5;
				cursor: pointer;
			}
			p:last-child {
				width: 40%;
				border-radius: 0px 9.70695px 0px 0px;
				// padding: 5px 7.5px;
			}
			.active {
				background: #6193a3;
				border-left: 2px solid transparent;
				border-top: 2px solid transparent;
				border-bottom: 2px solid transparent;
				color: #fff;
			}
		}
		.mob-tabs {
			display: none;
		}
	}
}

.last-form-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	.select-wrapper-holder {
		display: flex;
		align-items: center;

		.close {
			&:hover {
				color: #40798c;
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.trip {
		padding: 2.815rem 1.5rem;
		padding-top: 7.5%;
	}
}

@media screen and (max-width: 780px) {
	.trip {
		overflow: auto;
		min-height: unset;
		height: unset;
		padding-bottom: 0;
		// margin-bottom: 2rem;

		.trip-wrap {
			width: 100%;
		}
		.trip-content {
			width: 100%;

			.tabs {
				display: none;
			}
			.mob-tabs {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr 1fr;
				background-color: #fff;
				width: 100%;
				border-radius: 3px 3px 0px 0px;
				overflow: hidden;
				div {
					padding: 15%;
					display: flex;
					align-items: center;
					justify-content: center;
					border-left: 2px solid #f5f5f5;
					border-top: 2px solid #f5f5f5;
					border-bottom: 2px solid #f5f5f5;
					// width: 25%;
				}
				div:last-child {
					border-right: 2px solid #f5f5f5;
				}
			}
		}
	}
	.trip .trip-content .content .bottom .form-section form .form-item {
		.date-wrapper-new {
			padding: 1px 20px;
		}
		.select-wrapper {
			padding: 7.5px 20px;
		}
	}
}
