.PolicyTag {
	font-weight: 450;
	font-size: 12px;
	line-height: 15px;
	border-radius: 5px;

	display: flex;
	flex-direction: row;
	align-items: center;

	// variant
	&--inPolicy {
		background: #ffeae2;
		* {
			color: #fca17d;
		}
	}

	&--outOfPolicy {
		background: rgba(196, 196, 196, 0.25);
		* {
			color: #9c9393;
		}
	}
	//-----------------------

	// size
	&--short {
		padding: 3px 5px;
		gap: 4px;
	}

	&--long {
		padding: 5px 7px;
		gap: 5.5px;
	}
	//-----------------------

	span {
		min-width: max-content;
	}
}
