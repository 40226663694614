.PolicyFlag {
	font-weight: 450;
	font-size: 12px;
	line-height: 15px;

	display: flex;
	flex-direction: row;
	align-items: center;

	border-radius: 3px;
	background: rgba(220, 225, 231, 0.5);

	// variant
	&--inPolicy {
		* {
			color: #fca17d;
		}
	}

	&--outOfPolicy {
		* {
			color: #cc3e65;
		}
	}
	//-----------------------

	// size
	&--short {
		padding: 3px 5px;
		gap: 4px;
	}

	&--long {
		padding: 5px 7px;
		gap: 5.5px;
	}
	//-----------------------
}
