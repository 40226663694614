.DateDropDown {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 2px;

	& > .CustomDropdown:first-of-type {
		.CustomDropdown__box {
			// display: none;
			.dropInput {
				border-top-right-radius: 0 !important;
				border-bottom-right-radius: 0 !important;
			}
		}
	}

	& > .CustomDropdown:nth-of-type(2) {
		.CustomDropdown__box {
			// display: none;
			.dropInput {
				border-radius: 0 !important;
			}
		}
	}

	& > .CustomDropdown:nth-of-type(3) {
		.CustomDropdown__box {
			// display: none;
			.dropInput {
				border-top-left-radius: 0 !important;
				border-bottom-left-radius: 0 !important;
			}
		}
	}
}
