.CategoryTag {
	color: #655f5f;
	font-size: 12px;
	font-family: Circular Std;
	font-weight: 450;

	border-radius: 3.85px;
	background: #d5ebff;

	padding: 3px 5px;
	min-width: max-content;

	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 6px;

	& > svg {
		cursor: pointer;
	}
}
