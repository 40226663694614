// @import url("https://fonts.cdnfonts.com/css/circular-std");

.Toastify {
	&__toast {
		&-container {
			width: unset !important;
			min-width: 320px;
		}

		&--error {
			border: 0;
			/* border-radius: 50px !important; */
			background: #a10f18 !important;
			color: #ffffff !important;

			svg > path {
				fill: #ffffff !important;
			}
		}

		&--success {
			border: 0;
			/* border-radius: 50px !important; */
			background: #169d53 !important;
			color: #ffffff !important;

			svg > path {
				fill: #ffffff !important;
			}
		}

		&--info {
			border: 0;
			/* border-radius: 50px !important; */
			background: #243256 !important;
			color: #ffffff !important;
			svg > path {
				fill: #ffffff !important;
			}
		}
	}
}

* {
	padding: 0;
	margin: 0;
	font-family: "Circular Std", Helvetica;
	font-display: swap;
	box-sizing: border-box;
	/* overflow: scroll; */
	/* -webkit-overflow-scrolling: touch; */
	scroll-behavior: smooth;

	*::selection {
		color: #e2e7f3;
		background-color: #243256;
	}
}

.main {
	margin-top: 61px;
	/* padding-bottom: 61px; */
	overflow: auto;
	scrollbar-gutter: stable;
	height: 100vh;
	font-weight: 450;
	height: calc(100vh - 61px);
	background-color: #f9fafa;
	/* padding-top: 20px; */
}

.form-item > h5 {
	font-weight: 100;
}

body {
	overflow: hidden;
	overscroll-behavior: none;
}

.hover:hover {
	cursor: pointer;
}

.menu-options {
	background-color: white;
	width: 100vw;
	height: 100vh;
	z-index: 31;
	padding: 52px 17px;
	padding-top: 0;

	ul {
		margin-top: 35px;
		padding-bottom: 21px;
		margin-bottom: 28px;
		margin-left: 13px;
		border-bottom: 1px solid #d9d9d9;

		display: flex;
		flex-direction: column;
		align-items: stretch;
		gap: 13px;
	}

	a {
		text-decoration: none;
		color: #252627;
	}

	a li,
	.signoutLink {
		font-family: "Circular Std";
		font-style: normal;
		font-weight: 450;
		font-size: 14.3975px;
		line-height: 18px;
		list-style-type: none;

		padding: 10px;
		padding-left: 0;
	}

	.menu-top {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
		padding-bottom: 1rem;
		padding-top: 27px;
		border-bottom: 1px solid rgba(196, 196, 196, 0.2);

		.userInfo {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 10px;

			p {
				color: #252627;
				font-size: 13px;
				font-weight: 500;
				line-height: normal;
			}
		}
	}

	.menu-cancel {
		cursor: pointer;
	}

	.user-pic {
		width: 42px;

		border-radius: 50%;
	}

	.signoutLink {
		cursor: pointer;
		margin-left: 13px;
	}
}

h3 {
	font-weight: 500;
	font-size: 25px;
	line-height: 32px;
	color: #252627;
}

input {
	outline: 0;
}

input[type="text"],
input[type="password"],
input[type="email"] {
	border: 0.8px solid rgba(196, 196, 196, 0.5);
	transition: border 0.3s;
}

input[type="text"]:hover,
input[type="password"]:hover,
input[type="email"]:hover {
	border: 0.8px solid #655f5f !important;
}

input[type="checkbox"] {
	accent-color: #252627;
	cursor: pointer;
}

.rmdp-container {
	width: 100%;

	.rmdp-week-day {
		color: #c4c4c4;
	}
	.rmdp-header-values {
		color: #252627;
	}
	.rmdp-range {
		background-color: #40798c;
		color: #fff !important;
	}
	.rmdp-day.rmdp-today span {
		background-color: transparent;
		color: #252627;
	}
	.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
		background-color: #40798c;
	}
	.rmdp-day {
		color: #252627;
	}
	.rmdp-disabled {
		color: #a0a9b1;
	}
}

@media screen and (max-width: 1220px) {
	h3 {
		font-size: 18px;
		line-height: 23px;
	}

	h4 {
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		color: #252627;
	}

	p {
		font-weight: 450;
		font-size: 13px;
		line-height: 16px;
	}
}

@media screen and (max-width: 1024px) {
	.main {
		/* padding-top: 0; */
		/* margin-top: 60px; */
	}

	.Toastify {
		font-size: 14px;
	}

	.Toastify__toast {
		min-height: 42px !important;
	}
}

@media screen and (max-width: 780px) {
	.rmdp-container {
		& > :last-child {
			width: 100%;

			.rmdp-wrapper {
				width: 100%;

				.rmdp-calendar {
					margin: 0 auto;
				}
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.Toastify {
		&__toast-container {
			width: 80vw !important;

			&--bottom-center {
				bottom: 1em !important;
				left: 50% !important;
				transform: translateX(-50%) !important;
			}
		}
	}
}
