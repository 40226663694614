.content {
	// box-shadow: 0px 9px 5px 0px rgba(91, 89, 89, 0.655);
	// -webkit-box-shadow: 0px 9px 5px 0px rgba(91, 89, 89, 0.655);
	// -moz-box-shadow: 0px 9px 5px 0px rgba(91, 89, 89, 0.655);
	border: 0.970695px solid #f5f5f5;
	border-right: 0;
	border-top: 0;
	box-sizing: border-box;
	width: 80vw;
	max-width: 1038px;
	p {
		font-weight: 450;
		font-size: 13.517px;
		line-height: 17px;
	}
	.top-wrapper {
		border-bottom: 0.42834px dashed #c4c4c4;
		padding: 10px 5%;
		padding-top: 1rem;
		background-color: #fff;
		border-top-right-radius: 0;
		overflow: hidden;

		.top {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			width: 70%;
			min-width: fit-content;
			padding: 10px 0;
			box-sizing: border-box;
			gap: 95px;

			.travellers {
				p {
					font-size: 14.01px;
					line-height: 18px;
					padding-bottom: 10px;
					color: #655f5f;
				}

				.travellers-block {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					column-gap: 1.5rem;
					row-gap: 0.5rem;
					.traveller-item {
						width: 268px;
						display: flex;
						flex-direction: row;
						align-items: center;
						gap: 6px;
						border: 0.317797px dashed #c4c4c4;
						padding: 0.25rem;
						border-radius: 60px;
						position: relative;

						.intitialsImg {
							font-weight: 500;
							font-size: 12.7119px;
							line-height: 27px;
							min-width: 2rem;
							width: 2rem;
							height: 2rem;
							background: #ff7477;
							color: #ffffff;
							border-radius: 50%;
							display: grid;
							place-items: center;
						}
						p {
							padding: 0;
							font-weight: 450;
							font-size: 12.7119px;
							line-height: 1rem;
							color: #655f5f;
						}
						.close-icon {
							cursor: pointer;
							color: #9c9393;
							margin-left: auto;
							margin-right: 20px;
							min-width: 12px;
						}
					}
				}

				.travellers-icons {
					display: flex;
					justify-content: space-evenly;
					padding: 5px;
					width: 110%;
					border-radius: 51.851px;
					box-sizing: border-box;
					border: 0.317797px dashed #c4c4c4;
					border-radius: 93px;
					border-radius: 93px;
					p {
						padding: 10px;
						background-color: red;
						border-radius: 50%;
						color: #fff;
					}
				}
			}
		}
	}
	.bottom {
		background-color: #fff;
		padding: 0 5%;
		border-radius: 0;
		// border-radius: 0px 0px 8px 8px;
		border-bottom: 0.42834px dashed #c4c4c4;

		.actions {
			display: flex;
			padding: 15px 0;
			padding-top: 18px;
			align-items: center;
			width: 100%;
			justify-content: space-between;

			.trip-type {
				display: flex;
				gap: 4vw;

				.trip-item {
					display: flex;
					align-items: center;
					cursor: pointer;
					.radio {
						width: 12px;
						height: 12px;
						border-radius: 50%;
						border: 1px solid #9c9393;
					}
					.active {
						display: flex;
						align-items: center;
						justify-content: center;
						border: 1px solid black;

						.active-circle {
							background-color: #000000;
							width: 50%;
							height: 50%;
							border-radius: 50%;
						}
					}
					p {
						padding-left: 10px;
						color: #9c9393;
					}
				}
				.mob {
					display: none;
				}
			}
			.flight-type {
				display: flex;
				gap: 1rem;
				p {
					padding: 7.5px 15px;
					color: #6193a3;
					border-radius: 4px;
					border: 1px solid #c4c4c4;
				}
				.current {
					background: #6193a3;
					color: #fff;
					border: 0.970695px solid #6193a3;
				}
			}

			.car-money {
				font-weight: 500;
				font-size: 18px;
				line-height: 23px;
				color: #252627;
				padding: 0.75rem;
				border: 0.42834px dashed #c4c4c4;
			}

			.tripTypeToggle {
				// color: #655f5f;

				.tripTypeLabel {
					cursor: pointer;
					color: rgb(156, 147, 147);

					font-weight: 450;
					font-size: 13.517px;
					line-height: 17px;

					&--selected {
						color: #243256;
					}
				}
			}
		}
		.form-section {
			padding: 20px 0;
			align-items: center;
			width: 100%;

			form {
				// display: flex;
				// justify-content: space-between;
				// align-items: center;
				display: grid;
				grid-template-columns: 1fr 1fr 1fr 2fr 1fr;
				gap: 2vw;
				width: 100%;
				color: #9c9393;
				margin-bottom: 21px;

				.form-item {
					min-width: 17.5%;
					position: relative;

					.label {
						padding-bottom: 5px;
						color: #655f5f;
					}

					.CustomDropdown__drop--open {
						max-height: 150px !important;
						overflow-y: scroll;
					}

					.del-trip {
						position: absolute;
						left: 100%;
						cursor: pointer;
						top: 50%;
						margin-left: 0.75rem;
					}
					.stuff {
						border: 1px solid #c4c4c4;
						border-radius: 4.44444px;
						background-color: #fff;
						// padding: 7.5px 20px;
						padding-left: 10px;
						display: flex;
						align-items: center;
						box-sizing: border-box;
						// justify-content: space-between;
						// * {
						//   align-items: center;
						// }
						.css-ie7qsw {
							display: flex;
							width: 100%;
						}
						.css-1okebmr-indicatorSeparator {
							display: none;
						}

						img {
							// position: absolute;
							padding-right: 10px;
						}
						.myselect {
							display: flex;
							box-sizing: border-box;
							width: 87%;
							.css-17guccl-Control {
								width: 100%;
								display: flex;
								.css-319lph-ValueContainer {
									padding: 0;
									.css-14el2xx-placeholder {
										color: #9c9393;
									}
								}
								.css-1okebmr-indicatorSeparator {
									display: none;
								}
								.css-qc6sy-singleValue {
									box-sizing: border-box;
									.one {
										padding-bottom: 0;
										color: #9c9393;
									}
									.two {
										display: none;
									}
								}
							}
							.css-26l3qy-menu {
								// display: block;
								// width: 150%;
								// width: 300%;

								display: flex;
								align-items: center;
								justify-content: center;
								z-index: 15;

								.css-4ljt47-MenuList {
									background: transparent;
									position: absolute;
									z-index: 15;
									top: 10%;
									border: 0;

									width: 100%;
									.css-ftcqfo-option {
										// position: relative;
										// z-index: 15;
										z-index: 15;
										position: relative;
										width: 100%;
										// background-color: #fff;
									}
								}
							}
							.css-qc6sy-singleValue {
								.parent-select {
									div .one {
										color: "#9c9393";
									}
									.two {
										display: none;
									}
								}
							}
							.css-ie7qsw {
								.css-1d8n9bt {
									.css-qc6sy-singleValue {
										.parent-select {
											.one {
												color: red;
											}
										}
									}
								}
							}
						}
					}
					p {
						// padding-bottom: 10px;
						color: #9c9393;
					}

					.select-wrapper,
					.date-wrapper {
						border: 1px solid #c4c4c4;
						border-radius: 4.44444px;
						background-color: #fff;
						padding: 8px 20px;
						padding-left: 10px;
						display: flex;
						align-items: center;
						img {
							width: 20px;
							height: 20px;
							padding-right: 10px;
						}
						input,
						select {
							border: 0;
							outline: none;
							appearance: none;
							color: #9c9393;
						}
						input[type="date"]::-webkit-calendar-picker-indicator {
							color: transparent;
							background: none;
							z-index: 1;
						}
						select {
							padding: 1.5px 0;
						}
					}

					.custom-rmdp-input {
						border: 1px solid #c4c4c4;
						border-radius: 4.44444px;
						background-color: #fff;
						padding: 11.11px 2vw;
						font-weight: 400;
						font-size: 13.517px;
						line-height: 17px;
						width: 100%;
						text-align: center;
						color: #000000;
						height: 42px;

						&::placeholder {
							color: rgb(156, 147, 147);
							opacity: 1;
						}
					}
					.date-wrapper {
						grid-column-gap: 20px;
						padding: 8.5px 20px;
						// input[type="date"]::-webkit-inner-spin-button,
						// input[type="date"]::-webkit-calendar-picker-indicator {
						//   display: none;
						//   -webkit-appearance: none;
						// }

						.input-wrapper {
							display: flex;
							align-items: center;
							color: #655f5f;
						}
					}
					.date-wrapper-new {
						display: flex;
						border: 1px solid #c4c4c4;
						padding: 8px 20px;
						border-radius: 4.44444px;
						img {
							padding-right: 5px;
						}
						input {
							border: 0;
							outline: none;
							appearance: none;
							color: #9c9393;
							width: 100%;
						}
						input[type="date"]::-webkit-calendar-picker-indicator {
							color: #9c9393;
							// background: url("../../resource/img/caretdown.svg") no-repeat
							//   right center;
							background: url("../../resource/img/caretdown.svg");
							background-repeat: no-repeat;
							background-position: center;
							height: 10px;
							width: 10px;
							z-index: 1;
						}
					}
				}
			}
			.multi-trip {
				form {
					padding-bottom: 5px;
					grid-template-columns: 1fr 1fr 3fr 1fr;
					// gap: 0;
				}

				& > form:first-of-type {
					.dateTypeWrap {
						display: grid;
						grid-template-columns: 1fr 2fr;
						gap: 2vw;
					}
				}

				.multi-actions {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					p {
						font-weight: 450;
						font-size: 13.517px;
						line-height: 17px;
						padding: 7.5px 0px;
						color: #40798c;
					}
					.cancel {
						display: none;
					}
				}
			}
		}

		.form-section--car {
			form {
				grid-template-columns: repeat(4, 1fr);
			}
		}

		.selectAndButton {
			padding: 25px 0;
			padding-top: 5%;

			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			.choose-travellers {
				width: 60%;
				p {
					color: #9c9393;
					padding-bottom: 12px;
				}
				.choose-div {
					display: flex;
					border: 1px solid #c4c4c4;
					border-radius: 4.44444px;
					padding: 5px 10px;
					width: 50%;
					align-items: center;
					&:hover {
						border: 1px solid #6193a3;
					}

					p {
						padding-left: 10px;
						color: #655f5f;
						padding-bottom: 0px;
					}
				}
				.new-travellers {
					color: #40798c;
					padding-left: 5%;
					padding-bottom: 0;
					// margin-top: 2.5%;
					display: flex;
					align-items: center;
					align-self: center;
				}
			}

			.button-container {
				// width: 90%;
				display: flex;
				justify-content: flex-end;
				gap: 38px;

				.email-request {
					display: flex;
					flex-direction: row;
					align-items: center;

					input {
						border-color: #9c9393;
					}
					& > p {
						padding-left: 10px;
						color: #9c9393;
						position: relative;

						&:hover {
							.CustomTooltip {
								display: inline;
							}
						}
					}
				}
			}
		}
	}

	.bottom--car {
		padding-right: 9%;
	}
}

.EmptyTab {
	height: 342px;
	background-color: #ffffff;
	border-top-right-radius: 8px;
	text-align: center;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 0.75rem;

	.title {
		font-weight: 450;
		font-size: 18px;
		line-height: 23px;
		color: #252627;
	}

	.desc {
		font-weight: 450;
		font-size: 13.59px;
		line-height: 17px;
		color: #655f5f;
		margin-bottom: 20px;
	}
}

@media screen and (max-width: 780px) {
	.content {
		box-shadow: 0;
		-webkit-box-shadow: 0;
		-moz-box-shadow: 0;
		border-radius: 0;
		-webkit-box-shadow: 0px 0px 0px 0px rgb(91 89 89 / 66%);
		border: 2px solid #f5f5f5;
		border-top: 0;
		width: 100%;
		.top-wrapper {
			width: 100%;
			padding: 0;
			border-top-right-radius: 0;
			.top {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				width: 100%;
				box-sizing: border-box;
				padding: 1rem 18px;
				padding-bottom: 0;
				gap: 0;

				.new-travellers {
					display: none;
				}
				.travellers {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 10.5px 0;
					// padding: 7.5% 0;

					.travellers-block {
						width: 100%;
						.traveller-item {
							width: 100%;
							display: flex;
							flex-direction: row;
							align-items: center;
							gap: 6px;
							border: 0.317797px dashed #c4c4c4;
							padding: 0.25rem;
							border-radius: 60px;
							position: relative;

							.close-icon {
								margin-right: 12px;
							}
						}
					}
					.mob-none {
						display: none;
					}
					.travellers-icons {
						padding: 2.5%;
						margin-top: 5%;
						margin-bottom: 5%;
					}
				}
			}
		}
		.bottom {
			display: block;
			box-sizing: border-box;
			min-height: 600px;
			.actions {
				display: block;
				box-sizing: border-box;
				width: 100%;
				padding-top: 40px;
				padding-bottom: 27px;
				.trip-type {
					justify-content: space-between;
					width: 100%;
					.trip-item {
						// display: none;
						.radio,
						.active {
							// display: none;
						}
						p {
							padding-left: 15px;
						}
					}
					// .mob {
					// 	display: block;
					// 	cursor: pointer;
					// 	.mob-active {
					// 		color: #40798c;
					// 	}
					// }
				}
				.flight-type {
					width: 100%;
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					gap: 22px;
					padding-bottom: 2rem;

					p {
						font-weight: 450;
						font-size: 12px;
						line-height: 15px;
						padding: 11px 8px;
						text-align: center;
					}
				}
			}
			.form-section {
				box-sizing: border-box;
				width: 100%;
				padding-top: 13px;
				padding-bottom: 22px;

				.multi-trip {
					& > form:first-of-type {
						.dateTypeWrap {
							display: block;
						}
					}

					.multi-actions {
						.cancel {
							display: block;
							cursor: pointer;
						}
					}
				}

				form {
					width: 100%;
					display: block;
					box-sizing: border-box;
					.form-item {
						padding-bottom: 15px;
						.select-wrapper {
							select {
								width: 100%;
								background-color: transparent;
							}
						}
						input {
							padding: 7.5px 0;
						}

						del-trip {
							display: none;
						}
					}
				}
			}

			.selectAndButton {
				flex-direction: column;
				align-items: stretch;
				gap: 1rem;

				.choose-travellers {
					width: 100%;
					padding: 0;
					box-sizing: border-box;
					.choose-div {
						width: 100%;
						border: 1px solid #c4c4c4;
						box-sizing: border-box;
					}
					p {
						padding-bottom: 4px;
					}

					.new-travellers {
						display: none;
					}
				}

				.button-container {
					width: 100%;
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 1rem;
					justify-content: space-between;

					button {
						font-size: 12px;
						line-height: 15px;
						padding: 12px 25px;
					}
				}
			}
		}
	}
}
