.BookingCompletePage {
	h3 {
		margin-bottom: 0.5rem;
		span {
			color: #40798c;
		}
	}

	.msg {
		font-weight: 450;
		font-size: 16px;
		line-height: 25px;
		color: #655f5f;
		margin-bottom: 30px;
	}

	.priceBreakdown {
		margin-top: 0.5rem;
	}

	.left-tables {
		margin-top: 2rem;
		> * {
			margin-bottom: 18px;
		}

		.PlainTable__tableBody {
			padding-left: 0;
			padding-right: 0;
		}

		& > :first-child {
			.PlainTable__tableBody {
				padding-left: 2rem;
				padding-right: 2rem;
			}
		}
	}

	.dets {
		.PlainTable__tableBody {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.tableBody {
		font-weight: 450;

		.double-col {
			display: grid;
			grid-template-columns: 1fr 4px 1fr;
			margin-bottom: 1rem;

			p {
				font-size: 13.59px;
				color: #655f5f;
				width: max-content;
			}

			& > :last-child {
				text-align: center;
			}
		}
	}

	.printer {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 0.5rem;
		cursor: pointer;
		color: #40798c;
	}
}

.BookingCompletePage-mobile {
	display: none;
}

@media screen and (max-width: 800px) {
	.BookingCompletePage {
		display: none;
	}
	.BookingCompletePage-mobile {
		display: block;
		padding: 2.815rem 0;
		background-color: #ffffff;

		& > :first-child {
			padding: 0 20px;

			img {
				width: 60px;
				height: 60px;
			}
		}

		h3 {
			margin-bottom: 0.75rem;
			span {
				color: #40798c;
			}
		}

		.msg {
			font-weight: 450;
			font-size: 14px;
			line-height: 20px;
			color: #655f5f;
			padding-bottom: 30px;
			border-bottom: 1px solid rgba(196, 196, 196, 0.5);
		}

		.black {
			font-weight: 450;
			font-size: 13px;
			line-height: 16px;

			color: #252627;
		}

		.passengers {
			padding: 30px 20px;
			border-bottom: 1px solid #c4c4c4;

			h4 {
				margin-bottom: 1.25rem;
			}
		}

		.price-breakdown {
			padding: 20px 22px;
			padding-right: 2rem;
			background: #f9fafa;
		}

		.FlightInformation {
			color: #252627;
			border-bottom: 1px solid rgba(196, 196, 196, 0.5);
			padding: 31.5px 20px;
			.double-col {
				display: grid;
				grid-template-columns: 1fr 4px 1fr;
				gap: 48px;
				margin-bottom: 0.75rem;
				& > :first-child {
					font-weight: 400;
					color: #655f5f;
				}
				& > span {
					color: #9c9393;
				}
			}

			&__title {
				font-weight: 500;
				font-size: 16px;
				line-height: 20px;
				margin-bottom: 20px;
			}
		}

		.table {
			font-weight: 450;
			font-size: 13px;
			line-height: 16px;
			padding: 15px 0;
			padding-bottom: 1rem;
			color: #252627;
			border-bottom: 1px solid #c4c4c4;

			> :first-child {
				font-size: 16px;
				line-height: 27px;
				margin-bottom: 1rem;
			}
			.double-col {
				display: grid;
				grid-template-columns: 2fr 1fr;
				margin-bottom: 15px;
			}
			.double-col.smaller {
				margin-bottom: 0.5rem;
			}
			.gray {
				color: #655f5f;
			}

			.total {
				font-weight: 500;
				font-size: 12px;
				line-height: 15px;
				color: #252627;
			}
		}

		.grey-card {
			background: #f5f5f5;
			border-radius: 10.7783px;
			padding: 15px 24px;
			font-weight: 450;

			margin-top: 20px;
			color: #655f5f;

			p {
				font-size: 13px;
				line-height: 22px;
			}

			.title {
				font-weight: 500;
				font-size: 20.81px;
				line-height: 26px;
				margin-bottom: 15px;
				color: #252627;
			}

			a {
				text-decoration: none;
				color: #40798c;
			}
		}
	}
}
