.flight-page {
	padding: 3.375rem 13.9vw;
	padding-left: 14.09vw;
	padding-right: 12.5vw;
	padding-bottom: calc(3.375rem + 61px);
	// height: calc(100vh - 61px);
	// overflow-y: scroll;
	background-color: #ffffff;
	overflow: auto;
	height: calc(100vh - 61px);

	.search-query {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 12.4vw;
		background: #ffffff;
		border: 1px solid #c4c4c4;
		border-radius: 8px;
		padding: 19px 28px;
		margin-bottom: 27px;
		.search-left {
			display: block;

			.up {
				display: flex;
				align-items: center;
				gap: 12px;

				font-style: normal;
				font-weight: 450;
				font-size: 17px;
				line-height: 22px;
				color: #252627;
				margin-bottom: 15px;

				div {
					img {
						display: block;
					}
				}
			}
			.down {
				font-style: normal;
				font-weight: 450;
				font-size: 13.59px;
				line-height: 17px;

				color: #655f5f;

				display: flex;
				flex-direction: row;
				align-items: center;
				flex-wrap: wrap;
				gap: 10px;

				.dot {
					color: #c4c4c4;
					font-weight: 450;
					font-size: 11px;
					line-height: 14px;
				}
			}
		}
		.search-right {
			display: flex;
			align-items: center;
			gap: 9px;
			font-weight: 450;
			font-size: 14px;
			line-height: 18px;
			color: #40798c;
			padding: 1rem 12px;
			border-radius: 3px;
			cursor: pointer;
			transition: background 0.3s;

			&:hover {
				background: rgba(244, 244, 244, 0.5);
			}

			svg {
				width: 17px;
			}
		}
	}
	.flight-selected {
		font-weight: 450;
		font-size: 13.59px;
		line-height: 17px;
		background: #f5f5f5;
		color: #655f5f;
		border: 0.5px solid #c4c4c4;
		border-radius: 3px 3px 0px 0px;

		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 1rem;
		justify-content: space-between;
		padding: 10px 70px;

		.change {
			font-weight: 500;
			font-size: 14px;
			line-height: 18px;
			color: #40798c;
			cursor: pointer;
		}
	}
	.filter-options {
		margin-bottom: 0.5rem;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.advanced {
			margin-left: 16px;
			font-weight: 450;
			font-size: 13.59px;
			line-height: 17px;

			color: #655f5f;
		}
		.result-filter {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			.filters {
				display: flex;
			}
			.top-result {
				display: flex;
			}
			.filters {
				width: 45%;
				display: flex;
				align-items: center;
				// justify-content: space-between;
				// flex-wrap: wrap;
				// overflow-y: auto;
				gap: 10px;
				font-size: 80%;
				padding: 10px 16px;
				.singlefilter {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 1%;
					background: rgba(196, 196, 196, 0.15);
					border-radius: 7.01124px;
					// padding: 1% 15%;
					padding: 2% 9% 2% 8%;
					img {
						width: 12px;
						height: 1em;
						padding-left: 10px;
					}
				}
			}
		}
		.filter-div {
			display: none;
		}
	}

	.restrictions {
		background: rgba(168, 205, 231, 0.7);
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 1rem;
		padding: 19px 21px 15px 21px;
		border-radius: 8px 0px 0px 8px;
		position: relative;
		font-size: 13.59px;
		line-height: 17.19px;
		font-weight: 450;
		margin-bottom: 6px;

		&__left {
			width: 7px;
			height: 100%;
			background: #386fa4;
			border-radius: 8px 0px 0px 8px;
			position: absolute;
			top: 0;
			left: 0;
		}

		&__text {
			display: flex;
			flex-direction: row;
			align-items: flex-end;
			gap: 14px;
		}

		&__see_full {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 10.5px;
			color: #243256;
			cursor: pointer;
			margin-top: 1px;

			&--mobile {
				display: none;
			}
		}

		img {
			width: 21.85px;
		}
		svg {
			width: 15px;
			height: 15px;
		}
	}

	.hotels-main {
		display: grid;
		grid-template-columns: 568px 1fr;
		gap: 0.5rem;

		.hotel-results {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			gap: 0.5rem;

			.hotel-result {
				background: #ffffff;
				border: 0.34px solid rgba(156, 147, 147, 0.6);
				box-shadow: 0px 2px 2px rgba(64, 121, 140, 0.25);
				border-radius: 8px;
				overflow: hidden;
				padding-right: 30px;

				> :first-child {
					border-bottom: 0.34px solid #9c9393;
					display: grid;
					grid-template-columns: 204px 1fr;

					.image-wrapper {
						overflow: hidden;
						img {
							height: 100%;
						}
					}

					.main-content {
						padding: 21px 32px;
						font-weight: 450;
						font-size: 11px;
						line-height: 14px;
						color: #9c9393;

						display: flex;
						flex-direction: row;
						align-items: flex-start;
						justify-content: space-between;
						cursor: pointer;
					}

					.features {
						.amenities {
							> div {
								display: flex;
								flex-direction: row;
								align-items: center;
								gap: 1rem;
								margin-bottom: 2px;
							}
						}

						.rest {
							color: #5a6981;
							margin-top: 0.85rem;
						}

						.ratings {
							margin-top: 2px;
						}
					}
				}

				> :last-child {
					font-weight: 450;
					font-size: 15px;
					line-height: 19px;
					color: #252627;
					padding: 9px 18px;
					padding-bottom: 15px;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;

					.gray {
						color: #9c9393;
					}
					.blue-link {
						color: #243256;
						cursor: pointer;
					}

					.name-price {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						gap: 0.25rem;

						div {
							display: flex;
							flex-direction: row;
							align-items: center;
							gap: 2rem;
						}
					}
				}
			}
		}

		.hotelOptions {
			background: #f9f9f9;
			max-height: 1440px;
			transition: max-height 0.5s;
			&--shut {
				max-height: 0;
				overflow: hidden;
			}

			.tabComponent {
				border-left: 0.39px solid rgba(156, 147, 147, 0.6);
				border-right: 0.39px solid rgba(156, 147, 147, 0.6);

				.top {
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					justify-content: space-between;
					padding: 0 2rem;
					padding-top: 24px;
					font-weight: 450;
					font-size: 13.59px;
					line-height: 17px;
					color: #655f5f;
					border-bottom: 0.34px solid #9c9393;

					.tabs {
						display: flex;
						flex-direction: row;
						align-items: flex-end;
						gap: 1.5rem;

						.tab {
							padding: 0 0 0.75rem 0;
							cursor: pointer;

							&--active {
								color: #70a9a1;
								border-bottom: 1px solid;
							}
						}
					}

					.more-filter {
						display: flex;
						flex-direction: row;
						align-items: center;
						gap: 0.4rem;
						color: #5a6981;
						cursor: pointer;
					}
				}

				.roomItem {
					padding: 18px 60px 1rem 2rem;
					border-bottom: 0.34px solid #c4c4c4;
					font-weight: 450;
					font-size: 15px;
					line-height: 19px;

					&__nameprice {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: space-between;
						margin-bottom: 0.5rem;
					}

					&__bedsize {
						display: flex;
						flex-direction: row;
						align-items: center;
						gap: 1rem;
						color: #9c9393;
						margin-bottom: 20px;
					}

					&__imgfeatures {
						font-weight: 450;
						font-size: 13.59px;
						line-height: 17px;
						color: #655f5f;
						display: flex;
						flex-direction: row;
						align-items: flex-start;
						gap: 14px;
						margin-bottom: 21px;

						.img-grid {
							display: grid;
							grid-template-columns: repeat(3, 35px);
							gap: 3px;
						}

						.feature {
							display: flex;
							flex-direction: row;
							align-items: center;
							gap: 1.25rem;
							margin-bottom: 1rem;
						}
					}

					&__buttonDiv {
						display: flex;
						button {
							font-weight: 500;
							font-size: 15px;
							line-height: 19px;
							margin-left: auto;
						}
					}
				}

				.Details {
					font-weight: 450;
					font-size: 13.59px;
					line-height: 17px;
					color: #655f5f;

					&__section1 {
						padding: 1rem 2rem;
						border-bottom: 0.34px solid #9c9393;

						.title {
							margin-bottom: 1rem;
						}
						.address {
							color: #252627;
							margin-bottom: 0.8rem;
						}
						.blue-link {
							display: flex;
							flex-direction: row;
							align-items: center;
							gap: 0.25rem;
							color: #40798c;
							cursor: pointer;
							margin-bottom: 28px;
						}
						.see-where {
							margin-bottom: 22px;
						}

						.img-list {
							display: flex;
							flex-direction: row;
							align-items: flex-start;
							flex-wrap: wrap;
							gap: 7px;
							margin-bottom: 35px;
							img {
								width: 48px;
							}
						}
					}
					&__section2 {
						padding: 20px 2rem;

						.title {
							margin-bottom: 38px;
						}

						.amenities {
							display: grid;
							grid-template-columns: repeat(2, 1fr);

							&__item {
								display: flex;
								flex-direction: row;
								align-items: center;
								gap: 2rem;
								margin-bottom: 25px;
								color: #9c9393;

								svg {
									width: 18px;
									height: 18px;
									> path {
										fill: #9c9393;
									}
								}

								&--available {
									color: #252627;
									svg {
										> path {
											fill: #252627;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.map {
			height: 100%;
			overflow: hidden;
			img {
				height: 100%;
			}
		}
	}

	.select-departure {
		background: #243256;
		text-align: center;
		padding: 22px 7.5%;
		padding-top: 23px;
		margin-bottom: 0.5rem;

		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 14px;
		color: #fff;
		font-size: 14.01px;
		line-height: 18px;
		// width: 100%;

		.circle {
			border-radius: 50%;
			display: flex;
			height: 27px;
			width: 27px;
			background-color: #ffffff;
			text-align: center;
			align-items: center;
			justify-content: center;
			color: #000;
		}

		.mobile-time {
			display: none;
		}
	}
	.search-result-wrapper {
		display: grid;
		grid-gap: 8px;
		.search-result-container {
			padding: 20px 0;
			.search-result {
				// width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-around;
				background: #ffffff;
				border: 0.8px solid rgba(196, 196, 196, 0.5);
				border-radius: 8px;
				padding: 15px 20px;
				.travel-times {
					display: flex;
					align-items: center;
					.horizantal {
						height: 2px;
						background-color: #000;
						width: 100px;
					}
				}
			}
			.more-info {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 20vh;
				background: #f9f9f9;
				// border: 0.839px solid rgba(196, 196, 196, 0.5);
				// align-items: center;
				padding: 10px 0;
				.trip-info {
					display: flex;
					justify-content: space-between;
					padding: 0 70px;
					width: calc(100% - 140px);
					align-items: center;

					.flight-details {
						width: 60%;
						font-size: 80%;
						display: flex;
						justify-content: space-between;
						flex-direction: column;
						width: calc(100% - 140px);
						align-items: center;
						div {
							// width: 100%;
							width: 80%;

							display: flex;
							justify-content: space-between;
						}
						.dept,
						.arrv {
							width: 80%;
							display: grid;
							grid-template-columns: 1fr 1fr 1fr 6fr;
							grid-gap: 5%;
						}
					}
					.end {
						font-size: 80%;
						width: 20%;
						height: 100%;
						display: flex;
						flex-direction: column;
						padding: 10px 0;
						justify-content: space-between;

						div {
							display: flex;
							width: 100%;

							p {
								padding-left: 10px;
							}
						}
					}
				}
				.layover-banner {
					display: flex;
					background: rgba(112, 169, 161, 0.52);
					border-radius: 3px;
					width: 45%;
					margin-left: 25%;
					justify-content: space-between;
					padding: 5px 2.5%;
					font-size: 70%;
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.flight-page {
		padding: 2.815rem 1.5rem;
		padding-bottom: 85px;
	}
}

@media screen and (max-width: 930px) {
	.flight-page {
		padding-bottom: 85px;
		.search-query {
			padding: 10px 12px 12px 26px;
			margin-bottom: 12px;
			gap: 0.5rem;
			* {
				font-size: 12px;
				line-height: 15px;
			}
			.search-left {
				.up {
					margin-bottom: 9px;
					p {
						font-weight: 500;
					}
				}
				.down {
					gap: 4px;
				}
			}
			.search-right {
				// display: none;
				padding-left: 0;
				padding-right: 0;
				font-size: 12px;
				line-height: 15px;
				min-width: 68px;

				svg {
					display: none;
				}
			}
		}
		.flight-selected {
			border: 0;
			border-radius: 3px 3px 0px 0px;
			padding: 11.5px 7px;
			padding-right: 19px;

			p {
				font-weight: 450;
				font-size: 12px;
				line-height: 15px;
			}

			.change {
				font-weight: 500;
				font-size: 12px;
				line-height: 15px;
				color: #40798c;
				cursor: pointer;
			}
		}

		.filter-options {
			flex-direction: column;
			.advanced {
				display: none;
			}
			.result-filter {
				.filters {
					display: none;
				}
			}
			.filter-div {
				display: flex;
				flex-direction: row;
				margin-left: auto;

				button {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 0.5rem;
					font-weight: 450;
					font-size: 12px;
					line-height: 15px;
					color: #40798c;
					padding: 6px;
					background-color: transparent;
					border: 0;
				}
			}
		}

		.restrictions {
			padding: 8px 12px;
			gap: 12px;
			img {
				width: 15px;
			}
			p {
				font-weight: 450;
				font-size: 12px;
				line-height: 15px;
			}

			&__see_full {
				&--mobile {
					display: flex;
				}

				&--web {
					display: none;
				}
			}
		}

		.select-departure {
			padding: 16px 19px;
			padding-bottom: 20px;
			border-radius: 2px;
			font-weight: 450;
			p {
				font-size: 12px;
				line-height: 15px;
			}

			.circle {
				height: 20px;
				width: 20px;
			}

			.web-time {
				display: none;
			}

			.mobile-time {
				display: inline;
			}
		}
	}
}
