@keyframes rotateLoader {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(180deg);
		animation-timing-function: cubic-bezier(0.57, 0, 0.43, 1);
	}
	100% {
		transform: rotate(360deg);
	}
}

.SpinLoader {
	height: 100%;
	display: grid;
	place-items: center;

	svg {
		// animation: rotateLoader 0.7s ease-in-out infinite normal forwards;
		animation: rotateLoader 1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
		// animation: rotateLoader 1s linear infinite normal forwards;
		// animation: rotateLoader 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite
		// 	normal forwards;
	}
}
