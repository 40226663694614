.switchPassenger {
	position: relative;

	&__popUp {
		height: 0;
		width: 100%;
		background-color: #ffffff;
		overflow: hidden;

		box-shadow: 0px 0px 5px 0px rgba(64, 121, 140, 0.25);
		border-radius: 5px;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;

		position: absolute;
		top: 48px;
		z-index: 1500;

		&--open {
			height: 300px;
			overflow-y: auto;
		}
	}
}

.DropPassengerItem {
	padding: 10px 1rem;

	display: grid;
	grid-template-columns: 36px 1fr;
	align-items: center;
	gap: 41px;

	border-bottom: 1px solid rgba(196, 196, 196, 0.5);
	cursor: pointer;

	&:hover {
		background-color: #fafafa;
	}

	&__img {
		width: 36px;
		height: 36px;
		font-weight: 450;
		font-size: 10px;
		line-height: 13px;
		color: #9c9393;
		background: #e6e6e6;
		border-radius: 50%;

		display: grid;
		place-items: center;
	}

	&__names {
		font-weight: 450;
		font-size: 14.01px;
		line-height: 18px;
		color: #252627;
		margin-bottom: 6px;
	}

	&__email {
		font-weight: 450;
		font-size: 14.01px;
		line-height: 18px;
		color: #9c9393;
	}
}
