.PaginationComponent {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 2rem;
	padding: 20px 0;

	font-weight: 450;
	font-size: 13.59px;
	line-height: 17px;

	color: #9c9393;

	&__nav {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 0.5rem;

		font-weight: 450;
		font-size: 14px;
		line-height: 14px;

		padding-right: 1rem;

		svg {
			cursor: pointer;
		}

		.page-btn {
			width: 20px;
			height: 20px;
			display: grid;
			place-items: center;
			padding: 3px;
			border-radius: 50%;
			cursor: pointer;

			&--active {
				color: #40798c;
				background: rgba(196, 196, 196, 0.5);
			}
		}
	}
}
