.PassengerBlock {
	&__title {
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 15px;

		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 1rem;
		justify-content: space-between;
	}

	.users {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		gap: 6px;

		// margin-bottom: 18px;
	}
}

@media screen and (max-width: 800px) {
	
}
