.InvoicingPage {
	padding: 2.8125rem 13.9vw;
	padding-left: 14.09vw;
	padding-right: 12.5vw;
	background-color: #ffffff;
	min-height: 100vh;
	padding-bottom: calc(2.8125rem + 61px);

	&__top {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		gap: 1rem;
		margin-bottom: 20px;

		h3 {
			font-weight: 500;
			font-size: 30px;
			line-height: 37px;
			color: #252627;
			margin-bottom: 0.5rem;
		}

		.TripInfoDrawer {
			&__MoreInfo {
				background: #fff;
				border-radius: 0px;
				min-height: max-content;
				height: max-content;
				border: none;
				.card {
					display: flex;
					gap: 3rem;
					padding: 21px 25px 0 25px;
					border-radius: 8px;
					background: #ffffff;
					border: 0.5px solid rgba(196, 196, 196, 0.5);
					border-radius: 5px;
					min-height: 105px;
					margin-bottom: 15px;
					flex-wrap: wrap;

					> div {
						display: flex;
						gap: 1.2rem;
						cursor: pointer;
					}

					.total {
						font-size: 13px;
						line-height: 16px;
						font-weight: 450;
						width: max-content;
						padding: 0px 4px;
						border-radius: 3px;
					}

					.overdue {
						color: #ff5e5e;
						background: rgba(254, 199, 199, 0.3);
					}

					.unpaid {
						color: #ff5b80;
						background: rgba(255, 234, 239, 0.5);
					}

					.money {
						display: flex;
						align-items: center;
						gap: 1.2rem;
						color: #252627;
						font-weight: 500;
						font-size: 18px;
						line-height: 37px;
					}

					span {
						color: #40798c;
						font-size: 13px;
						padding-bottom: 3px;
					}
					span:hover {
						border-bottom: 1px solid #40798c;
					}

					.settleShutter {
						&--shut {
							display: none;
						}

						.ButtonDropdown {
							&__button {
								padding: 0;
								border: 0;
								font-size: 13px;
								padding-bottom: 3px;

								&:hover {
									text-decoration: underline;
									text-underline-offset: 0.3rem;
								}
							}
						}
					}
				}
			}
		}
	}

	.setPreferences {
		color: #40798c;
		font-weight: 450;
		font-size: 14px;
		line-height: 18px;
		display: flex;
		align-items: center;
		gap: 15px;
		cursor: pointer;
		margin: 40px 0 50px;
	}

	&__head {
		display: flex;
		justify-content: space-between;
		margin-bottom: 18px;

		.search-filter {
			display: flex;
			align-items: center;
			gap: 14px;

			.search {
				padding: 8px 20px;
				width: 166px;
				height: 42px;
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 8px;
				background: #f9fafa;
				border: 0.8px solid rgba(196, 196, 196, 0);
				border-radius: 100px;

				input[type="search"] {
					color: #655f5f;
					border: 0;
					font-weight: 450;
					font-size: 14.01px;
					line-height: 13px;
					width: 100%;
					background-color: #f9fafa;
				}
				input::placeholder {
					color: #c4c4c4;
				}

				input.hide-clear[type="search"]::-ms-clear,
				input.hide-clear[type="search"]::-ms-reveal {
					display: none;
					width: 0;
					height: 0;
				}

				input.hide-clear[type="search"]::-webkit-search-decoration,
				input.hide-clear[type="search"]::-webkit-search-cancel-button,
				input.hide-clear[type="search"]::-webkit-search-results-button,
				input.hide-clear[type="search"]::-webkit-search-results-decoration {
					display: none;
				}
			}
		}

		.filter {
			position: relative;
			cursor: pointer;

			&__inner {
				display: flex;
				flex-direction: row;
				gap: 8px;
				color: #9c9393;
				padding: 8px 20px;
				height: 31px;
				background: #f9fafa;
				border-radius: 8px;

				p {
					font-size: 14px;
				}
			}
		}
		.options {
			position: absolute;
			top: 40px;
			right: 0px;
			background-color: #ffffff;
			border: 0.8px solid rgba(196, 196, 196, 0.5);
			box-shadow: 0px 4px 4px rgba(156, 147, 147, 0.1);
			border-radius: 6px;
			width: 160px;
			padding: 5px 0;
			div {
				padding: 10px;
				padding-left: 35px;
			}

			label {
				color: #9c9393;
				font-size: 14px;
				line-height: 18px;
				display: flex;
				gap: 10px;
				cursor: pointer;
			}

			&__shut {
				display: none;
			}
		}

		.generateButtons {
			display: flex;
			gap: 1.6rem;

			.generatePay {
				padding: 10px 20px;
				border-radius: 6px;
				font-weight: 450;
				font-size: 14px;
				line-height: 18px;
				background-color: transparent;

				&--active {
					color: #40798c;
					border: 0.5px solid #40798c;
					cursor: pointer;
				}

				&--inActive {
					color: #c4c4c4;
					border: 0.5px solid #c4c4c4;
					cursor: default;
				}

				svg {
					color: #40798c;
				}
			}
		}
	}

	&__tabs {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 2rem;
		flex-wrap: wrap;
		color: #9c9393;

		.mobileDropDown {
			display: none;
		}

		.tab {
			font-size: 14px;
			line-height: 18px;
			cursor: pointer;

			&--active {
				border-bottom: 1px solid #40798c;
				color: #40798c;
			}
		}
	}

	&__table {
		font-weight: 450;
		font-size: 13.59px;
		line-height: 17px;

		.mobileWarning {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			gap: 10px;
			background: #fff7e5;
			border-radius: 5px;
			padding: 8px 10px;
			margin-bottom: 1rem;
			max-width: max-content;

			font-weight: 450;
			font-size: 12px;
			line-height: 15px;
			color: #655f5f;
		}

		&__head {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr 0.7fr;
			color: #252627;
			font-weight: 500;
			font-size: 14px;
			line-height: 18px;
			padding: 15px 20px;
			background: #f5f5f5;
			font-weight: 500;
			border-radius: 5px 5px 0px 0px;
		}

		.record {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr 0.7fr;
			padding: 20px 20px;
			border-bottom: 0.34px solid rgba(196, 196, 196, 0.4);
			color: #655f5f;
			border-radius: 3px;
			align-items: center;
			font-size: 14px;

			> div > p:nth-child(2) {
				max-width: max-content;
				color: #655f5f;
				background: #f2f3f3;
				border-radius: 2px;
				padding: 0px 5px;
				font-size: 12px;
				font-weight: 450;
				margin-top: 2px;

				span {
					color: #db1919;
				}
			}

			> p:nth-child(2) {
				font-weight: 450;
				font-size: 13.59px;
			}

			> p:nth-child(3) {
				font-weight: 500;
				font-size: 14px;
			}

			.status {
				max-width: max-content;
				border-radius: 3px;
				padding: 5px 8px;
				font-size: 12.8px;
				font-weight: 500;

				&--paid {
					background: rgba(224, 244, 232, 0.5);
					border: 0.5px solid rgba(64, 191, 111, 0.5);
					color: #40bf6f;
				}

				&--unpaid {
					color: #ff5b80;
					background: rgba(255, 234, 239, 0.59);
					border: 0.5px solid rgba(255, 91, 128, 0.5);
				}

				&--overdue {
					color: #ff5e5e;
					background: rgba(254, 199, 199, 0.3);
					border: 0.5px solid rgba(255, 94, 94, 0.5);
				}
			}
		}

		.activities {
			grid-template-columns: 0.4fr 1fr 1.3fr 1.3fr 0.8fr 1fr 0.5fr;

			color: #252627;
			> div > p:nth-child(2) {
				color: #9c9393;
				background: none;
				border-radius: 0;
				padding: 0;
				font-size: 12.6px;
			}

			> p:nth-child(2) {
				font-size: 14px;
			}

			> p:nth-child(3) {
				font-weight: 450;
				font-size: 14px;
			}
			> p:nth-child(5) {
				color: #9c9393;
			}
			> p:nth-child(6) {
				color: #9c9393;
				font-size: 14.01px;
			}
		}

		[type="checkbox"] {
			width: 14px;
			height: 14px;
			color: #252627;
			vertical-align: middle;
			appearance: none;
			background: none;
			border: 0;
			outline: 0;
			flex-grow: 0;
			border-radius: 2px;
			background-color: #ffffff;
			transition: background 300ms;
			cursor: pointer;
		}

		[type="checkbox"]::before {
			content: "";
			color: transparent;
			display: block;
			width: inherit;
			height: inherit;
			border-radius: inherit;
			border: 0;
			background-color: transparent;
			background-size: contain;
			box-shadow: inset 0 0 0 1px #c4c4c4;
		}

		[type="checkbox"]:checked {
			background-color: #252627;
		}

		[type="checkbox"]:checked::before {
			box-shadow: none;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
			background-size: 20px;
			background-position: center;
		}

		/* IE */

		[type="checkbox"]::-ms-check {
			content: "";
			color: transparent;
			display: block;
			width: inherit;
			height: inherit;
			border-radius: inherit;
			border: 0;
			background-color: transparent;
			background-size: contain;
			box-shadow: inset 0 0 0 1px #ccd3d8;
		}

		[type="checkbox"]:checked::-ms-check {
			box-shadow: none;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
			background-size: 20px;
			background-position: center;
		}

		.record:hover {
			background-color: #f9fafa;
		}

		.actions {
			max-width: 105px;
			display: flex;
			justify-content: space-between;
			color: #40798c;
			font-weight: 500;
			font-size: 12.8px;

			a {
				color: #40798c;
				text-decoration: none;
				text-underline-offset: 0.3rem;
			}

			a:hover {
				cursor: pointer;
				text-decoration: underline;
			}

			> span {
				font-weight: 400;
			}
		}
		&__end {
			display: flex;
			justify-content: space-between;
			margin-top: 20px;
			padding-left: 20px;
			font-size: 13.59px;
			.results {
				color: #9c9393;
			}
		}
	}

	.InvoicingEmptyState {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 0.5rem;
		min-height: 280px;
		text-align: center;

		.title {
			font-weight: 500;
			font-size: 18px;
			line-height: 23px;
			color: #252627;
			margin-bottom: 5px;
		}
		.desc {
			font-weight: 450;
			font-size: 14px;
			line-height: 18px;
			color: #655f5f;
		}
	}
}

.InvoiceGenerationModal {
	width: 533px;
	background: #ffffff;
	border-radius: 8px;

	&__head {
		background: #f5f5f5;
		border: 0.339149px solid rgba(0, 0, 0, 0.25);
		border-radius: 8px 8px 0px 0px;
		padding: 1.5rem 0 1.5rem 2.5rem;

		h2 {
			font-weight: 500;
			font-size: 20.31px;
			line-height: 26px;
			color: #252627;
		}
	}

	&__modalBody {
		padding: 1.5rem 2.5rem;

		.paymentMethod {
			display: flex;
			gap: 18px;
			padding-bottom: 1.5rem;
			cursor: pointer;

			input[type="radio"] {
				border: 0px;
				width: 16.5px;
				height: 2em;
			}

			&__name {
				font-weight: 450;
				font-size: 15px;
				line-height: 19px;
			}

			&__desc {
				font-weight: 450;
				font-size: 13.59px;
				line-height: 17px;
				color: #9c9393;
			}
			&__btn {
				margin: 0.5rem 0;
				text-align: right;
				button {
					height: 36px;
				}
			}
		}
	}
}

.SingleInvoice {
	padding: 2.8125rem 13.9vw;
	padding-left: 14.09vw;
	padding-right: 12.5vw;
	background-color: #f9fafa;
	min-height: 100vh;

	padding-bottom: calc(2.8125rem + 61px);

	.InvoiceCard {
		padding-top: 64px;
		padding-left: 68px;
		padding-right: 75px;
		padding-bottom: 1rem;
		background: #ffffff;
		box-shadow: 0px 4px 4px rgba(101, 95, 95, 0.25);
		border-radius: 8px;
		min-width: 1000px;

		p,
		* {
			font-weight: 450;
			font-size: 14.01px;
			line-height: 18px;
			color: #252627;
		}

		.grey {
			color: #655f5f;
		}
		.w450 {
			font-weight: 450;
		}
		.w500 {
			font-weight: 500;
		}
		.w700 {
			font-weight: 700;
		}

		&__companyInfo {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 1.5rem;
			justify-content: space-between;
			margin-bottom: 36px;

			img {
				width: 90px;
			}
			.left-text {
				text-align: right;
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				gap: 28px;

				.timeline {
					margin-bottom: 1px;
				}
				.companyName {
					margin-bottom: 5px;
				}
			}
		}

		&__line {
			background: #e7e7e7;
			border: 0.8px solid rgba(196, 196, 196, 0.5);
			border-radius: 4px;
			height: 5px;
		}

		&__Status {
			display: flex;
			justify-content: flex-end;
			margin: 20px 0;

			.status {
				max-width: max-content;
				border-radius: 3px;
				padding: 5px 8px;
				font-size: 12.8px;

				&--paid {
					background: rgba(224, 244, 232, 0.5);
					border: 0.5px solid rgba(64, 191, 111, 0.5);
					color: #40bf6f;
				}

				&--unpaid {
					color: #ff5b80;
					background: rgba(255, 234, 239, 0.5);
					border: 0.5px solid rgba(255, 91, 128, 0.5);
				}

				&--due {
					color: #ff5e5e;
					background: rgba(254, 199, 199, 0.3);
					border: 0.5px solid rgba(255, 94, 94, 0.5);
				}
			}
		}

		&__billing {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			gap: 1rem;
			justify-content: space-between;
			margin-bottom: 63px;

			.two-grid {
				display: grid;
				grid-template-columns: 132px 1fr;
				gap: 26px;
				margin-bottom: 11px;

				& > :first-child {
					color: #655f5f;
				}

				& > :nth-child(2) {
					text-align: right;
				}
				.light {
					color: #40798c;
					background-color: #ffffff;
				}
				.dark {
					color: #ffffff;
				}

				.ButtonDropdown {
					&__button {
						padding: 0.5rem 11px;
						background: #243256;
						border-radius: 3.85px;
						color: #ffffff;

						&--inActive {
							background: #169d53;
							border: 0;

							// color: #169d53;
						}
					}

					&__drop {
						text-align: left;

						&__item {
							&__title {
								color: #655f5f;
								font-weight: 500;
								font-size: 13.59px;
								line-height: 17px;
							}

							&__desc {
								color: #9c9393;
								font-weight: 500;
								font-size: 13.59px;
								line-height: 17px;
							}
						}
					}
				}
			}

			.splitWrap {
				position: relative;
				display: flex;

				width: max-content;
				margin-left: auto;

				& > * {
					margin-left: auto;
				}

				&:hover {
					.CustomTooltip {
						display: inline;
						color: #ffffff;
						left: 50%;
					}
				}

				.ButtonDropdown {
					&__button {
						font-weight: 450;
						font-size: 14px;
						line-height: 18px;
						color: #40798c;

						border: 0.7px solid #40798c;
						border-radius: 6px;
						padding: 6px 21px;

						&--inActive {
							color: #c4c4c4;
							border: 0.7px solid #c4c4c4;
						}
					}

					&__drop {
						text-align: left;

						&__item {
							&__title {
								color: #655f5f;
								font-weight: 500;
								font-size: 13.59px;
								line-height: 17px;
							}

							&__desc {
								color: #9c9393;
								font-weight: 500;
								font-size: 13.59px;
								line-height: 17px;
							}
						}
					}
				}
			}

			.buttons {
				margin-top: 26px;
			}

			.names {
				margin-bottom: 0.55rem;
				margin-top: 1.55rem;
			}
		}

		&__table {
			width: 100%;
			border-collapse: separate;
			border-spacing: 0 5px;

			tr,
			td,
			th {
				text-align: left;
			}

			&__header {
				// display: grid;
				// grid-template-columns: 1fr 2fr 1fr 0.5fr;
				// grid-auto-columns: max-content;
				border-radius: 2px;

				tr {
					th {
						padding: 18px;
						border-radius: 2px;
						border: 0.8px solid #e6e6e6;
						background: #e6e6e6;
					}
				}
			}
			&__body {
				.record {
					// display: grid;
					// grid-template-columns: 1fr 2fr 1fr 0.5fr;
					// grid-auto-columns: max-content;
					padding: 27px 18px;
					border: 0.8px solid rgba(196, 196, 196, 0.5);
					border-radius: 4px;
					margin-bottom: 8px;

					td {
						border: 0.8px solid rgba(196, 196, 196, 0.5);
						border-left: 0;
						border-right: 0;
						width: max-content;
						padding: 32px 18px;
					}

					& > td:first-of-type {
						border-left: 0.8px solid rgba(196, 196, 196, 0.5);
						border-radius: 4px;
					}
					& > td:last-of-type {
						border-right: 0.8px solid rgba(196, 196, 196, 0.5);
						border-radius: 4px;
					}
				}
				.total-record {
					padding-bottom: 0;

					td {
						padding: 18px;
						padding-bottom: 13px;
					}
				}
			}
		}

		&__notes {
			display: flex;
			flex-direction: column;
			gap: 5px;
			margin: 103px 0;
			.title {
				color: #655f5f;
				margin-bottom: 19px;
			}
		}

		&__enquiry > p {
			color: #9c9393;
			text-align: center;
		}
	}
}

.hideOnDesktop {
	display: none;
}

@media screen and (max-width: 930px) {
	.hideOnMobile {
		display: none;
	}
	.hideOnDesktop {
		display: inherit;
	}
	.InvoicingPage {
		padding: 2.8125rem 13.9vw;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		padding-bottom: calc(2.8125rem + 61px);

		.InvoicingPage {
			&__top {
				flex-direction: column;
				align-items: flex-start;

				h3 {
					font-size: 24px;
					line-height: 37px;
				}
				.totalCard {
					display: flex;
					flex-direction: column;
					gap: 18px;
					margin-top: 2rem;
					width: 100%;

					.hideOnDesktop {
						cursor: pointer;
						width: max-content;
						> p {
							color: #252627;
							font-weight: 450;
							font-size: 14px;
							line-height: 18px;
							display: flex;
							flex-direction: row;
							align-items: center;
							gap: 1rem;
						}
					}
				}

				.TripInfoDrawer {
					&__MoreInfo {
						background: #fff;
						border-radius: 0px;
						min-height: max-content;
						height: max-content;
						border: none;
						transition: all 1s;

						&--shut {
							height: 0;
							height: 0;
							border: none;
						}
						.card {
							gap: 2rem;
							padding: 21px 25px;
							background: #f9fafa;

							> div {
								gap: 1.2rem;

								.overdueTotal {
									border-bottom: 1px solid rgba(196, 196, 196, 0.5);
									padding-bottom: 20px;
								}
							}
						}
					}
				}
			}
			&__head {
				display: flex;
				justify-content: space-between;
				gap: 1.6rem;
				flex-direction: column;
				margin-bottom: 18px;

				.search-filter {
					display: flex;
					align-items: center;
					gap: 14px;

					.search {
						width: 100%;
						appearance: none;
					}
					input[type="search"] {
						appearance: none;
					}
				}

				.filter {
					&__inner {
						align-items: center;
						color: #9c9393;
						height: 42px;
						border-radius: 0;
					}
				}
				.options {
					div {
						padding: 8px;
						padding-left: 13px;
					}
				}

				.generateButtons {
					display: none;
				}
			}

			&__tabs {
				.mobileDropDown {
					display: inherit;
					font-size: 13px;
					color: #40798c;
					padding: 0;
					background: #ffffff;
					border-radius: 5px;
					width: max-content;
					font-family: "Circular Std";
				}

				.tab {
					display: none;
				}
			}

			&__table {
				.activityDate {
					font-size: 14px;
					line-height: 18px;
					color: #9c9393;
					padding: 15px 0 13px 20px;
				}

				&__head {
					grid-template-columns: 1fr 0.1fr;
				}

				.generatedDate {
					max-width: max-content;
					color: #655f5f;
					background: #f2f3f3;
					border-radius: 2px;
					padding: 2px 5px;
					font-size: 12.5px;
					line-height: 18px;
					font-weight: 450;
					margin-top: 12px;

					span {
						color: #db1919;
					}
				}

				.mobileHover:hover {
					background-color: #f9fafa;
				}

				.record {
					display: grid;
					grid-template-columns: 1fr 0.3fr;
					align-items: flex-start;
					padding: 15px 10px;

					.invoiceDetailsSide {
						display: flex;
						flex-direction: column;
						gap: 6px;

						> :first-child {
							color: #252627;
							font-weight: 450;
							font-size: 14px;
							line-height: 18px;
						}

						> :nth-child(2) {
							color: #9c9393;
							font-weight: 500;
							font-size: 14px;
							line-height: 18px;
							background: none;
							margin: 0;
							padding: 0;
						}
					}

					.amountSide {
						display: flex;
						flex-direction: column;
						gap: 6px;

						p:first-child {
							font-weight: 500;
							font-size: 15px;
							line-height: 18px;
							color: #252627;
						}
					}

					.status {
						border-radius: 2px;
						padding: 3px 4px;
						font-size: 11px;
					}
				}

				.activities {
					grid-template-columns: 1fr 0.3fr;
				}

				.activityDetails {
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					gap: 18px;

					> div > p {
						font-size: 14px;
						line-height: 18px;
						margin-bottom: 0.5rem;
					}

					.nameOfTraveller {
						color: #252627;
					}

					.route {
						color: #655f5f;
					}

					.reference {
						font-size: 12px;
						line-height: 15px;
						color: #9c9393;
					}
				}

				.amount {
					font-weight: 500;
					font-size: 14px;
					line-height: 18px;
					color: #252627;
				}

				.actions {
					width: 87px;
					flex-direction: column;
					align-items: flex-end;
					justify-content: center;
					gap: 10px;
					font-size: 14px;
					line-height: 18px;

					:first-child:hover {
						cursor: pointer;
						text-decoration: underline;
					}

					:nth-child(2):hover {
						cursor: pointer;
						text-decoration: underline;
					}

					.ButtonDropdown {
						&__button {
							width: max-content;
							padding: 0;
							border: 0;
							font-size: 14px;
							line-height: 18px;
							font-weight: 500;
						}

						&__drop {
							left: unset;
							right: 0;
						}
					}
				}
				&__end {
					display: none;
				}
			}
		}
	}
}
