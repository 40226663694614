.bell-wrappers {
	min-width: 34px;
	width: 34px;
	height: 34px;
	position: relative;
	border-radius: 50%;
	display: grid !important;
	place-items: center;
	background: #f9fafa;
	border: 0.3px solid rgba(196, 196, 196, 0.5);

	#HW_badge_cont {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;

		display: flex;
		flex-direction: row;
		align-items: flex-end;
		justify-content: flex-end;
	}

	#HW_badge {
		display: block;
		transform: scale(0.8) translateX(100%) translateY(-90%);
	}
}
